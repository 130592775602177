@import "styles/variables.scss";
@import "styles/mixins.scss";

// Search section
.search-section {
  display: flex;
  width: 100%;
  max-width: 100%;
  .search-input {
    float: left;
    width: 100%;
    .sg-search-input {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right: none;
    }
    .sg-search-input:focus {
      border-color: $dark-grey-v1 !important;
      box-shadow: none;
    }
  }

  .search-separator {
    border-left: 0.5px solid $gray-600;
    float: left;
    opacity: 0.3;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }

  .search-toggle {
    display: flex;
    border: 1px solid $dark-grey-v1;
    border-left: none;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;

    .dropdown-menu {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      right: 0px;
      .selected {
        color: $black-russian;
        text-decoration: none;
        background-color: $darker-gray;
      }
      .dropdown-item {
        margin-bottom: 2px;
      }
    }

    .dropdown-toggle {
      padding: 0.75rem;
      padding-left: 0.45rem;
      padding-right: 0.45rem;
      padding-bottom: 0.5rem;
      text-decoration: none;
      background-color: $white;
      .dropdown-toggle-indicator {
        color: $black-rock-blue;
        font-size: 0.8rem;
        opacity: 0.8;
        margin-left: 0.2rem;
      }
    }
  }
} // End of search section
