@import 'styles/variables.scss';
@import 'styles/mixins.scss';

// Apps tab setting
.tab-apps-settings {
  .tabs {
      border-bottom: 0.063rem solid $approx-gainsboro;
      margin-bottom: 1.6rem;
  }

  .nav-item {
      margin-left: -1.5rem !important;
      margin-right: 1.8rem !important;
      position: relative;
      top: 0.063rem;
  }
}

// Mobile app container and integration container
.mobile-app-outer-container,
.app-integration-container {
  min-width: 10%;
  min-height: 26.667rem;
  background: $shadow-white;
  box-shadow: 0 0 0.4rem 0 $box-shadow-light;
  display: flex;
  flex-direction: column;
  padding: 1.667rem;
  border-radius: 0.25 * $base-border-radius;
  margin: 3% 0 3% 0;
  padding-top: 1.875 * $base-padding;

  .mobile-app-container-one {
    display:flex;
  }

  .inner-container-one {
    margin-left: 22px;
  }

  .app-img {
    width: 36px;
    height: 36px;
  }

  .large-text {
    color: $black-rock-blue;
    font-size: 22px;
    font-weight: bold;
  }


  .small-gray-text {
    color: $black-rock-blue;
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 37px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    opacity: 0.8;
  }

  .mobile-app-container-two {
    display:flex;
    border-radius: 3px;
    border: solid 1px $light-grey;
    background-color: $shadow-white;
  }

  .phone-img {
    width: 220px;
    height: 220px;
    margin-top: 26px;
  }

  .inner-container-two {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .large-text-two {
    font-size: 18px;
    font-weight: bold;
    color: $black-rock-blue;
    margin-bottom: 10px;
  }

  .app-images-container {
    margin-bottom: 16px;
  }

  .small-text-two {
    font-size: 16px;
    color: $black-rock-blue;
    margin-bottom: 23px;
    width: 90%;
  }

  .apple-store-image {
    width: 160px;
    height: 47px;
    margin-right: 7px;
  }

  .google-store-image {
    width: 185px;
    height: 72px;
  }

  @media only screen and (max-width: 898px) {
    .app-images-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}

// Integration tab
.app-integration-container {
  .integration-card {
    width: 19.375rem;
    min-height: 13rem;
    padding: 0.813rem 1.25rem 3.313rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.08);
    border: solid 0.063rem $border-light;
    background-color: $white;

    .integration-card-contents {
      .connection-status {
        position: relative;
        .pulse-m-icon {
          width: 5.667rem;
          height: 5.667rem;
          object-fit: contain;
        }

        .tick-mark {
          top: 0;
          position: absolute;
          margin-top: -0.5rem;
          margin-left: -0.95rem;
          width: 1.6rem;
          height: 1.6rem;
          object-fit: contain;
        }
      }
      .status-text {
        font-size: 1.2rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: $black-rock-blue;
        opacity: 0.8;
      }
      .connection-date {
        font-size: 1.2rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: $black-rock-blue;
      }
    }
  }
}

// Connection modal contents.
.connection-modal-contents {
  .modal-container {
    padding-left: 10.9rem;
    padding-right: 10.9rem;

    .connect-to-pulsem-text {
      font-size: 1.467rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.82;
      letter-spacing: 0.022rem;
      color: $black-rock-blue;
    }

    .customerlobby-img {
      width: 7.733rem;
      height: 5.867rem;
      object-fit: contain;
    }

    .pulsem-img {
      width: 9.2rem;
      height: 5.867rem;
      object-fit: contain;
    }

    .company-details {
      padding: 0.533rem;
      border-radius: 0.2rem;
      border: solid 0.067rem $light-grey;
      background-color: $white-smoke2;
      min-height: 2.667rem;
    }

    .company-name-text {
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        text-align: left;
        color: $charcoal-grey;
      }

    .getting-started-section {
      border-radius: 0.4rem;
      border: solid 0.067rem rgba(127, 134, 155, 0.35);
      background-color: $shadow-white;
      .getting-started-text {
        font-size: 0.933rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: $cello-blue-2;
      }

      .img-style {
        width: 1.333rem;
      }

      .description {
        opacity: 0.8;
        font-size: 0.867rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        text-align: left;
        color: $black-rock-blue;

        a {
          color: $sg-text-info;
          text-decoration: underline;
        }
      }
    }

    .label-style {
      font-size: 0.933rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: $gondola-grey;
    }

    .review-details-section {
      .feedback-icon {
        width: 1.333rem;
        height: 1.333rem;
        object-fit: contain;
      }
      .arrow {
        width: 0.733rem;
        height: 0.4rem;
        cursor: pointer;
      }

      ul {
        list-style: none;
        li {
          &:before{
          content: "\2022";
          color: $dolphin-grey;
          font-weight: bold;
          font-size: 2.133rem;
          margin-right: 1rem;
          line-height: 0;
          vertical-align: sub
          }
        }
      }
    }
  }
}
