@import "styles/variables.scss";
.enterprise-recent-campaigns {
  min-width: 54rem !important;

  .read-more-notes-title {
    font-size: 1.067rem;
    color: $bright-grey;
    font-weight: 600;
  }

  .row-header {
    margin: 1rem 0rem 0rem 0rem;
    color: $bright-grey;
    font-weight: normal;
    font-size: 1rem;
  }

  .row-text {
    color: $bright-grey;
    font-size: 15px;
    font-weight: normal;
  }

  .sub-rows {
    margin: 0.3rem 0rem 0.3rem 0rem;
  }

  .campaign-name {
    font-size: 1rem;
    color: $sg-text-info;
  }
}
