#mainNav.navbar-light {
  .navbar-collapse {
    .navbar-sidenav {
      .nav-link-collapse:after {
        color: fade-out($black, 0.5);
      }
      > .nav-item {
        > .nav-link {
          color: $light-gray;
          font-size: 0.99 * $base-font-size;
          &:hover {
            color: $white;
          }
        }
        .sidenav-second-level,
        .sidenav-third-level {
          > li > a {
            color: fade-out($black, 0.5);
            &:focus,
            &:hover {
              color: fade-out($black, 0.3);
            }
          }
        }
      }
    }
    .navbar-nav > .nav-item.dropdown > .nav-link:after {
      color: fade-out($black, 0.5);
    }
  }
  @media (min-width: $screen-md-min) {
    .navbar-collapse {
      .navbar-sidenav {
        background: $gray-100;
        height: calc(100vh - 3rem);
        li {
          &.active {
            a {
              color: $black !important;
              background-color: $gray-200;
              &:focus,
              &:hover {
                color: $black;
              }
            }
          }
        }
        > .nav-item {
          .sidenav-second-level,
          .sidenav-third-level {
            background: $gray-100;
          }
        }
      }
    }
  }
}
