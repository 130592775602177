@import "styles/variables.scss";

.texting-accordion-container {
  border-radius: 0.4rem;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.04);
  background-color: $white;
  padding: 0.8rem 1.0667rem 0.8rem 1.5333rem;
  width: 100%;

  .accordion {
    .texting-accordion-line {
      margin-top: calc(0.7667rem - 0.5rem);
      margin-bottom: 0.8333rem;
      border: 1px solid #e7e6e6;
      border-top: none;
      padding: 0 !important;
      width: 100%;
      height: 1px;
      &:not(:first-of-type) {
        margin-top: 1rem;
      }
      + .transaction-box {
        border: none;
        padding: 0.25rem 0;
      }
    }
  }
}
.dark-mode {
  .texting-accordion-container {
    background: $mystical-shade;
    border-radius: 0;
  }
}
