@import "styles/variables.scss";
@import "styles/mixins.scss";
@import "styles/navbar.scss";

#top-header-section {
  background-color: $cello-blue;
  padding-top: 0 * $base-padding;
  padding-bottom: 0 * $base-padding;

  .logo-image {
    height: 2.375 * $base-height;
    object-fit: contain;
    vertical-align: baseline;
  }

  .nav-link {
    font-size: $base-height;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.01 * $base-padding;
    text-align: left;
    color: $white !important;
    padding: 1.375 * $base-padding 1vw;

    @media (max-width: 1600px) {
      font-size: 0.9375 * $base-height;
    }

    @media (max-width: 768px) {
      padding: $base-padding 4vw;
      font-size: 0.875 * $base-height;
    }
  }

  .profile-menu {
    padding: 1.375rem 1vw;
  }

  .right-arrow {
    width: 0.58125 * $base-width;
    height: 0.34375 * $base-height;
    object-fit: contain;
  }

  .show-menu {
    display: block;
  }

  .nav-link:hover {
    background-color: $biscay-blue;
    cursor: pointer;
  }

  .nav-link-active {
    background-color: $biscay-blue;
  }

  .nav-link:active {
    background-color: $biscay-blue;
  }

  .user-menu {
    margin: auto;
    margin-right: 0 * $base-margin;
  }

  img.pulsemicon {
    width: 4.706rem;
    height: 1.106rem;
    margin: 0 0 0 0.294rem;
    object-fit: contain;
  }

  .powered {
    width: 3.938rem;
    height: 0.938rem;
    margin: 0.169rem 0.294rem 0 0;
    opacity: 0.7;
    font-family: ProximaNova;
    font-size: 0.625rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $white;
  }

  .information-menu {
    .information-icon {
      width: 1.375 * $base-width;
      height: 1.375 * $base-height;
      object-fit: contain;
      opacity: 0.7;
    }
  }

  .profile-section {
    background: white;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    float: left;
    margin-right: 0.625rem;
    margin-top: -5px;

    .profile-icon {
      width: 2.125 * $base-width;
      height: 2.125 * $base-height;
      object-fit: contain;
      margin-right: 0.625 * $base-margin;
    }
  }

  .navbar-toggler {
    color: $white;
  }
}

.navbar-light {
  color: $white;
  background-color: $alto-grey;
  border-color: $nobel-grey-border;
}

.navbar-light .navbar-nav > li > a {
  color: $white;
}

.navbar-light .navbar-nav > .dropdown > a .caret {
  border-top-color: $white;
  border-bottom-color: $white;
}

.navbar-light .navbar-brand {
  color: $white;
}

.menu-large {
  position: relative !important;
}

ul.megamenu {
  padding: 1.25 * $base-padding 0.625 * $base-padding 0.5 * $base-padding;
  width: max-content;
  margin-top: 0 * $base-margin;
  background-color: $biscay-blue;

  .menu-icon {
    width: 1.5 * $base-width;
    height: 1.5 * $base-height;
    object-fit: contain;
    margin-top: 0.3125 * $base-margin;
  }

  .menu-text {
    padding-left: 2.5 * $base-padding;
    white-space: normal;
  }

  .menu-header {
    font-size: 1.125 * $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.01125 * $base-font-size;
    text-align: left;
    color: $white;
    margin-bottom: 0.125 * $base-margin;

    @media (max-width: 1600px) {
      font-size: 1.0625 * $base-height;
    }

    @media (max-width: 768px) {
      font-size: $base-height;
    }
  }

  .menu-header-default-font {
    font-size: $base-height;
  }

  .menu-sub-title {
    opacity: 0.7;
    font-size: 0.875 * $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: $white;

    @media (max-width: 1600px) {
      font-size: 0.8125 * $base-height;
    }
  }
}

.megamenu > div > li > ul {
  padding: 0;
  margin: 0;
}

.megamenu > div > li > ul > li {
  list-style: none;
}

.megamenu .card {
  outline: none;
}

.megamenu .card:hover,
.megamenu .card:focus {
  outline: 0.0625 * $base-font-size solid $black;
}

.megamenu > div > li > ul > li > a {
  display: block;
  padding: 0.1875 * $base-padding 1.25 * $base-padding;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: $night-rider;
  white-space: normal;
}

.megamenu > div > li > ul > li > a:hover,
.megamenu > div > li > ul > li > a:focus {
  text-decoration: none;
  color: $nero-grey;
  background-color: $white-smoke;
}

.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  color: $nobel-gray;
}

.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed;
}

.megamenu.dropdown-header {
  color: $approx-curious-blue;
  font-size: 1.125 * $base-font-size;
}

@media (min-width: 1200px) {
  .navbar-light .navbar-nav .dropdown-menu.reviews-menu {
    width: 48vw;
  }

  .navbar-light .navbar-nav .dropdown-menu.coustmer-markting-menu {
    width: 48vw;
  }

  

  .navbar-light .navbar-nav .dropdown-menu.campaign-menu {
    width: 26.3vw;
  }

  .navbar-light .navbar-nav .dropdown-menu.setting-menu {
    width: 48vw;
  }

  .navbar-light .navbar-nav .dropdown-menu.customer-marketing-menu {
    width: 48vw;
  }

  /* Reduce width to 24vw if only one item */
  .navbar-light .navbar-nav .dropdown-menu {
    li:only-child {
      width: 24vw;
    }
  }
}

@media (max-width: 1600px) {
  .navbar-light {
    .navbar-nav {
      .dropdown-menu {
        &.setting-menu {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .megamenu {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0px;
  }

  .megamenu > li {
    margin-bottom: 1.875 * $base-margin;
  }

  .megamenu > li:last-child {
    margin-bottom: 0;
  }

  .megamenu.dropdown-header {
    padding: 0.1875 * $base-padding 0.9375 * $base-padding !important;
  }

  .navbar-nav .open .dropdown-menu .dropdown-header {
    color: $white;
  }

  .dropdown-menu {
    width: 100 * $base-width-pct;
  }

  .user-menu {
    width: 100 * $base-width-pct;
  }

  .campaign-menu {
    .dropdown-item {
      margin-bottom: 0;
    }
  }

  .dropdown {
    &.information-menu {
      display: none;
    }
  }

  .navbar {
    padding-left: 0 * $base-padding;
    padding-right: 0 * $base-padding;
  }

  .navbar-collapse {
    overflow-y: scroll;
    height: calc(100vh - 60px);
  }
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: middle;
  content: "\f107";
  border: 0;
  font-family: FontAwesome;
}

.dropdown-menu {
  border-top-left-radius: 0 * $base-border-radius;
  border-top-right-radius: 0 * $base-border-radius;
  border: transparent;
}

.dropdown-item {
  //min-height: 5.375 * $base-height;
  min-height: 5 * $base-height;
  padding: 0.625 * $base-padding 0.9375 * $base-padding;
  //margin-bottom: 0.3125 * $base-margin;

  a {
    text-decoration: none;
  }
}

.user-menu-dropdown-item {
  a {
    cursor: pointer;
  }

  min-height: unset;

  @media (max-width: 1024px) {
    margin-bottom: 0px !important;
  }
}

.dropdown-item:hover,
.dropdown-item:focus {
  text-decoration: none;
  border-radius: 0.375 * $base-border-radius;
  background-color: $cello-blue-1;
}

.dark-mode {
  #top-header-section {
    background: $midnight-express !important;
  }
}
