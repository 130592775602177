@import "styles/variables.scss";
@import "styles/mixins.scss";

.customer-header {
  padding-left: $page-gutter;
  padding-right: $page-gutter;
  padding-top: $page-gutter * 1.5;
  padding-bottom: $page-gutter * 1.15;
  border-bottom: 0.1rem solid #dbddda;
  margin-bottom: 0.1rem;
}