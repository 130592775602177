@import "styles/variables.scss";

.location-detail-view-container {
  position: absolute;
  width: 25rem;
  background: $white;
  height: 83vh;
  right: 0.5rem;
  border: 1px solid $light-gray;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-top: -4.7rem;

  .tab-header {
    font-size: 1.067rem;
    color: $black-rock-blue;
  }

  .review-platform-item-heading {
    font-size: 1rem;
  }

  .customer-details-header {
    .custom-header {
      font-size: 1.333rem;
      color: $black-rock-blue !important;
    }
  }

  .customer-details-content {
    background: rgba(102, 117, 135, 0.04);
  }

  .customer-info-container {
    .customer-name {
      color: $sg-text-info;
    }
  }

  .vr {
    width: 0;
    height: 73px;
    margin: 0.5px 0 0;
    opacity: 0.1;
    border: solid 1px #707070;
  }

  .texting-accordion-container {
    .texting-accordion-line {
      border: none;
    }
  }

  .campaign-status {
    border: none !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 !important;

    div {
      border-radius: 6px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.04);
      border: solid 1px rgba(151, 149, 147, 0.15);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 7rem;
      width: 49%;
      margin-bottom: 0.5rem;
      text-align: center;
    }
  }
  .info-txt {
    text-transform: capitalize;
  }
}
