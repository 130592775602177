#mainNav.fixed-top {
  .sidenav-toggler {
    display: none;
  }
  @media (min-width: $screen-md-min) {
    .navbar-sidenav {
      height: calc(100vh - 1 * #{$base-height});
    }
    .sidenav-toggler {
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;

      margin-top: calc(100vh - 3 * #{$base-margin});

      > .nav-item {
        width: 13.22 * $base-width;
        padding: 0;
        > .nav-link {
          padding: 0.9 *$base-padding;
        }
      }
    }
  }
  &.navbar-dark {
    .sidenav-toggler {
      background-color: $gray-900;
      a {
        i {
          color: $gray-500;;
        }
      }
    }
  }
  &.navbar-light {
    .sidenav-toggler {
      background-color: $gray-300;
      a {
        i {
          color: fade-out($black, 0.5);
        }
      }
    }
  }
}

body.sidenav-toggled {
  #mainNav.fixed-top {
    .sidenav-toggler {
      overflow-x: hidden;

      width: 12 * $base-width ;
      .nav-item,
      .nav-link {
        width: 12 * $base-width !important;
      }
    }
    #sidenavToggler {
      i {
        -webkit-transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter:  'FlipH';
      }
    }
  }
}
