// import project variables
@import "styles/variables.scss";

.getting-started-card {
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px $border-light;
  background-color: $white;
  height: 560px;
  width: 95%;
  .header {
    height: 168px;
    background-color: #fff6ef;
    display: grid;
    place-content: center;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .primary-button {
    width: 182px;
    height: 50px;
    font-weight: 500;
    font-size: 1.375rem;
    border-radius: 4px;
    background-color: $carrot-orange;
  }
  .primary-info {
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.42px;
    text-align: center;
    color: $black-rock-blue;
    padding: 40px 116px;
    padding-bottom: 0;
  }
  .secondary-info {
    opacity: 0.8;
    height: 40px;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: $black-rock-blue;
    padding: 21px 60px 0px 60px;
    padding-bottom: 0;
  }
  .third-info {
    opacity: 0.8;
    height: 88px;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: $black-rock-blue;
    padding: 0px 60px;
    padding-bottom: 0;
  }
  .actions {
    text-align: center;
    padding: 30px 0;
    padding-bottom: 24px;
    .btn {
      margin-bottom: 16px;
    }
  }
}

.getting-started {
  .username {
    border-bottom: 2px solid $approx-gainsboro;
    margin-bottom: 2px;
    font-size: 1.73rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: normal;
    text-align: left;
    color: $black-rock-blue;
    padding: 29px 240px;
  }
  .how-would-you {
    font-size: 2.8rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.43;
    letter-spacing: normal;
    text-align: center;
    color: $black-rock-blue;
    padding: 46px 240px;
  }
  .getting-started-cards {
    padding: 0px 120px 100px;
  }
}

.about-insights-modal {
  .modal-content {
    width: 850px;
  }
  .content {
    display: flex;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  .div-content {
    overflow-y: scroll;
    height: 550px;
  }
  .header {
    opacity: 0.8;  
    font-size: 1.25rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;  
    text-align: center;
    color: $black-rock-blue;
    padding: 12px 0;
  }
  .description {
    font-size: 1.06rem;
    text-align: center;
    margin-left: 30px;
  }
  
  .preview {
    margin-right: 60px;
    margin-left: 60px;
  }

  .preview-img {
    max-height: 320px;
  }
}

.getting-started-modal {
  .modal-body {
    padding: 0;
    width: 1080px;
    height: 640px;
  }
  .close-button {
    position: absolute;
    cursor: pointer;
    right: 8px;
    top: 8px;
  }
  .steps {
    display: flex;
    .sidebar {
      width: 420px;
      height: 640px;
      background-color: $cello-blue;
      padding: 12px 25px;
      .header {
        font-size: 1.625rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.96;
        letter-spacing: normal;
        text-align: left;
        color: $white;
      }
      .sideLabel {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.22;
        letter-spacing: normal;
        text-align: left;
        color: $white;
    }
    
    .sideLabelActive {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.22;
        letter-spacing: normal;
        text-align: left;
        color: $carrot-orange;
    }
      .step {
        padding: 8px 0px;
        display: flex;
        align-items: center;
        img {
          width: 1.2rem;
          margin-right: 16px;
        }
        font-size: 1.125rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.22;
        letter-spacing: normal;
        text-align: left;
      }
    }
    .content {
      padding: 12px 25px;
      width: 660px;
      padding: 0 50px;
      text-align: center;
      .preview-img {
        max-height: 370px;
        max-width: 100%;
      }
      .header {
        opacity: 0.8;
        font-size: 1.25rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: $black-rock-blue;
      }
      .description {
        opacity: 0.8;
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: $black-rock-blue;
        padding: 16px 0;
      }
      .paginate {
        padding-top: 8px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        position: absolute;
        bottom: 2%;
        right: 25%;
        .next-dot,
        .fa {
          margin: 0 6px;
        }
        .fa {
          color: $carrot-orange;
          opacity: 0.8;
        }
        .fa.disabled {
          color: #a5a5a5;
          opacity: 0.4;
          cursor: not-allowed;
        }
        .next-dot {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: solid 1px #a5a5a5;
          background-color: $white;
          &.active {
            border: none;
            background-color: $carrot-orange;
          }
        }

        .chevron {
          width: 8px;
          height: 15px;
        }
      }
    }
  }
}
