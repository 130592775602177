@import "styles/variables.scss";

.map-coverage-container {
  .map-coverage {
    min-height: 540px;
    .sg-card {
      .sg-card-body {
        position: relative;
      }
    }
    .campaign-header {
      position: relative;
    }

    #map {
      position: absolute;
      left: 0;
      width: 100%;
      height: calc(100% - 70px);
    }

    .marker {
      background-image: url("~src/assets/images/campaigns/result/marker-icon-orange.svg");
      background-size: cover;
      background-size: 100%;
      cursor: pointer;
      width: 1.25 * $base-width;
      height: 3 * $base-height;
    }

    .mapboxgl-popup-content {
      border-radius: 0.313 * $base-border-radius;
      padding: 0.438 * $base-padding;
      text-align: left;
      line-height: 1.43;
      letter-spacing: normal;
      color: $grey-dark;
      font-size: 0.875 * $base-font-size;
      font-stretch: normal;
      font-weight: normal;
      font-style: normal;
    }
    .mapboxgl-popup-close-button {
      outline: none;
    }
    .mapboxgl-popup {
      top: -0.938rem;
    }
  }
}