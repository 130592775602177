/**
 * All font-sizes, paddings, margins variables values are in rem
 * All colors are of hex type exluding the colors used for opacity with use of short-hands
 * Fonts and directions variables are of string
 */

// Color Variables
// Bootstrap Color Defaults
$white: #fff !default;
$gray-100: #3f474d !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$orange: #ff7d00;
$tangerine-orange: #f47820;
$green: #41a138;
$gray: #eaeaea;
$dull-cyan: #6ec5b8;
$gainsboro: #dfdfdf;
$solitude: #e5e7ea;
$toast-default: #f47821;
$toast-error: #f42134;
$toast-info: #428bca;
$toast-success: #5cb85c;
$toast-warning: #f47821;
$toast-warning-info: #fcf8e3;
$azureish-white: #e2e7ec;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$bloodred: #a70817;
$fire-Red: #df4740;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$flamingo: #f05656;
$dark-red: #ff0000;
$light-gray: #f3f5f6;
$dull-blue: #5d798f;
$dark-gray: #6d7273;
$light-gray1: #e7e6e6;
$shuttle-gray: #58626e;
$light-slate-gray: #7a8c99;
$dark-slate-gray: #adadad;
$dim-gray: #666666;
$dim-gray1: #6a6a6a;
$darker-gray: #e0e0e0;
$very-light-gray: #cccccc;
$autumn-fog: #bbbbbb;
$night-rider: #333333;
$nobel-gray: #999999;
$whisper-gray: #ebeaea;
$white-smoke: #f7f7f7;
$white-smoke1: #f8f8f8;
$white-smoke2: #f3f2f2;
$puerto-rico: #61baa9;
$suva-gray: #909090;
$approx-dark-gray: #acacac;
$zircon-gray: #e6e7e7;
$gossamer: #3c9685;
$approx-gossamer: #45a798;
$lynch: #5d7d90;
$wedgewood: #516c81;
$lavender-gray: #ededee;
$seashell-peach: #fff6ef;
$light-peach: #fde4d2;
$approx-nobel-gray: #979797;
$approx-gainsboro: #dddddd;
$curious-blue: #337ab7;
$silver-gray: #bfbfbf;
$trout: #4c5459;
$mid-gray: #636a6f;
$trolley-gray: #aaaaaa;
$salad-green: #43a63a;
$aluminium-gray: #85898c;
$baltic-sea-black: #393d40;
$matterhorn-gray: #555555;
$pattens-blue-gray: #dae0e4;
$dodger-blue: #2d8cf0;
$snow-gray: #fafafa;
$dark-tangerine-yellow: #f4b31b;
$bright-red: #f92727;
$link-water-gray: #d2d6de;
$white-smoke: #f3f3f3;
$shadow-white: #ffffff;
$light-snow-gray: #fcfcfc;
$carrot-orange: #e69422;
$box-shadow-light: rgba(0, 0, 0, 0.08);
$modal-border: rgba(112, 112, 112, 0.2);
$modal-background: rgba(0, 0, 0, 0.16);
$dark-trout: #505759;
$whisper-light-gray: #e4e4e4;
$maximum-yellow-red: #f4bb4b;
$whisper-light-gray-shade: #eeeeee;
$dim-gray-shade: #737373;
$mariner-blue: #3c63b0;
$atomic-gray: #41484c;
$storm-gray: #797c80;
$lochmara-blue: #2a6496;
$clear-day-gray: #e7ecea;
$link-water-dark-gray: #c8cacc;
$tonys-pink-orange: #e6a08b;
$cornflower-blue: #4da6ff;
$whisper-light-gray: #eeeeee;
$summer-sky: #3da7f2;

$gull-gray: #a1abb2;
$alice-blue-light-gray: #fafbfc;
$alice-blue-gray: #edf0f2;
$denim-blue: #1d7cbf;
$cod-gray: #2b3033;
$approx-alice-blue-gray: #edf0f2;
$approx-fiord: #576066;
$chestnut-red: #bf4343;
$summer-sky-blue: #22b5e6;
$kelly-green: #5fcc29;
$cinnabar-red: #e64322;
$silver: #c0c0c0;
$summer-sky: #22b5e6;
$vivid-blue: #3bc2f4;
$galliano: #e6a422;
$cinnabar: #e64322;
$gray-chateau: #9da0a6;
$tuna-black: #45484c;
$approx-pattens-blue: #c2e0ff;
$pale-navy: #afc6e3;
$light-navy: #4371d6;
$approx-alice-blue: #ebf5ff;
$approx-pink-swan: #b3b3b3;
$raven-gray: #6c757d;
$approx-mischka: #a6afba;
$approx-pattens-blue-shade: #dbe1e6;
$approx-periwinkle: #cdd1df;
$approx-curious-blue: #428bca;
$approx-curious-blue-opacity: #428bca12;
$gray-59: #969696;
$email-prview-box-shadow: #f4f4f4;
$campaign-preview-text-color: #173a66;
$catellina-blue: #1a345c;
$dodger-blue: #2b80fd;
$highlighted-area: #fdf1e1;
$highlighted-area-text: #a19586;
$error-text: #f74b31;

$border-light: #e3e7ed;
$header-border: #dbddda;

$cello-blue: #37485b;
$biscay-blue: #2c3a4c;
$alto-grey: #ccc6c6;
$nobel-grey-border: #aca1a2;
$nero-grey: #262626;
$white-smoke: #f5f5f5;
$cello-blue-1: #38465c;
$ghost-white: #f7f8fa;

$deep-cyan-blue: #22405d;
$dark-slate-blue: #49576b;
$light-slate-grey: #7f869b;
$sapphire-blue: #082544;
$carrot-orange: #f47821;
$light-grey: #d6d3d3;
$charcoal-grey: #444444;
$charcoal-blue: #222832;
$gondola-grey: #323130;
$cello-blue-2: #3d5067;
$black-rock-blue: #313541;
$light-blue-gray: #d2dbe6;
$rock-blue: #030303;
$lynch-blue: #667587;
$light-black: #00000014;
$normal-black: #1a1a1a;
$grey-dark: #7e7e7e;
$grey: #7e7e7e66;
$grey1: #7e7e7e33;
$grey2: #7f8188;
$mountain-mist-grey: #979593;
$dolphin-grey: #707071;

$atomic-tangerine-orange: #ff8c69;
$solitude-blue: #f4f7fa;
$solitude-blue1: #dfe6ee;
$solitude-blue2: #f4f4f9;
$heather-blue: #b0bac5;
$bali-hai-blue: #8091a5;
$black-pearl-blue: #16181e;
$gulf-blue: #35455b;
$fiord-blue: #56676f;
$luxor-gold-yellow: #a78440;
$zircon-grey: #dfe3e6;
$fern-green: #5cb85c;
$zumthor-grey: #cccfd3;
$lightest-blue: #f7fafb;
$light-apricot: #fef1e8;

$surf-crest-green: #c6dbbc;
$twilight-blue-grey: #fbfdfa;
$pale-turquoise-blue: #bce8f1;
$lavender-violet: #d9edf7;
$astral-blue: #31708f;

$gainsboro-white: #e0e0e0;
$spun-pearl: #a3a4aa;
$suva-gray-1: #959595;

$alto-approx: #d2d2d2;
$cinnabar: #e03b3e;

$dark-grey-v1: #a1a1a1;
$dark-grey-v3: #a4a4a4;
$black-russian: #16181b;
$bright-grey: #5a5d67;
$whisper: #ebebeb;
$lily-white: #f5f8f5;
$cello: #3d5067;
$sun: #f5883c;
$gondola: #323130;
$midnight-blue: #0c0a3b;
$whisper: #e9e7e7;
$light-midnight-blue: #0e0b65;
$black-shadow: #bbb6b6;
$silver-chalise: #b5aaaa;
$dark-cello: rgba(55, 72, 91, 0.05);
$grey-light: rgba(255, 255, 255, 0.31);
$cello-light: rgba(55, 72, 91, 0.2);
$cyan-blue: rgba(102, 117, 135, 0.5);
$dark-grey-v2: rgba(0, 0, 0, 0.5);
$postcard-unsafe-border: rgba(255, 0, 0, 0.5);
$postcard-content-border: rgba(55, 72, 91, 0.05);
$forest-green: #4d7317;
$dark-midnight-blue: #1a3c68;
$bright-gray: #ebebee;
$mild-grey: #dbdddf;
$philippine-silver: #b8b3af;
$nobel-grey: #a09d9d;
$storm-dust: #646260;
$very-light-gray-v1: #c9c7c7;
$heather-gray: #b8bfca;
$silver-chalice: #aeaeae;
$midnight-express: #232d41;
$dark-moderate-blue: #3b4557;
$dark-shade-cyan-blue: #303a4d;
$dark-shuttle-gray: #58616f;
$dark-bright-grey: #4f5765;
$dark-blue-grey: #2c3a4c;
$mystical-shade: #4d5664;
$pickled-bluewood: #37495c;
$dark-grey-v4: #4e5765;
$titan-white: #e3effd;

$bootstrap-grid-small: 576px;
$bootstrap-grid-medium: 768px;
$bootstrap-grid-large: 992px;
$bootstrap-grid-xl: 1200px;

$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

$screen-md-pro: 1024px !default;
$screen-md-min-pro: $screen-md-pro !default;
$screen-desktop-pro: $screen-md-min-pro !default;

$screen-xl: 1920px !default;
$screen-xl-max: $screen-xl !default;
$screen-xl-desktop: $screen-xl-max !default;

$screen-1600: 1600px !default;

$screen-ipad-pro: 1366px !default;

$sidebar-gutter: 385px;

// BASE VARIABLES
$base-padding: 1rem;
$base-margin: 1rem;
$base-font: "proxima-nova", Arial, Helvetica, sans-serif;
$base-font-size: 1rem;
$base-font-weight: 100;
$base-border-radius: 1rem;
$base-border-width: 1rem;
$base-bg-hover-dark-pct: 1%;
$base-border-hover-dark-pct: 1%;
$base-width: 1rem;
$base-height: 1rem;
$base-min-height: 1rem;
$base-right: 1rem;
$base-left: 1rem;
$base-top: 1rem;
$base-bottom: 1rem;
$base-line-height: 1rem;
$base-top-pct: 1%;
$base-left-pct: 1%;
$base-width-pct: 1%;
$base-height-pct: 1%;
$base-shadow: 1rem;
$base-position: 1rem;
$base-border-radius-pct: 1%;
$base-right-pct: 1%;
$base-margin-pct: 1%;
$base-pct: 1%;
$base-direction: 1rem;
$base-outline: 1rem;

/**
 * DIRECTIONS CONSTANTS VARIABLES IN STRING
 */
$direction-top: "top";
$direction-right: "right";
$direction-bottom: "bottom";
$direction-left: "left";

/**
 * FONT STYLES VARIABLES IN STRING
 */
$font-style-normal: "normal";
$font-style-italic: "italic";
$font-style-oblique: "oblique";
$none: none;

/**
 * LAYOUT Variables
 */
$page-gutter: 1.5rem !default;
$page-bg-color-light: #fbfdfa !default;

/**
 * STYLEGUIDE VARIABLES
 */
$sg-text-primary: #323130;
$sg-text-success: #5cb85c;
$sg-text-info: #428bca;
$sg-text-warning: #f47821;
$sg-text-danger: #f42134;
$sg-text-highlight-bg: #ffff00;

$sg-chart-progress-bar-bg: #f47821 !default;
$sg-chart-progress-bg: rgba(151, 149, 147, 0.15) !default;
$sg-chart-empty-state: #dadada;

$navbar-height: 62px;
