@import "styles/variables.scss";

.table-responsive {
  overflow: visible;
}

.sg-table {
  background-color: #fff;
  thead {
    background-color: #f7fafb;
    border: 1px solid #e3e7ed;
    th {
      border: 0;
      font-size: 0.85rem;
      font-weight: normal;
      color: #313541;
      font-weight: 600;
    }
  }
  tr {
    border: 1px solid #e3e7ed;
  }
  th,
  td {
    padding: 8px !important;
  }
}
.sg-table-page-info {
  color: #313541;
}

.sg-table-meta {
  font-size: $base-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $lynch-blue;
  .meta-label {
    font-weight: 500;
    max-width: 320px;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-left: 4px;
  }
  .meta-reset {
    &:hover {
      text-decoration: underline;
    }
  }
}

.dark-mode {
  .table {
    color: $white;
  }
  .sg-table {
    background-color: $dark-shuttle-gray;
    thead {
      border: none;
      tr {
        background-color: $dark-shuttle-gray;
      }

      th {
        color: $black-rock-blue;
      }
    }
    tr {
      background: $bali-hai-blue;
      border: none;
    }
    .sort-icon {
      filter: grayscale(1) brightness(4);
      img {
        opacity: 1 !important;
      }
    }
  }
}
