@import "styles/variables.scss";
// @import "styles/mixins.scss";

.call-credit-container {
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 3px 0px;
    border: 1px solid rgb(204, 207, 211);
    width: 50%;
    padding: 0.1rem;
    font-size: 1.5rem;
    text-align: center;
  }
}
