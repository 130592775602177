#email-modal-container {
  display:none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7777;
  background: rgba(0,0,0,0.4);

  .inner-container {
    width: 585px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    width: 85%;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    width: 85%;
  }

  .header-container {
    height: 74px;
    width: 100%;
    font-size: 26px;
    color: white;
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(95deg, #37485b -1%, #465464 101%);
    padding-left: 30px;
    padding-right: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .edit-email-label {
    color: #323130;
    font-size: 14px;
  }

  .edit-email-input {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #d6d3d3;
    margin-bottom: 10px;
    margin-top: 5px;
    padding-left: 5px;
  }

  .email-info-container {
    display:flex;
  }

  .edit-email-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 7px;
  }

  .edit-email-info-txt {
    font-size: 13px;
    color: #7e7e7e;
  }

  .edit-email-btns-container {
    display:flex;
    justify-content: flex-end;
    width: 85%;
    margin-bottom: 20px;
  }

  .close-modal {
    cursor: pointer;
  }

  .edit-email-cancel {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #667587;
    color: #667587;
    margin-right: 15px;
    cursor: pointer;
  }

  .edit-email-resend {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 4px;
    background-color: #f47821;
    color: white;
    cursor: pointer;
  }
}
