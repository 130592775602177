.sg-dropdown {
  @import "styles/variables.scss";

  // override variables
  $primary: #f47821 !default;
  $dropdown-link-hover-bg: #e0e0e0;
  $dropdown-font-size: 0.9rem;
  $dropdown-primary-button-font-size: 1.067rem;
  $dropdown-divider-margin-y: 0;
  $dropdown-bg-transition: 250ms;

  //  import
  @import "bootstrap/scss/functions";
  @import "bootstrap/scss/_variables";
  @import "bootstrap/scss/mixins";
  @import "bootstrap/scss/dropdown";

  // custom styles
  .dropdown-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $dropdown-font-size;
    &.btn-primary {
      height: $dropdown-primary-button-font-size * 2.5;
      font-size: $dropdown-primary-button-font-size;
    }
    &::after {
      display: none !important;
    }
    .dropdown-toggle-indicator {
      font-size: $dropdown-font-size * 0.75;
      margin-left: 0.5rem;
    }
  }

  .dropdown-menu {
    padding: 0;
    width: auto;
    overflow: hidden;
  }

  &.sg-dropdown-shadow {
    .dropdown-menu {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
      border: solid 1px rgba(112, 112, 112, 0.2);
    }
  }

  .dropdown-item {
    min-height: auto;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-radius: 0;
    @include hover-focus {
      color: $dropdown-link-hover-color;
      text-decoration: none;
      @include gradient-bg($dropdown-link-hover-bg);
    }
    -webkit-transition: background-color $dropdown-bg-transition linear;
    -ms-transition: background-color $dropdown-bg-transition linear;
    transition: background-color $dropdown-bg-transition linear;
  }

  .dropdown-item:hover {
    border-radius: 0;
  }

  .dropdown-divider {
    width: 92%;
    margin: auto !important;
  }
}
