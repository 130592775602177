@import "styles/variables.scss";

.icon-accordion-toggle {
  font-size: 0.6rem !important;
}

.dark-mode {
  small {
    span {
      color: $white;
    }
  }
  svg {
    filter: brightness(1.5);
  }
}
