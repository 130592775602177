@import "styles/variables.scss";
@import "styles/mixins.scss";

#TabsComponent {
  padding: 0;
  margin: 0;
  .tabs {
    .nav-item {
      @media (max-width: $screen-xs) {
        width: 32%;
        padding: 0 !important;
        text-align: center;
        font-size: 0.8rem !important;
      }
    }
    .nav-link {
      opacity: 0.65;
      font-size: 1.188 * $base-font-size;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $black-rock-blue !important;
      padding: 0.75rem;
      border: 0;
      border-bottom: 0.156rem solid transparent !important;
      margin-left: 0.125 * $base-margin;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
      height: 100%;
      @media (max-width: $screen-xs) {
        padding: 0 !important;
        text-align: center;
        font-size: 1rem !important;
      }
    }
    a.nav-link,
    span.nav-link {
      margin-left: $page-gutter !important;
      margin-right: $page-gutter !important;
      padding-left: 0;
      padding-right: 0;
      outline: none;
    }
    .nav-link:hover {
      border-color: transparent !important;
      color: $black-rock-blue !important;
      border: none !important;
      border-bottom: 0.156 * $base-border-width solid $carrot-orange !important;
      opacity: 1 !important;
      cursor: pointer;
      margin-left: 0.125 * $base-margin;
    }
    .active {
      opacity: 1;
      font-weight: 600;
      color: $black-rock-blue !important;
      border: none !important;
      border-bottom: 0.156 * $base-border-width solid $carrot-orange !important;
    }
  }
}

.dark-mode {
  #TabsComponent {
    .tabs {
      .active {
        color: $white !important;
      }
    }
  }
}
