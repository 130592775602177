@import "styles/variables.scss";
@import "styles/mixins.scss";

#private-feedback-tab-component{
    background-color: $twilight-blue-grey;

    @media (min-width: $screen-1600) {
        .sidebar-view {
            flex: 0 0 $sidebar-gutter;
            max-width: $sidebar-gutter;
        }

        .content-view {
            flex: 0 0 calc(100% - #{$sidebar-gutter});
            max-width: calc(100% - #{$sidebar-gutter});
        }
    }
}