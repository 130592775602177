.header-img {
  width: 36px;
  height: 33px;
  object-fit: contain;
}

.header-large-text {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: 0.33px;
  text-align: left;
  color: #313541;
}

.header-small-text {
  opacity: 0.8;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #313541;
}

.add-user-btn {
  width: 110px;
  height: 40px;
  border-radius: 4px;
  background-color: #f47821;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.company-users-header-date {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: right;
  color: #444444;
  &:before {
    content: 'Created on: ';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: right;
    color: #444444;
  }
}
