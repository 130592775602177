@import "styles/variables.scss";
@import "styles/mixins.scss";

.get-reviews-container {
  background-color: $page-bg-color-light;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  .get-reviews-block {
    padding-left: $page-gutter;
    padding-right: $page-gutter;
  }

  @media (min-width: $screen-1600) {
    .get-reviews-sidebar {
      flex: 0 0 $sidebar-gutter;
      max-width: $sidebar-gutter;
    }
    .get-reviews-content {
      flex: 0 0 calc(100% - #{$sidebar-gutter});
      max-width: calc(100% - #{$sidebar-gutter});
    }
  }
  .get-reviews-sidebar {
    .custom-control-label {
      height: 1.52rem;
      display: flex;
      align-items: center;
    }
  }

  .sg-dropdown {
    .dropdown-menu {
      margin-top: 8px;
      a {
        font-weight: 600;
        color: #212529;
      }
    }
  }
}

.kiosk-content {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #323130;
}

.kiosk-spacer {
  margin-top: 25px;
  margin-bottom: 20px;
}

.kiosk-link {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-family: "proxima-nova", sans-serif !important;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #444444;
}

.kiosk-label {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #313541;
}

.tab-get-reviews .tabs {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.6rem;
}

.tab-get-reviews .nav-item {
  margin-left: -$page-gutter !important;
  margin-right: 1.2 * $page-gutter !important;
  position: relative;
  top: 1px;
}

.get-review-credit-info {
  margin-top: auto;
  padding-top: 128px;
}

.get-reviews-table-container .table-responsive {
  border: 1px solid #e3e7ed !important;
}

.table-responsive {
  @media (max-width: 1366px) {
    overflow-x: scroll;
  }
}

.get-reviews-table {
  @media (max-width: 1366px) {
    position: relative;
    th:last-of-type,
    td:last-of-type {
      position: sticky;
      right: 0px;
      z-index: 10;
      box-shadow: 0px 6px 8px #ccc;
      padding-left: 32px !important;
      padding-right: 32px !important;
      border: 1px solid #e3e7ed;
    }
    th:last-of-type {
      background-color: #f7fafb;
    }
    td:last-of-type {
      background-color: #ffffff;
    }
    .sg-dropdown .dropdown-menu {
      margin-top: -8px;
      margin-left: -16px;
    }
  }
  .column-phone {
    min-width: 136px;
  }
  th:last-of-type {
    width: 84px;
  }
  .column-last_service_date {
    min-width: 136px;
  }
}
