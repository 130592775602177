@import "styles/variables.scss";

.dark-mode {
  background-color: $dark-shade-cyan-blue;
  // override utility classes
  .text-dark {
    color: $white !important;
  }
  .form-control {
    background-color: $lynch-blue;
    color: $white;
  }

  #top-header-section {
    background: $midnight-express;
  }

  // header
  #top-header-section ~ div#TabsComponent,
  #top-header-section ~ div.page-header,
  div.page-header {
    color: $white;
    background: $dark-moderate-blue;
    h1 > img {
      filter: brightness(0) invert(1);
    }
    .nav-item {
      color: $white;
    }
  }

  // component containers
  .tab-pane.active > div,
  .campaign-results-container {
    background: $dark-shade-cyan-blue;
  }

  .showing-results-for {
    color: rgba(255, 255, 255, 0.6);
  }
  #SidebarMenuComponent .filter-title,
  .sg-sidebar-component-container .filter-title,
  .results-dashboard-toggle,
  .title {
    color: $white;
  }

  // common components
  // sg-card: long narrow cards used for campaigns
  .sg-card {
    .sg-card-body {
      background-color: $dark-moderate-blue;

      .sidebar-card-item {
        color: rgba(255, 255, 255, 0.6);
      }

      .simple-result-info {
        color: rgba(237, 237, 237, 0.6);
        .simple-result-value {
          color: $white;
        }
      }

      > div:first-of-type {
        background: $dark-moderate-blue;
      }
      .accordion {
        background: $dark-shade-cyan-blue;
      }
      .texting-accordion-container {
        .accordion {
          background: $mystical-shade;
        }
      }
    }
  }

  .modal-header {
    background: $midnight-express;
    border-bottom: none;
  }
  .modal-body {
    background: $dark-bright-grey;
    color: $white;

    .form-control {
      background: $lynch-blue;
      color: $white;
      border: 1px solid $lynch-blue;
    }
    > .form-control::placeholder {
      color: $white;
    }
  }

  .modal-footer {
    background: $dark-bright-grey;
  }

  .calculator-content {
    .form-control,
    .Select-placeholder,
    .Select-control {
      background: $lynch-blue;
      color: $white;
      border: 1px solid $lynch-blue;
    }

    .Select-arrow-zone {
      filter: brightness(0) invert(1);
    }
  }

  .map-content {
    .accordion {
      h6 {
        background: $pickled-bluewood;
      }
      .show {
        background: $white;
        color: $black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        flex-direction: column;
      }
    }
    .input-group-text {
      background: $orange;
      color: $white;
    }
  }

  .select-dark-mode {
    background: $lynch-blue;
    color: $white;
  }

  .sg-datepicker {
    input {
      color: $white !important;
    }
  }
  .sg-datepicker-header-next,
  .sg-datepicker-header-prev {
    .text-dark {
      color: $gray-800 !important;
    }
  }
  .swal-title {
    color: $white;
  }
  .swal-modal {
    background: $dark-moderate-blue;
  }
  .swal-icon--success::before {
    background: $dark-moderate-blue;
  }
  .swal-icon--success::after {
    background: $dark-moderate-blue;
  }
  .swal-icon--success__hide-corners {
    background: $dark-moderate-blue;
  }
  .approved-campaign {
    .swal-title {
      color: $white;
    }
  }
}
.get-help {
  .modal-header {
    background: $dark-bright-grey;
    border-bottom: 1px solid rgba(226, 226, 226, 0.4);
  }
}

.modal-open {
  .tooltip {
    z-index: 9999;
  }
}
