@import "styles/mixins.scss";
@import "styles/variables.scss";

.list-card-container {
  border-radius: 6px;
  overflow: visible;

  .list-card-flag-container {
    position: absolute;
    z-index: 80;
    border-radius: 6px;
    overflow: hidden;
    pointer-events: none;
  }
  
  .list-card-flag {
    position: relative;
    top: -42px;
    left: -40px;
    transform: rotate(47deg);
    width: 80px;
    height: 80px;
  }

  .list-card-flag-icon {
    position: absolute;
    top: 0;
    z-index: 500;
    margin: 0.5rem;
  }
}

.sg-card.list-card .sg-card-body {
  padding: 0;
  background-color: $white-smoke;

  >div:first-of-type {
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24) !important;
    background-color: $white;
    padding: 24px;
  }
}

.collapsing {
  position: static;
}

.collapse,
.collapse.show,
.collapsing {
  >div {
    border: 1px solid $border-light;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 1.75rem 1.6rem;
  }
}