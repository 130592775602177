$search-input-font-size: 1rem !default;
$search-input-icon-pad: 8px !default;
$search-input-text-pad: 4px !default;

.sg-search-control {
  position: relative;
  .sg-search-icon,
  .sg-clear-icon {
    svg {
      fill: currentColor;
      height: $search-input-font-size;
      width: $search-input-font-size;
    }
    display: inline-flex;
    align-items: center;
    height: 100%;
    position: absolute;
    padding: 0px $search-input-icon-pad;
  }
  .sg-search-icon {
    left: 0;
  }
  .sg-clear-icon {
    svg {
      height: $search-input-font-size * 1.35;
      width: $search-input-font-size * 1.35;
    }
    right: 0;
  }

  .sg-search-input {
    border: 1px solid #a1a1a1;
    border-radius: 4px;
    padding: $search-input-text-pad;
    padding-left: calc(
      #{$search-input-font-size} + #{$search-input-icon-pad * 2}
    );
    padding-right: calc(
      #{$search-input-font-size} + #{$search-input-icon-pad * 2}
    );
  }
  .sg-search-input:focus {
    border-color: #fac29a !important;
  }
}
