@import "styles/variables.scss";

.campaign-filter-modal {
  position: absolute;
  z-index: 1;
  width: 25rem;
  height: auto;
  background: $white;
  right: 0;
  top: 2rem;
  padding-bottom: 3.6rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .filter-pane-header {
    background: $gulf-blue;
    padding: 0.5rem 1.5rem;
    color: $white;
  }

  .filter-pane-footer {
    display: flex;
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem;
    justify-content: end;
    border-top: 1px solid rgba(226, 226, 226, 0.4);

    button {
      margin-top: 1rem;
    }
  }

  .chevron:after {
    content: "\f107";
    font-family: "FontAwesome";
    font-size: 1.2rem;
    padding: 0 1.5rem 1.5rem;
    margin-top: -4px;
  }

  .chevron.collapsed:after {
    content: "\f106";
  }

  .search-container {
    font-size: 1rem;
    color: black;
  }

  .validation-text {
    margin: 1.6rem;
    color:red
  }
}
