@import "styles/variables.scss";
@import "styles/mixins.scss";

.content-block {
  display: block;
  overflow-x: hidden;
}

.popup-content {
  box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.16);
  border-radius: 0.3rem;
  border: 0.133rem rgba(103, 103, 103, 0.16);
  background-color: $white;

  .popup-arrow {
    path {
      stroke: rgba(126, 126, 126, 0.2);
      stroke-width: 1px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
      stroke-linecap: butt;
    }
  }
  .popup-header {
    display: flex;
    justify-content: space-between;
    .popup-title {
      padding-bottom: 1.533rem;
      @include font-setting(1.1rem, $bright-grey, 1.25, left, 600);
    }
  }
  .sg-text-info {
    max-width: fit-content;
  }
  .content-short {
    overflow: hidden;
    @extend .content-block;
  }
  .content-long {
    @extend .content-block;
  }
}
