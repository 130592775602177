@import "styles/variables.scss";
@import "styles/mixins.scss";

.customers-header {
  padding-left: $page-gutter;
  padding-right: $page-gutter;
  padding-top: $page-gutter;
  padding-bottom: $page-gutter * 0.6;
}

#TabsComponent.tabs-customers {
  .tabs {
    border-bottom: 0.1rem solid $header-border;
    margin-bottom: 0.1rem;
    .nav-link {
      position: relative;
      top: 0.1rem;
    }
  }
}

.new-customer-btn {
  .dropdown-menu {
    margin-left: -90px !important;
  }
  .dropdown-item {
    padding: 0.6rem 1rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }
  img {
    margin: 0px 0.6rem;
  }
  a {
    height: 2.5rem;
    display: flex !important;
    align-items: center;
    width: 7.8rem;
    font-size: 1rem;
    letter-spacing: normal;
    color: $black-rock-blue;
  }
}
