@import "styles/mixins.scss";
@import "styles/variables.scss";

.base-modal-w-cards {
  .modal-xl {
    max-width: 1200px;
  }

  .modal-lg {
    max-width: 720px;
  }

  .modal-md {
    max-width: 376px;
  }

  @media (max-width: $screen-ipad-pro) {
    .modal-xl {
      max-width: 95%;
    }
  
    .modal-lg {
      max-width: 70%;
    }
  
    .modal-md {
      max-width: 40%;
    }
  }

  .modal-body {
    padding: 0;
    text-align: center;

    .modal-body-header {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $seashell-peach;
      height: 7.8667rem;
    }
  }

  .modal-dialog {
    font-size: 0.8667rem;
      .modal-header {
      border-bottom: none;
      padding: 0.6667rem 1rem;
      .modal-title {
        font-size: 1.7333rem;
      }
      .close {
        padding: 0.4rem 1.8rem;
        font-size: 3.3333rem;
      }
    }

    .credits-card {
      margin: 4rem auto 2rem auto;
      border: solid 1px $border-light;
      border-radius: 8px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
      padding: 0;
      max-width: 392px;
      height: 340px;

      @media (max-width: $screen-ipad-pro) {
        justify-content: space-between;
      }
      .info-section {
        padding: 0 0.5333rem 0px 0.5333rem;
        width: 100%;

        h2 {
          margin: 1.3333rem 0;
          text-align: center;
          line-height: 1.42;
          letter-spacing: 0.36px;
          color: $black-rock-blue;
          font-size: 1.6rem;
          font-stretch: normal;
          font-weight: 600;
          font-style: normal;
        }
        @media (min-width: $screen-ipad-pro) {
          min-width: 325px;
        }

        .content-separator {
          padding-bottom: 0.3333rem;
        }
        .cost {
          color: $sg-text-warning;
          font-weight: 600;
        }
      }
      .cta-section {
        align-self: flex-end;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        .btn {
          margin: 2rem;
          padding: 0.5333rem 1.3333rem;
          font-size: 0.8667rem;
        }
      }
      .success-messge {
        display: block;
        opacity: 0.9;
        padding-top: 2.5333rem;
        color: $sg-text-success;

        img {
          display: inline-block;
          margin-right: 0.3rem;
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
  }
}

.purchase-credit-modal {
  @extend .base-modal-w-cards;
}