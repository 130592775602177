.sg-modal {
  &.set-1080 {
    .modal-dialog {
      max-width: 1080px;
    }
  }
  .modal-dialog {
    display: grid;
    min-height: 100vh;
    place-items: center;
  }
  .modal-content {
    background-color: transparent;
  }
  .modal-body,
  .modal-footer {
    background: #ffffff;
  }
  .modal-header {
    background-image: linear-gradient(93deg, #37485b -1%, #465464 101%);
    .modal-title {
      color: #ffffff;
      font-size: 1.2rem;
      margin-bottom: 0;
    }
    button {
      color: #ffffff !important;
      font-weight: normal;
      font-size: 2rem;
    }
  }
}
