@import "styles/variables.scss";

.code-preview {
  margin: 2rem 0;
  position: relative;
}

.code-preview-elements {
  padding: 1.5rem;
}

.code-preview-code {
  padding: 1.5rem;
  background-color: $whisper-light-gray-shade;
  position: relative;
}

.code-preview-code pre {
  margin: 0 !important;
}

.code-preview-code .copy-button {
  position: absolute;
  right: 0;
  top: 0;
}

.code-preview-label {
  text-transform: uppercase;
  position: absolute;
  left: 0.5rem;
  top: -0.75rem;
  font-size: 0.75rem;
  background-color: $white;
}
