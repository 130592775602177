@import "styles/variables.scss";
@import "styles/mixins.scss";

.review-link-container {
  display: flex;
  align-items: center;

  .info-img-styles {
    cursor: pointer;
  }

  .review-status-link {
    font-size: $base-font-size;
    color: $approx-curious-blue;
    cursor: pointer;
  }

  .hover-content {
    position: absolute;
    display: none;
    z-index: 9999;
    background: $normal-black;
    padding: 7px;
    color: white;
    border-radius: 5px;
    min-width: 200px;
    width: 200px;
    left: -95px;
    top: -61px;
    opacity: 0.9;
    font-size: 14px;
    text-align: center;
    color: $shadow-white;
  }

  .hover-content:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 102px;
    width: 0;
    border-top: 7px solid $normal-black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}
