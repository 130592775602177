@import "styles/variables.scss";

.customer-info-container {
  margin-top: 0.6667rem;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.04);
  border-radius: 6;

  .customer-name {
    color: $sg-text-info;
    font-size: 1.3333rem;
    font-weight: 600;
  } 

  .info-text-container {
    margin-top: 1rem;

    .info-txt {
      opacity: 0.9;
      margin-left: 0.9333rem;
      overflow: hidden;
      word-break: break-all;
      color: $black-rock-blue;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  a {
    text-decoration: none;
  }
}

@media (max-width: 1300px){
  .customer-info-container {
    .customer-name {
      flex-wrap: wrap;
      word-break: break-all;
    }
  }
}
