.content-wrapper {
  overflow-x: hidden;

  background: $white;
  @media (min-width: $screen-md-min) {
    margin-left: 10 * $base-width;
  }
}

// Toggling the Sidenav
#sidenavToggler {
  i {
    font-weight: 8 * $base-font-weight;
  }
}

.navbar-sidenav-tooltip.show {
  display: none;
}

body.sidenav-toggled {
  .content-wrapper {
    @media (min-width: $screen-md-min) {
      margin-left: 12 * $base-width;
    }
  }
  .navbar-sidenav {
    overflow-x: hidden;

    width: 12 * $base-width;
    .nav-link-text {
      display: none;
    }
    .nav-item,
    .nav-link {
      width: 12 * $base-width !important;
      &:after {
        display: none;
      }
    }
  }
  .navbar-sidenav-tooltip.show {
    display: flex;
  }
}
