@import "styles/variables.scss";
@import "styles/mixins.scss";

#review-list {
  .review-tile {
    box-shadow: 0 0 0.375 * $base-border-width 0 $light-black;
    margin-top: 1.25 * $base-margin;
    background-color: $white;
    padding: 0.625 * $base-padding;
    cursor: pointer;
  }
  .subject {
    font-size: 1.0625 * $base-font-size;
    font-weight: 5 * $base-font-weight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $black-rock-blue;
    word-wrap: break-word;
  }
  .review-body {
    opacity: 0.8;
    font-size: 0.9375 * $base-font-size;
    font-weight: 5 * $base-font-weight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $black-rock-blue;
    word-wrap: break-word;
  }
  .pagination {
    .previous,
    .next {
      a:hover {
        text-decoration: none;
      }
      span {
        font-size: 0.9375 * $base-font-size;
        font-weight: 6 * $base-font-weight !important;
        font-stretch: normal !important;
        font-style: normal !important;
        letter-spacing: normal;
        color: $approx-curious-blue;
      }
    }
    a,
    a:focus {
      outline: none;
    }
    .page-counts {
      margin: 0 0.5 * $base-margin 0 0.5 * $base-margin;
      cursor: pointer;
      color: $approx-curious-blue;
    }
    .disable-page {
      opacity: 0.5;
      font-size: $base-font-size;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $black-rock-blue;
      cursor: not-allowed;
      span {
        color: $black-rock-blue !important;
      }
    }
    .active-page {
      width: 1.5 * $base-width;
      height: 1.5 * $base-height;
      border-radius: 0.25 * $base-border-radius;
      border: solid 0.0625 * $base-border-width $approx-curious-blue;
      background-color: $approx-curious-blue-opacity;
      outline: none;
    }
  }
  .review-details-title {
    font-size: 1.625 * $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: normal;
    text-align: left;
    color: $cello-blue-2;
    cursor: pointer;

    img {
      vertical-align: top;
      margin-top: -0.1 * $base-margin;
      margin-right: $base-margin;
    }
  }

  .modal-dialog {
    .modal-body {
      padding-top: 0 * $base-padding;
    }
  }
}
