@import "styles/variables.scss";

.navbar-container {
  background: $gulf-blue;

  .card {
    background-color: $gulf-blue;
    border: none;
  }

  .nav-link,
  .menu-container {
    background: $dark-slate-blue;
  }
  .menu-container {
    border-radius: 0.53rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    padding: 1.2rem 0.2rem;
    font-size: 1.33rem;

    a {
      display: flex;
      align-items: center;
      line-height: 1.4;
      letter-spacing: 0.2px;
      padding: 1rem;
      color: #b3b8c4;

      img {
        height: 1.6rem;
        width: 1.6rem;
      }
    }
    .collapse {
      background: $dark-slate-blue;
    }
    .card-body {
      border: none;
    }

    .bg-dark-slate {
      background: $dark-slate-blue;
    }
  }
  .chevron:after {
    content: "\f107";
    font-family: "FontAwesome";
    font-size: 1.5rem;
  }

  .chevron.collapsed:after {
    content: "\f106";
  }

  a {
    padding: 0.6rem 0;
    text-decoration: none;
  }

  .active-link {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    text-decoration: none;
    color: $white !important;
    position: relative;

    img {
      filter: brightness(0) invert(1);
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-left: 8px solid $white;
    }
  }

  .text-white {
    img {
      filter: brightness(0) invert(1);
    }
  }
}
