@import "styles/variables.scss";
@import "styles/mixins.scss";

#NoDataFound, #orientationNotSupported {
  height: 100vh;

  .no-data-lower-container {
    margin-top: $base-margin*3
  }
  .message{
    margin-top: 38px;
  }
  .circle {
    width: 17.5 * $base-width;
    height: 17.5 * $base-height;
    background-color: $solitude-blue2;
    border-radius: 50%;

    @media (max-width: $screen-xs) {
      width: 13.5 * $base-width;
      height: 13.5 * $base-height;
      img {
        width: 5.313 * $base-width;
        height: 5.313 * $base-height;
      }
    }
  }
  .no-data {
    font-size: 2.625 * $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    color: $black-rock-blue;

    @media (max-width: $screen-xs) {
      font-size: 1.625 * $base-font-size;
    }
  }
  .no-data-subheading {
    font-size: 1.125 * $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    color: $grey-dark;
    margin-bottom: 14.5 * $base-margin;

    @media (max-width: $screen-xs) {
      font-size: $base-font-size;
    }
  }
}
#orientationNotSupported {
  .no-data {
    text-align: center;
    color: #3d5067;
  }
  .no-data-subheading{
    text-align: center;
    color: #3d5067;
  }

  @media (min-width: $screen-xs-min) {
    display: none;
  }
}
