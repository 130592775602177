.company-users-container {
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 30px 33px 23px 30px;

  .placeholder-block {
    width: 36px;
  }

  .company-users-inner-container {
    width: 100%;
  }
}
