@import "styles/variables.scss";
@import "styles/mixins.scss";

#SidebarMenuComponent {
  .filter-title {
    @include font-setting(
      1.25 * $base-font-size,
      $sapphire-blue,
      1.38,
      left,
      bold
    );
  }
  .menu-text {
    @include font-setting(
      1.125 * $base-font-size,
      $light-slate-grey,
      2.78,
      left,
      6 * $base-font-weight,
      0.011 * $base-padding
    );
    cursor: pointer;
    text-decoration: none;
    @media (max-width: $screen-md-pro) and (min-width: $screen-md-pro) {
      font-size: 1.04 * $base-font-size;
    }
    .menu-label {
      position: relative;
      top: 2px;
    }
    .menu-icon {
      svg {
        fill: currentColor;
      }
      display: inline-block;
      width: 1.125 * $base-width;
      height: 1.125 * $base-height;

      @media (max-width: $screen-sm) and (min-width: $screen-sm) {
        width: 0.7 * $base-width;
        height: 0.7 * $base-height;
      }

      @media (max-width: $screen-xs) {
        width: $base-width;
        height: $base-height;
        margin-left: $base-margin;
        margin-right: $base-margin;
      }
    }

    @media (max-width: $screen-sm) and (min-width: $screen-sm) {
      font-size: 0.7 * $base-font-size;
    }

    @media (max-width: $screen-xs) {
      font-size: $base-font-size;
    }
  }
  .menu-style {
    border-left: 0.125 * $base-border-width solid $light-slate-grey;
  }

  //.menu-style:hover,
  //.menu-style:focus {
  //  filter: invert(48%) sepia(100%) saturate(429%) hue-rotate(341deg)
  //    brightness(96%) contrast(99%);
  //  border-left: 0.125 * $base-border-width solid;
  //  color: $carrot-orange;
  //  font-weight: bold;
  //}
  .sidebar-menu-disabled {
    cursor: default !important;
    & :hover {
      cursor: default !important;
    }
    & * {
      cursor: default !important;
      & :hover {
        cursor: default !important;
      }
    }
  }
  .active-menu {
    position: relative;
    border-left: 0.125 * $base-border-width solid $carrot-orange;
    a {
      color: $carrot-orange !important;
    }
    font-weight: bold;
  }
  .active-menu:after {
    position: absolute;
    top: 0;
    left: -1px;
    background: $carrot-orange;
    width: 3px;
    height: 100%;
    content: " ";
  }
}

// Shared
.sg-sidebar-component-container {
  .filter-title {
    margin-top: 36px;
    margin-bottom: 12px;
    @include font-setting(
      1.25 * $base-font-size,
      $sapphire-blue,
      1.38,
      left,
      bold
    );
  }

  .filter-sub-title {
    margin-top: 0.75rem;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: $charcoal-grey;
  }
}

.sort-icon {
  cursor: pointer;
}
