@import "styles/variables.scss";
@import "styles/mixins.scss";

#company-settings {
  .required-field::after {
    content: "\0002A";
    color: $dark-red;
    margin-left: 0.3rem;
  }
  .form-control {
    height: 2.5 * $base-height;
    box-shadow: none;
  }

  textarea.form-control {
    height: auto !important;
    resize: none;
  }
  label {
    font-size: 0.875 * $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $gondola-grey;

    @media (max-width: $screen-xs) {
      margin-top: 3%;
    }
  }

  .form-control.is-invalid:focus {
    border: solid 0.063 * $base-border-width $light-grey;
    box-shadow: none;
  }
  .form-control.is-invalid {
    background-image: none;
    border: solid 0.063 * $base-border-width $light-grey;
  }

  .rc-time-picker-input,
  .rc-time-picker-input:focus {
    border: none;
    outline: none;
    font-size: 0.938 * $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: $charcoal-grey;
    text-transform: uppercase;
  }

  .title {
    padding: 2% 0 2% 0%;
    font-size: 1.625 * $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $cello-blue-2;
  }

  .error-message {
    font-size: 80%;
    color: $red;
  }

  .sidebar {
    .globOpt {
      object-fit: contain;
      margin-left: 1.4375 * $base-margin;
      margin-right: 1.4375 * $base-margin;
      line-height: 2.78;
      letter-spacing: 0.01125 * $base-padding;
      font-size: 1.125 * $base-font-size;
      font-weight: 600;
    }

    .menu-header {
      font-size: 1.25 * $base-font-size;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.013 * $base-padding;
      text-align: left;
      color: $sapphire-blue;

      @media (max-width: $screen-md-pro) and (min-width: $screen-md-pro) {
        font-size: 1.2 * $base-font-size;
      }

      @media (max-width: $screen-sm) and (min-width: $screen-sm) {
        font-size: 0.85 * $base-font-size;
      }

      @media (max-width: $screen-xs) {
        font-size: 1.2 * $base-font-size;
      }
    }

    a {
      text-decoration: none !important;
    }

    .menu-text {
      font-size: 1.125 * $base-font-size;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.78;
      letter-spacing: 0.011 * $base-padding;
      text-align: left;
      color: $light-slate-grey;
      cursor: pointer;
      text-decoration: none !important;

      display: flex;
      align-items: center;

      .menu-icon {
        width: 1.125 * $base-font-size;
        height: 1.125 * $base-font-size;
        margin-bottom: 2px;
        //margin-left: 1.4375 * $base-margin;
        //margin-right: 1.4375 * $base-margin;

        @media (max-width: $screen-sm) and (min-width: $screen-sm) {
          width: 0.7 * $base-width;
          height: 0.7 * $base-height;
        }

        @media (max-width: $screen-xs) {
          width: $base-width;
          height: $base-height;
          margin-left: $base-margin;
          margin-right: $base-margin;
        }
      }

      @media (max-width: $screen-sm) and (min-width: $screen-sm) {
        font-size: 0.7 * $base-font-size;
      }

      @media (max-width: $screen-xs) {
        font-size: $base-font-size;
      }
    }

    .menu-style {
      border-left: 0.125 * $base-border-width solid $light-slate-grey;
    }

    .menu-style:hover,
    .menu-style:focus {
      filter: invert(48%) sepia(100%) saturate(429%) hue-rotate(341deg)
        brightness(96%) contrast(99%);
      border-left: 0.125 * $base-border-width solid;
      color: $carrot-orange;
      font-weight: bold;
    }

    .active {
      filter: invert(48%) sepia(100%) saturate(429%) hue-rotate(341deg)
        brightness(96%) contrast(99%);
      border-left: 0.125 * $base-border-width solid;
      color: $carrot-orange;
      font-weight: bold;
    }
  }
  .tabs {
    .nav-item {
      @media (max-width: $screen-xs) {
        width: 33%;
        padding: 0;
      }
    }
    .nav-link {
      opacity: 0.65;
      font-size: 1.188 * $base-font-size;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $black-rock-blue !important;
      padding: 0.75rem;
      border: 0;
      border-bottom: 0.156rem solid transparent !important;
      margin-left: 0.125 * $base-margin;

      @media (max-width: $screen-sm) {
        font-size: 0.85 * $base-font-size;
      }
    }
    .nav-link:hover {
      border-color: transparent !important;
      color: $black-rock-blue !important;
      border: none !important;
      border-bottom: 0.156 * $base-border-width solid $carrot-orange !important;
      opacity: 1 !important;
      cursor: pointer;
      margin-left: 0.125 * $base-margin;
    }
    .active {
      opacity: 1;
      font-weight: 600;
      color: $black-rock-blue !important;
      border: none !important;
    }
  }
  #company-information,
  #contact-details,
  #business-hour,
  #campaigns-setting {
    border-radius: 0.25 * $base-border-radius;
    box-shadow: 0 0 0.375 * $base-shadow 0 $light-black;
    background-color: $white;
    margin: 3% 0 3% 0;
    padding: 0.938 * $base-padding;
    padding-top: 1.875 * $base-padding;

    .tab-logo {
      margin: 4% 0 0 3%;
      width: 2.5 * $base-width;
      height: 2.206 * $base-height;
      object-fit: contain;
    }
    .tab-title-block {
      .tab-title {
        font-size: 1.375 * $base-font-size;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        color: $black-rock-blue;
        margin-bottom: 0.375 * $base-margin;

        @media (max-width: $screen-sm) {
          font-size: 1.3 * $base-font-size;
        }
      }
      .tab-subtitle {
        opacity: 0.8;
        font-size: 1 * $base-font-size;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $black-rock-blue;
      }
    }
    .form-block {
      .input-label {
        font-size: 0.875 * $base-font-size;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: $gondola-grey;
        margin-top: 1 * $base-margin;
      }
      .informationLogo {
        margin-left: 1%;
      }
    }
    .save-button-block {
      margin-left: 9%;
      .save-button {
        border-width: 0;
        width: 8 * $base-width;
        height: 2.5 * $base-height;
        border-radius: 0.25 * $base-border-radius;
        border-color: $carrot-orange;
        background-color: $carrot-orange;
        margin: 3.5% 0 5% 2%;
        color: $white;
      }
    }
    .error-message {
      color: $toast-error;
    }
    .success-message {
      color: $toast-success;
      opacity: unset !important;
    }
  }
  #company-information {
    .uploadLogoBlock {
      border-radius: 0.25 * $base-border-radius;
      border: solid 0.063 * $base-border-width $grey;
      background-color: $white;
      .uploadLogoImage {
        width: 6.75 * $base-width;
        height: 6.75 * $base-height;
        object-fit: contain;
        margin: 2.4% 0 0 2.9%;
        border-radius: 0.25 * $base-border-radius;
        border: solid 0.063 * $base-border-width $grey1;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 1.25 * $base-font-size;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
        }
        label {
          font-size: $base-font-size;
          opacity: 0.6;
        }
      }
      .button-block {
        width: 78%;
        margin: 3.7% 0 0 3.3%;
        .upload-logo-button {
          font-size: 0.938 * $base-font-size;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          margin: 0 3% 0 4%;
          .inputFile {
            position: absolute;
            opacity: 0;
            width: 5.625 * $base-width;
          }
          input[type="file"]::-webkit-file-upload-button {
            cursor: pointer;
          }
        }

        .upload-logo-instruction {
          font-size: 0.875 * $base-font-size;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: $black-rock-blue;
          margin: 3% 0 3.5% 2%;
        }
        .upload-logo-instruction-valid {
          opacity: 0.6;
        }
        .buttons {
          @media (max-width: $screen-xs) {
            margin-top: 0.5 * $base-top;
            margin-left: 0.5 * $base-left;
            width: 7rem;
          }
        }
      }
    }
    .description-block {
      margin-left: 9%;
      .description-label {
        margin: 3.5% 0 0 0;
        font-size: 0.875 * $base-font-size;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $gondola-grey;
      }

      .description-limit {
        font-size: 0.875 * $base-font-size;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: right;
        color: $gondola-grey;
      }
    }
    .website-block {
      margin-left: 9%;
      .website-label {
        margin: 3.5% 0 0 0;
        font-size: 0.875 * $base-font-size;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $gondola-grey;
      }
    }
  }
  #contact-details {
    .form-heading {
      font-size: 1.125 * $base-font-size;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $black-rock-blue;
    }
  }
  #business-hour {
    .rc-time-picker-clear {
      display: none;
    }

    .week-days-row {
      .week-day {
        margin: 4.2% 0 0 13.3%;
        font-size: 1 * $base-font-size;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $gondola-grey;
      }
      .time-seprater {
        margin: 3%;
      }
      .time-picker {
        display: block;
        width: 100%;
        height: calc(1.5rem + 0.75rem + 0.125rem);
        padding: 0.375 * $base-padding 0.75 * $base-padding;
        font-size: 1 * $base-font-size;
        font-weight: 400;
        line-height: 1.5;
        color: $gray-700;
        background-color: $white;
        background-clip: padding-box;
        border: 0.063 * $base-border-width solid $gray-400;
        border-radius: 0.25 * $base-border-radius;
        -webkit-transition: border-color 0.15s ease-in-out,
          -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out,
          -webkit-box-shadow 0.15s ease-in-out;
        -o-transition: border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
          -webkit-box-shadow 0.15s ease-in-out;
      }
      .rc-time-picker-clear {
        top: 0.5 * $base-top;
      }
    }
    .error-message {
      margin-top: 0.25rem;
      font-size: 80%;
      color: $red;
    }
  }
}

.rc-time-picker-panel {
  width: 14.375 * $base-width !important;
  max-width: fit-content;

  .rc-time-picker-panel-input {
    text-transform: uppercase;
  }

  .rc-time-picker-panel-select {
    width: 4.375 * $base-width !important;
    li {
      text-transform: uppercase;
    }
    li.rc-time-picker-panel-select-option-selected {
      background: $carrot-orange;
      color: $white;
      outline: none;
    }

    li:hover {
      background: $carrot-orange !important;
      color: $white !important;
    }
    li:focus {
      outline: none;
    }
  }
}

#campaigns-setting {
  border-radius: 0.25 * $base-border-radius;
  box-shadow: 0 0 0.375 * $base-shadow 0 $light-black;
  background-color: $white;
  padding: 4% 0 0 0;
  .required-field::after {
    content: "\0002A";
    color: $dark-red;
    margin-left: 0.2rem;
  }

  .followUp {
    padding: 3.2% 0 0 0;
    width: max-content;
    font-size: 1.25 * $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $black-rock-blue;
  }
  .followUpSubtitle {
    font-size: 0.875 * $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $grey-dark;
  }

  .form-block {
    margin: 1.563 * $base-margin 0;
    font-size: 0.875 * $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: $gondola-grey;
  }

  .saveChangesButton {
    width: 8 * $base-width;
    height: 2.5 * $base-height;
    margin: 0.75 * $base-margin 0 0 6.25 * $base-margin;
    font-size: 1 * $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    border-width: inherit;
    color: $white;
    border-radius: 0.25 * $base-border-radius;
    background-color: $carrot-orange;
  }
}

#texting-settings {
  border-radius: 0.25 * $base-border-radius;
  box-shadow: 0 0 0.375 * $base-shadow 0 $light-black;
  background-color: $white;
  padding: 4.2% 0 3% 0;
  .required-field::after {
    content: "\0002A";
    color: $dark-red;
  }
  .texting-setting-logo {
    width: 2.375 * $base-width;
    height: 1.956 * $base-height;
    margin-left: 3.3%;
    margin-top: 0.8%;
  }
  .texting-setting-title-block {
    margin-left: 2.4%;
    .texting-setting-title {
      font-size: 1.375 * $base-font-size;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      color: $black-rock-blue;
    }
    .texting-setting-subtitle {
      opacity: 0.8;
      font-size: 1 * $base-font-size;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $black-rock-blue;
      margin-top: 2%;
    }
  }
  .title {
    width: max-content;
    font-size: 1.25 * $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $black-rock-blue;
  }
  .subtitle {
    font-size: 0.83 * $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $grey-dark;
  }
  .provisionedNumberBlock {
    .number {
      font-size: $base-font-size;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $black-rock-blue;
    }
  }
  .provisioned-info-msg {
    color: $grey-dark;
    font-size: 0.875 * $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: oblique;
    line-height: 2.86;
    letter-spacing: normal;
  }
  .textingTitle {
    font-size: 0.938 * $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $black-rock-blue;
  }

  label {
    font-size: $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: $black-rock-blue;
  }

  .radioButton {
    .radioTitle {
      margin-left: 1%;
      font-weight: normal;
      font-size: 1rem;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $black-rock-blue;
    }
    input[type="radio"] {
      height: 1.125 * $base-height;
      width: 1.125 * $base-width;
    }
    .updateButtonBlock {
      .updateHourButton {
        margin-left: 0.5 * $base-margin;
        border-radius: 0.25 * $base-border-radius;
        border: solid 0.063 * $base-border-width $carrot-orange;
        background-color: $white;
        color: $carrot-orange;
      }
    }
  }
  .contentBlock {
    max-width: 47.5 * $base-width;
    .fieldLabel {
      @media (max-width: $screen-xs) {
        padding-right: 1.5rem;
      }
      font-size: 0.875 * $base-font-size;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      color: $gondola-grey;
    }
    .reset {
      font-size: 0.875 * $base-font-size;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: $approx-curious-blue;
      cursor: pointer;
      background: transparent;
      border: none;
      outline: none;
    }

    button:disabled {
      cursor: default;
    }

    .contentInput {
      resize: none;
      height: 8.125 * $base-height;
    }
    .saveButton {
      margin-top: 1.563 * $base-margin;
      margin-bottom: 1.625 * $base-margin;
      font-size: 1 * $base-font-size;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $white;
      border-radius: 0.25 * $base-border-radius;
      padding: 0.625 * $base-padding 1.625 * $base-padding;
      border-width: 0;
      background-color: $carrot-orange;
    }
  }
}
