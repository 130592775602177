@import "styles/variables.scss";
@import "styles/mixins.scss";

.modal-view {
  overflow: hidden !important;
  .modal-dialog {
    max-width: 50rem;
    width: 50rem;
    color: $black-rock-blue;
    font-weight: normal !important;

    .modal-content {

      .modal-body {
        padding: 1rem;
        color: $black-rock-blue;
        font-size: 0.95rem !important;
        font-weight: normal !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        text-align: left;
        font-family: sans-serif !important;
      }

      .modal-header {
        padding: 1.5rem 2rem;

        .modal-title {
          font-size: 1.7333rem;
          font-weight: normal;
          line-height: 0.69;
          text-align: left;
          color: $white;
        }

        .close {
          font-size: 3.333rem;
          padding: 0.4rem 0.8rem;
        }
      }
    }
  }
}

#reviews-overview {

  .reviews-heading {
    font-size: 1.25 * $base-font-size;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.013 * $base-font-size;
    text-align: left;
    color: $sapphire-blue;
  }

  .reviews-subheading {
    font-size: $base-font-size;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.02 * $base-margin;
    text-align: left;
    color: $black-rock-blue;
    margin-bottom: 0 * $base-margin;
    padding: 3 * $base-width-pct;
    padding-bottom: 0 * $base-padding;
  }

  .reviews-border {
    border-radius: 0.25 * $base-border-radius;
    min-height: 8.6 * $base-height;
    height: 100%;
    border: solid 0.075 * $base-width $zumthor-grey;
    background-color: $white;
  }

  .arrow {
    width: 0.775rem;
    height: 1.125rem;
  }

  .overiew-stats {
    display: flex;
    flex: 1;
    align-items: center;
    .reviews-count {
      font-size: 2 * $base-font-size;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.56;
      letter-spacing: normal;
      text-align: left;
      color: $black-rock-blue;
      padding-left: 3 * $base-width-pct;

      .px-2 {
        min-width: 16px;
      }
    }

    .reviews-percentage {
      font-size: $base-font-size;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
    }
    .reviews-percentage.no-change {
      color: $grey-dark;
    }

    .down-percentage {
      color: $toast-error;
    }
    .up-percentage {
      color: $fern-green;
    }

    .fa-arrow-down,
    .fa-arrow-up {
      padding-left: 0.625 * $base-padding;
      padding-right: 0.313 * $base-padding;
    }
  }

  .pending-review-sub-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.16px;
    text-align: left;
    color: $grey-dark;
    padding-left: 3%;
  }

  .reviews-learn-more {
    font-size: $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.01 * $base-margin;
    text-align: center;
    color: $approx-curious-blue !important;
    padding-left: 3%;
    cursor: pointer;
  }
}