@import "styles/variables.scss";
@import "styles/mixins.scss";

#review-list {
  .review-tile {
    box-shadow: 0 0 0.375 * $base-border-width 0 $light-black;
    margin-top: 1.25 * $base-margin;
    background-color: $white;
    padding: 10px;
  }
  .platform-image {
    height: 3.0875 * $base-height;
    width: 6.875 * $base-width;
  }
  .ellipse {
    height: 2.125 * $base-height;
    width: 2.125 * $base-width;
    @media (max-width: $screen-sm) {
      height: 1.5 * $base-height;
      width: 1.5 * $base-width;
    }
    border-radius: 50%;
    background-color: $rock-blue;
    vertical-align: middle;
  }
  .name-initials {
    font-size: 0.875 * $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    color: $white;
  }
  .reviewer-name,
  .reviewer-name a {
    font-size: $base-font-size;
    font-weight: 6 * $base-font-weight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $black-rock-blue;
  }
  .dot {
    margin-top: 0.28 * $base-margin;
    margin-left: 0.375 * $base-margin;
    margin-right: 0.375 * $base-margin;
    font-size: 0.875 * $base-font-size;
    font-weight: 9 * $base-font-weight;
    font-stretch: normal;
    font-style: normal;
    color: $dim-gray1;
  }
  .review-type {
    font-size: 0.875 * $base-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $dim-gray1;
    margin-top: 0.1 * $base-margin;
  }
  .subject {
    font-size: 1.0625 * $base-font-size;
    font-weight: 5 * $base-font-weight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $black-rock-blue;
    word-wrap: break-word;
  }
  .review-body {
    opacity: 0.8;
    font-size: 0.9375 * $base-font-size;
    font-weight: 5 * $base-font-weight;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $black-rock-blue;
    word-wrap: break-word;
  }
  .review-lines {
    max-height: 48px;
    overflow: hidden;
    position: relative;
    background: white;
    word-break: break-word;
  }
  .subject .review-lines {
    max-height: 29px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .review-lines:after {
    content: " ...";
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 0.3em;
    background: inherit;
  }
  .subject .review-lines:after {
    content: "";
  }

  .review-lines span:after {
    min-height: 30px;
    content: "\0000a0";
    position: absolute;
    width: 1000px;
    z-index: 1;
    background: white;
  }

  .pagination {
    .previous,
    .next {
      a:hover {
        text-decoration: none;
      }
      span {
        font-size: 0.9375 * $base-font-size;
        font-weight: 6 * $base-font-weight !important;
        font-stretch: normal !important;
        font-style: normal !important;
        letter-spacing: normal;
        color: $approx-curious-blue;
      }
    }
    a,
    a:focus {
      outline: none;
    }
    .page-counts {
      margin: 0 0.5 * $base-margin 0 0.5 * $base-margin;
      cursor: pointer;
      color: $approx-curious-blue;
    }
    .disable-page {
      opacity: 0.5;
      font-size: $base-font-size;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: $black-rock-blue;
      cursor: not-allowed;
      span {
        color: $black-rock-blue !important;
      }
    }
    .active-page {
      width: 1.5 * $base-width;
      height: 1.5 * $base-height;
      border-radius: 0.25 * $base-border-radius;
      border: solid 0.0625 * $base-border-width $approx-curious-blue;
      background-color: $approx-curious-blue-opacity;
      outline: none;
    }
  }
  .manage-review-logo {
    max-width: 5.5rem;
  }
}