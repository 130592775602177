@import "styles/variables.scss";
@import "styles/mixins.scss";

// Customers Content Component
.customers-content-view {
    margin: 1rem !important;
    .customerlist-label {
        margin-top: 2.1rem;
        margin-bottom: 1.3rem;
        text-align: left;
        letter-spacing: 0.022rem;
        color: $black-rock-blue;
        font-size: 1.46rem;
        font-stretch: normal;
        font-weight: bold;
        font-style: normal;
    }

    .primary-message-style {
        font-size: 2.8rem;
    }

    .secondary-message-style {
        font-size: 1.2rem;
        max-width: 46.667rem;
        margin: auto;
        margin-bottom: 1.333rem;
    }

    .customers-table-container .table-responsive {
        border: 0.06rem solid $border-light !important;
    }

    .customer-table {
        td:nth-of-type(3) {
            width: 8rem;
        }
        td:nth-of-type(4) {
            max-width: 10rem;
            span {
                display: inline-block;
                width: 100%;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        td {
            vertical-align: middle;
            z-index: 11;
        }

        @media (max-width: $screen-ipad-pro) {
            position: relative;
            th:last-of-type,
            td:last-of-type {
                position: sticky;
                right: -0.133rem;
                z-index: 10;
                box-shadow: 0rem 0.4rem 0.5rem $very-light-gray;
                padding-left: 2.1rem !important;
                padding-right: 0rem !important;
                border: 0.06rem solid $border-light;
                min-width: 4rem;
            }
            th:last-of-type {
                background-color: $lightest-blue;
            }
            td:last-of-type {
                background-color: $white;
            }
            .sg-dropdown .dropdown-menu {
                margin-top: -0.53rem;
                margin-left: -1rem;
            }
            .dropdown-toggle {
                margin-left: 2rem;
            }
            .dropdown-menu {
                min-width: 13.3rem;
                min-height: 6.5rem;
                margin-right: 0.9rem !important;
            }
            .dropdown-item {
                min-height: 2.6rem;
                margin-top: 0.4rem;
                margin-bottom: 0.4rem;
            }
        }

        th:last-of-type,
        td:last-of-type {
            z-index: 10;
            padding-left: 0rem !important;
            padding-right: 0rem !important;
        }

        .column-service-date {
            min-width: 8.8rem;
        }
        .column-created-on {
            min-width: 9.3rem;
        }
        .column-phone {
            min-width: 9rem;
        }
        .options-column {
            min-width: 0.3rem;
        }
    }

    .customers-table-address-column-tooltip {
        .tooltip-inner {
            padding: 0.5rem 0.75rem 0.75rem 0.75rem;
        }
        @media (max-width: $screen-ipad-pro) {
            top: -0.75rem !important;
            .tooltip-inner {
                max-width: 40rem;
            }
        }
    }

    .option-dropdown {
        .dropdown-item {
            min-height: 2.6rem;
            margin-top: 0.6rem;
            margin-bottom: 0.6rem;
            display: flex;
            align-items: center;
        }

        .dropdown-menu {
            min-width: 13.3rem;
            min-height: 6.5rem;
            margin-right: 0.4rem !important;
        }
    }

    .customer-details-sidebar-restrictor {
        position: absolute;
        top: -0.1333rem;
        right: -0.4rem;
        z-index: 20;
        border-left: 1px solid $light-grey;
        background: $twilight-blue-grey;
        width: 350px;
    }

    .customer-name {
        color: $approx-curious-blue;
    }
    .show-filter-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
