.buttons-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 32px;

  .static-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .widget-img {
    width: 110px;
    height: 110px;
  }

  .static-button-bottom {
    width: 110px;
    height: 20px;
    border-radius: 10px;
    background-color: #428bca;
    color: white;
  }

  .blue-lobby-img {
    width: 50px;
    height: 38px;
    margin-top: 14px;
  }

  .button-container {
    width: 316px;
    height: 158px;
    border-radius: 6px;
    border: solid 1px #c1c1c1;
    background-color: rgba(243, 242, 242, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-container-appointments {
    width: 316px;
    min-height: 158px;
    border-radius: 6px;
    border: solid 1px #c1c1c1;
    background-color: rgba(243, 242, 242, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .button-container-tall {
    padding: 16px;
    border-radius: 6px;
    border: solid 1px #c1c1c1;
    background-color: rgba(243, 242, 242, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-outer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 31px;
  }

  .copy-code {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    cursor: pointer;
    margin-top: 6px;
    text-align: right;
    color: #428bca;
  }
}
