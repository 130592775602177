@import "styles/variables.scss";
$review-results-header-link-color: #428bca !default;

.review-results-container {
  background-color: $page-bg-color-light;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
}

.review-results-header-link {
  color: $review-results-header-link-color;
  margin-right: 1.5rem;
}

.review-results-link {
  color: $review-results-header-link-color;
}

.review-results-block {
  padding-left: $page-gutter;
  padding-right: $page-gutter;
  padding-top: $page-gutter;
}

.response-rate-positive {
  color: $sg-text-success;
}

.response-rate-negative {
  color: $sg-text-danger;
}

.response-rate-neutral {
  color: $grey-dark;
}

.highcharts-legend-item {
  &.highcharts-line-series {
    .highcharts-graph {
      stroke-width: 0 !important;
    }
  }
}

#TabsComponent.reviews {
  .tabs {
    border-bottom: 2px solid #dbddda;
    margin-bottom: 2px;
    .nav-link {
      position: relative;
      top: 2px;
    }
  }
}
