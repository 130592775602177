@import "styles/variables.scss";
@import "styles/mixins.scss";

#modal-popup-right-view {
  .modal-body {
    background-color: $twilight-blue-grey;
    overflow-x: hidden;
  }
  .modal-header {
    background-color: $twilight-blue-grey;
    border-bottom: 0;
  }
  .modal-dialog {
    float: right;
    margin-top: 4 * $base-margin;
    margin-bottom: 0;
    max-width: 59.375 * $base-border-width;
    min-width: 59.375 * $base-border-width;

    @media (max-width: $screen-md) {
      min-width: 60%;
    }
  }
  .modal-content {
    height: calc(100vh - 4rem);
    overflow: hidden;
  }
  .review-details-title {
    @include font-setting(1.625* $base-font-size, $black-rock-blue,0.69);
    cursor: pointer;
    img {
      vertical-align: top;
      margin-top: -0.1 * $base-margin;
      margin-right: $base-margin;
    }
  }
}
