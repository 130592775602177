.tooltip-inner {
  -webkit-font-smoothing: auto;
}
.tooltip-p-10 {
  .tooltip-inner {
    padding: 10px;
  }
}

.tooltip-w-213 {
  .tooltip-inner {
    min-width: 213px;
    width: 213px;
  }
}

.tooltip-w-233 {
  .tooltip-inner {
    min-width: 233px;
    width: 233px;
  }
}

.tooltip-w-300 {
  .tooltip-inner {
    min-width: 300px;
    width: 300px;
  }
}

.tooltip-w-333 {
  .tooltip-inner {
    min-width: 333px;
    width: 333px;
  }
}