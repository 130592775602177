@import "styles/variables.scss";
@import "styles/mixins.scss";

.track-reviews-overview {
  .container {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }

  .track-reviews-card {
    height: 100px;
    width: 19%;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border-radius: 4px;
    border: solid 1.2px #cccfd3;
    align-items: center;
    margin-bottom: 1rem;
    box-sizing: border-box;
  }

  .count {
    font-size: 32px;
    font-weight: bold;
    color: black;
  }

  .lengthy {
    font-size: 24px !important;
  }

  .label {
    font-size: 15px;
    color: #667587;
  }

  .track-overview-img {
    height: 46px;
    width: 42px;
  }

  @media only screen and (max-width: 1425px) {
    .track-reviews-card {
      width: 190px;
    }
  }
}
