@import "styles/variables.scss";
@import "styles/mixins.scss";

.tile {
  @include tile();
}

.ellipse {
  @include ellipse();
  overflow: hidden;
  word-wrap: break-word;
  @media (max-width: $screen-sm) {
    height: 2.125 * $base-height;
    width: 2.125 * $base-height;
  }
}

.name-initials {
  @include font-setting(0.875 * $base-font-size, $white, 1.38, center);
}

.name {
  cursor: pointer;
  @include font-setting(
    $base-font-size,
    $black-rock-blue,
    1.38,
    left,
    5 * $base-font-weight
  );
  margin-top: 0.1875 * $base-margin;
}

.type {
  @include font-setting(0.875 * $base-font-size, $dim-gray1, 1.68, left);
  margin-top: 0.1 * $base-margin;
}

.subject {
  @include font-setting(
    1.0625 * $base-font-size,
    $black-rock-blue,
    1.38,
    left,
    5 * $base-font-weight
  );
  @media (max-width: $screen-xs) {
    line-height: 1.15;
  }
  word-wrap: break-word;
}

.summary {
  opacity: 0.8;
  word-wrap: break-word;
  @include font-setting(
    0.9375 * $base-font-size,
    $black-rock-blue,
    1.2,
    left,
    5 * $base-font-weight
  );
}
