.help-and-get-started {
  --navbarHeight: 60px;
  position: absolute;
  top: 63px;
  right: 0;
  width: 480px;
  height: calc(100vh - var(--navbarHeight));
  box-shadow: 0 3px 9px 0 #0000003b;
  border: solid 1px #e3e7ed;
  background-color: #ffffff;
  z-index: 1;
}

.help-and-get-started .header {
  /* todo: fix font sizes */
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: 0.3px;
  text-align: left;
  color: #313541;
  padding: 33px 16px;
  padding-bottom: 20px;
}

.help-and-get-started .close-button {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
}

.help-and-get-started .tabs {
  display: flex;
  top: 8px;
  right: 8px;
  border-bottom: 2px solid #ddd;
  margin-bottom: 2px;
  padding: 0 16px;
  font-size: 0.8rem;
}

.help-and-get-started .tabs .fa {
  font-size: 0.8rem;
  font-weight: 300;
}

.help-and-get-started .tabs .tab {
  cursor: pointer;
  color: #313541;
}

.help-and-get-started .tablabelactive {
  border-bottom: 2px solid #f47821;
  padding-bottom: 1px;
  color: #313541;
}

.help-and-get-started .tabs div {
  margin-right: 6px;
}

.help-and-get-started .content {
  margin-top: -2px;
}

.help-and-get-started .list-item {
  margin-top: -1px;
  display: flex;
  height: 112px;
  align-items: center;
  padding: 24px 16px;
  border: 1px solid #e3e7ed;
}

.help-and-get-started .list-item:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

.help-and-get-started .preview-img {
  max-width: 48px;
  margin-right: 12px;
}

.help-and-get-started .list-item .label {
  font-size: 0.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.24px;
  text-align: left;
  color: #313541;
}

.help-and-get-started .list-item .secondary-label {
  font-size: 0.8rem;
  margin-top: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.24px;
  text-align: left;
  color: #428bca;
}

.help-and-get-started .list-item .description {
  padding-top: 4px;
  font-size: 0.8rem;
  color: #313541;
}
