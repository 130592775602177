@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

#manage-tab-component, #track-tab-component {
  background-color: $twilight-blue-grey;

  label{
    text-transform: capitalize;
  }

  @media (min-width: $screen-1600) {
    .sidebar-view {
      flex: 0 0 $sidebar-gutter;
      max-width: $sidebar-gutter;
    }

    .content-view {
      flex: 0 0 calc(100% - #{$sidebar-gutter});
      max-width: calc(100% - #{$sidebar-gutter});
    }
  }
}
#track-tab-component {
  @media (max-width: $screen-xs) {
    display: none;
  }
}
