@import "styles/variables.scss";

.segment-container {
  position: relative;
  .form-label {
    display: inline-block;
    padding-top: 0.5333rem;
    line-height: 1.22;
    letter-spacing: normal;
    color: $charcoal-grey;
    font-size: 1rem;
    font-weight: normal;
  }
  .form-control {
    height: 2.6667rem;
  }
  .segment-name-container {
    display: inline-block;
    min-width: 482px;
    #segmentName {
      margin-top: 0.4rem;
    }
    .input-container {
      &:after {
        position: absolute;
        top: 3.3333rem;
        right: 1.6rem;
        background: url("../../../../styles/assets/segments/custom-segment-icon.svg")
          center;
        width: 1.2667rem;
        height: 1.2667rem;
        content: " ";
      }
      input {
        padding-right: 2.5rem;
      }
      .form-control.is-invalid {
        border: solid 0.063 * $base-border-width $light-grey;
        box-shadow: none;
        background: none;
      }
    }

    .segment-name-messages {
      margin-top: 0.4rem;
      width: 100%;
      min-height: 1.6rem;
      line-height: 1.6rem;
      color: $sg-text-danger;
      font-size: 0.9333rem;

      .segment-name-count {
        display: inline;
        float: right;
        line-height: 1.47;
        color: $charcoal-grey;
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }
  .sg-card.segment-content-card {
    padding-left: 2rem;
    min-height: calc(100vh - 12.8rem);
    .sg-card-title {
      line-height: 1;
      color: $cello-blue-2;
      font-size: 1.7333rem;
      font-stretch: normal;
      font-weight: 500;
    }
    .sg-card-sub-title {
      line-height: 2.2;
      letter-spacing: normal;
      color: $matterhorn-gray;
      font-size: 1rem;
      font-weight: 300;

      .segmentation-learn-more {
        cursor: pointer;
        color: $approx-curious-blue;
        font-weight: 600;
      }
    }
    .segment-accordion-container {
      position: relative;
      left: -2rem;
      .segment-accordion {
        .icon-accordion-toggle:before {
          content: "\f107";
          font-family: "FontAwesome";
          font-size: 1.5rem;
        }
        .fa-chevron-up:before {
          content: "\f106";
        }
        .fa-chevron-down:before {
          content: "\f107";
        }
        .active {
          color: $white;
          background: $light-slate-grey;
        }
        .view-contacts-container {
          margin-bottom: 2px;
          .customers-component-container {
            position: relative;
            min-height: 775px;
            overflow: hidden;
          }
        }
        .segment-toggle-target {
          position: relative;
          border: solid 1px rgba(103, 103, 103, 0.16);
          padding: 2rem;
          min-height: 109px;

          .segment-section-title-container {
            display: inline-block;
            width: 100%;
            .segment-section-title {
              height: 1.8rem;
              line-height: 0.82;
              letter-spacing: 0.33px;
              color: $black-rock-blue;
              font-size: 1.4666rem;
              font-weight: 600;
            }
            .segment-section-subtitle {
              display: inline-block;
              position: absolute;
              opacity: 0.8;
              line-height: 1.33;
              color: $black-rock-blue;
              font-size: 1rem;
              font-weight: normal;
            }
          }

          h6 {
            position: absolute;
            top: 0;
            right: 0;
            align-items: center;
            padding: 2rem;
            width: 100%;
            height: 100%;
          }
        }
        .custom-toggle-button {
          height: 2.2667rem;
        }
        .selected-criteria-actions-container {
          margin-bottom: 0.4rem;
          min-height: 3.6667rem;
          line-height: 2;
          font-size: 1.2rem;
          font-weight: 500;

          .total-contacts-result {
            font-size: 1.3333rem;
          }
          .btn {
            margin-top: 0.4rem;
            margin-bottom: 0.8rem;
            padding-bottom: 0.8rem;
            height: 2.2667rem;
          }
        }
        .col-6:first-of-type {
          .selected-criteria-actions-container {
            padding-bottom: 4.0667rem;
          }
        }
        .selected-criteria {
          > .row {
            margin-left: 0;
            margin-right: 0;
          }

          .selected-criteria-card {
            border: solid 1px rgba(0, 0, 0, 0.1);
            border-radius: 0.2rem;

            .selected-criteria-card-header {
              border-bottom: solid 1px rgba(0, 0, 0, 0.1);
              padding: 1.0667rem;
              line-height: 1;
              color: $black-rock-blue;
              font-size: 1rem;
              font-weight: 600;
              &.active {
                @extend .active;
              }
            }

            .add-criteria-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 0.8667rem;
              min-height: 230px;

              .add-criteria {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                padding: 1rem;

                line-height: 1;
                color: $carrot-orange;
                font-size: 0.9333rem;
                font-stretch: normal;
                font-weight: 500;
                font-style: normal;
                img {
                  margin-bottom: 1rem;
                  width: 1.6rem;
                  height: 1.6rem;
                }
              }
            }
            .show-criteria-container {
              display: inline-block;
              padding: 0.8667rem;
              width: 100%;
              min-height: 230px;

              .show-criteria-category {
                padding-bottom: 2rem;
                margin-bottom: 1rem;
                &:not(:last-of-type) {
                  border-bottom: 1px solid rgba(103, 103, 103, 0.25);
                }
                .show-criteria-divider {
                  margin: 0 0.75rem;
                }
              }
            }

            &.empty-state {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 606px;

              img {
                width: 220px;
                height: 220px;
              }

              .message-body {
                max-width: 444px;
                text-align: center;
                font-size: 1.0667rem;

                h3 {
                  padding-top: 1rem;
                  line-height: 2.5rem;
                  letter-spacing: 0.33px;
                  color: $black-rock-blue;
                  font-size: 1.4667rem;
                  font-weight: 600;
                }
              }
            }
          }
        }

        .criteria-form-card {
          border: solid 1px rgba(0, 0, 0, 0.1);
          border-radius: 0.2rem;

          .criteria-form-card-header {
            border-bottom: solid 1px rgba(0, 0, 0, 0.1);
            padding: 0.8667rem 1.0667rem;
            color: $black-rock-blue;
            font-size: 1rem;
            font-weight: 600;
            &.active {
              @extend .active;
              .create-segement-tooltip-icon {
                margin-left: 0.6667rem;
              }
            }
          }
          .criteria-form-card-body {
            padding: 0 1rem;
            .expand-controls {
              padding: 1rem 0 0.5rem 0;
              span {
                cursor: pointer;
                color: rgba(49, 53, 65, 0.65);
                font-size: 0.9333rem;
              }
              span:last-of-type {
                float: right;
                color: $carrot-orange;
              }
              span:first-of-type {
                margin-right: 1.0667rem;
              }
            }
            &.advanced-filter {
              .custom-control-label {
                cursor: pointer;
                color: $approx-curious-blue;
                // important tag required to override another important tag
                font-size: 0.9333rem !important;
              }
            }

            .btn {
              height: 2.6667rem;
              font-size: 1.0667rem;
            }
          }
        }

        .form-accordion {
          border-left: 0.4rem solid $white;

          .form-toggle-target {
            position: relative;
            border: solid 1px rgba(103, 103, 103, 0.16);
            padding: 1rem;

            .form-section-title-container {
              .form-section-title {
                line-height: 1.06;
                letter-spacing: normal;
                color: $carrot-orange;
                font-size: 1.1333rem;
                font-weight: 600;
              }
            }

            h6 {
              position: absolute;
              top: 0;
              right: 0;
              align-items: center;
              padding: 1rem;
              width: 100%;
              height: 100%;
            }
          }
          .form-toggle-container {
            display: flex;
            line-height: 1.8;
            .custom-control {
              margin: 0 1rem;
            }
          }
          .form-label {
            padding-left: 1.2rem;
            min-width: 217px;
            &.criteria-touched:before {
              position: absolute;
              left: 0.2rem;
              background: url("../../../../assets/images/campaigns/check-orange-circle.svg");
              background-size: cover;
              width: 1.2rem;
              height: 1.2rem;
              content: " ";
            }
          }
          .create-segement-tooltip-icon {
            margin-left: 0.6667rem;
          }
          .time-since-last-transaction-type {
            display: inline-block;
            margin-right: 2rem;

            label.custom-control-label {
              // important tag required to override another important tag
              font-size: 1rem !important;
              line-height: 1.47;
              color: $charcoal-grey;
            }
          }
          .segment-slider-container {
            margin-bottom: 1.6667rem;
            border-bottom: 1px solid rgba(103, 103, 103, 0.25);
          }
          &.segment-section-active {
            border-left: 0.4rem solid $carrot-orange;
            .form-toggle-target {
              border-left: 0.47rem solid $white;
              padding-left: 0.6rem;
            }
          }
        }
      }
      @media (max-width: $screen-1600) {
        &.col-lg-12 {
          flex: 0 0 calc(100% + 4rem);
          max-width: calc(100% + 4rem);
        }
      }
    }
  }
  .submit-button-container {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    flex-direction: row;
    justify-content: end;
    margin-left: 1rem;
    border-top: solid 1px $light-grey;
    padding: 1.6667rem;
    width: calc(100% - 2rem);
    .btn {
      min-height: 2.6667rem;
      font-size: 1.0667rem;
      margin-left: 0.8rem;
    }
    .btn:first-of-type {
      min-width: 7.7333rem;
    }
    .btn:last-of-type {
      min-width: 10.1333rem;
    }
  }

  .collapse > div,
  .collapse.show > div,
  .collapsing > div {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (max-width: $screen-1600) {
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .offset-xl-5 {
      margin-left: 50%;
    }
  }
  @media (max-width: $screen-ipad-pro) {
    .show-all-container {
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (max-width: $screen-lg) {
    .col-xl-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .offset-xl-5 {
      margin-left: 0;
    }
  }
}

.dark-mode {
  .collapse > div,
  .collapse.show > div,
  .collapsing > div {
    border: 1px solid $dark-shuttle-gray;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
