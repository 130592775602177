@import "styles/variables.scss";

.customer-transactions-container {
  margin-top: 0.6667rem;

  .accordion {
    .transactions-txt-bold {
      font-size: 1.4667rem;
      font-weight: bold;
      color: $black-rock-blue;
    }

    .no-transactions-text {
      opacity: 0.7;
      border: none;
      text-align: left;
      color: $black-rock-blue;
      font-size: 1rem;
      font-weight: 500;
    }

    .transactions-txt {
      margin-top: 0.3333rem;
      color: $lynch-blue;
      font-size: 1rem;
      font-weight: normal;
    }

    .vertical-divider {
      border-left: 1px solid $clear-day-gray;
      width: 2%;
      height: 4.6667rem;
    }

    .center {
      text-align: center;
    }

    .date-txt {
      opacity: 0.9;
      color: $black-rock-blue;
      font-size: 1rem;
      font-weight: 500;
      border: none;
      padding: 0.25rem  0;
    }
    .transaction-box {
      margin-top: 0.26667rem;
      border: none;
      .number-of-transactions-container {
        width: 49%;
        .number-of-transactions {
          max-width: 6rem;
        }
      }
      .transaction-section {
        margin-left: 5%;
        width: 50%;
        height: 85%;
      }
    }
    .last-service-date {
      margin-top: 1.1333rem;
    }
  }
}

@media (max-width: 1300px) {
  .customer-transactions-container {
    .transaction-box {
      border: none;
      display: flex;
      flex-direction: column;
      .transaction-section {
        width: 100%;
      }
    }
    .transactions-txt-bold {
      color: $black-rock-blue;
      font-size: 1.467rem;
      font-weight: bold;
    }
    .no-transactions-text {
      opacity: 0.7;
      text-align: left;
      color: $black-rock-blue;
      font-size: 1rem;
      font-weight: 500;
    }
    .transactions-txt {
      margin-top: 0.333rem;
      color: $lynch-blue;
      font-size: 1rem;
      font-weight: normal;
    }
    .vertical-divider {
      border-bottom: 1px solid $clear-day-gray;
      width: 5.333rem;
      height: 2%;
    }
  }
}
