.edit-email-success {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  .edit-success-inner-container {
    display:flex;
    width: 100%;
    height: 42px;
    background: #1bc652;
    justify-content: center;
    align-items: center;
  }

  .edit-email-success-toast {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }

  .success-circle-img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

}
