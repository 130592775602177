@import 'styles/variables.scss';

#track-table-container {
  display: flex;
  flex-direction: column;
  position:relative;

  .hold-rows {
    display:flex;
    flex-direction: column;
    width: 100%;
    min-height: $base-min-height;
    border: solid 1px $border-light;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px $border-light;
    background-color: $lightest-blue;
    padding-left: 15px;
    height: 44px;
  }

  .header-label {
    font-size: 13px;
    font-weight: 500;
    color: $black-rock-blue;
    margin-right: 0.25rem;
  }

  .header-img-container {
    display:flex;
    width: 20%;
    margin-right: 1rem;
    align-items: center;
  }

  .header-arrows-container {
    display:flex;
    flex-direction: column;
  }

  .header-up-arrow {
    width: 6px;
    height: 6px;
    object-fit: contain;
    cursor: pointer
  }

  .header-down-arrow {
    width: 6px;
    height: 6px;
    object-fit: contain;
    cursor: pointer
  }

  .header-label-container {
    display: flex;
    align-items: center;
  }

  .table-row {
    display:flex;
    min-height: 58px;
    border-bottom: solid 1px $border-light;
    padding-left: 15px;
    background: white;
  }

  .row-size {
    width: 20%;
    display:flex;
    align-items: center;
    word-break: break-all;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .name-container {
    display:flex;
    align-items: center;
  }

  .name-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    background-color: $rock-blue;
    border-radius: 50%;
    margin-right: 10px;
  }

  .name {
    font-size: 15px;
  }

  .row-text {
    font-size: 15px;
    word-break: break-word;
  }

  .status-container {
    position: relative;
    display: flex;
    align-items: space-between;
    justify-content: space-between;
  }

  .status-text {
    position: relative;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 22px;
    border-radius: 4px;
    border: solid 1px $lynch-blue;
    background-color: $shadow-white;
  }

  .options-outer-container {
    width: 100%;
    display: none;
    padding-right: 12px;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    z-index: 9999;
  }

  .options-container {
    display: flex;
    background: white;
    flex-direction: column;
    width: 200px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 $modal-background;
    border: solid 1px $modal-border;
    font-weight: bold;
  }

  .option-one {
    display: flex;
    height: 40px;
    align-items: center;
    padding-left: 10px;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: $darker-gray;
    }
  }

  .option-two {
    display: flex;
    height: 40px;
    align-items: center;
    padding-left: 10px;
    width: 100%;
    cursor: pointer;
    color: $dark-gray;
    &:hover {
      background-color: $darker-gray;
      text-decoration: none;
    }
  }

  .table-img {
    margin-right: 12px;
    cursor: pointer;
  }
}
