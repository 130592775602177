.table-company-places {
  thead {
    background-color: #f7fafb;
    border: solid 1px #e3e7ed;
    th {
      border: 0;
      padding: 8px;
      font-size: 0.85rem;
      font-weight: normal;
      color: #313541;
    }
  }
  tr {
    border: solid 1px #e3e7ed;
  }
}
