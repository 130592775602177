// import project variables
@import "styles/variables.scss";

// override colors for button
$primary: #f47821 !default;

$form-feedback-valid-color: #5cb85c !default;
$form-feedback-invalid-color: #f74b31 !default;
$form-feedback-icon-valid: url("data:image/svg+xml,%3Csvg id='round-error-symbol' xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %235cb85c; %7D .cls-2 %7B fill: %23fff; %7D %3C/style%3E%3C/defs%3E%3Cg id='error'%3E%3Cpath id='Path_1193' data-name='Path 1193' class='cls-1' d='M9,0a9,9,0,1,0,9,9A9.026,9.026,0,0,0,9,0Zm.9,13.5H8.1V11.7H9.9Zm0-3.6H8.1V4.5H9.9Z'/%3E%3Ccircle id='Ellipse_24' data-name='Ellipse 24' class='cls-1' cx='9' cy='9' r='9'/%3E%3C/g%3E%3Cpath id='checked_4_' data-name='checked (4)' class='cls-2' d='M.1,6.808a.335.335,0,0,1,0-.485l.485-.485a.335.335,0,0,1,.485,0l.035.035,1.9,2.042a.167.167,0,0,0,.242,0L7.892,3.1h.035a.335.335,0,0,1,.485,0l.485.485a.335.335,0,0,1,0,.485h0L3.358,9.819a.335.335,0,0,1-.485,0L.173,6.912.1,6.808Z' transform='translate(5 2.999)'/%3E%3C/svg%3E%0A") !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,%3Csvg id='round-error-symbol' xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23f45b32; %7D %3C/style%3E%3C/defs%3E%3Cg id='error'%3E%3Cpath id='Path_1193' data-name='Path 1193' class='cls-1' d='M9,0a9,9,0,1,0,9,9A9.026,9.026,0,0,0,9,0Zm.9,13.5H8.1V11.7H9.9Zm0-3.6H8.1V4.5H9.9Z'/%3E%3C/g%3E%3C/svg%3E%0A") !default;
$input-disabled-bg: $white-smoke2 !default;

// custom controls
$custom-control-indicator-size: 1.2rem !default;
$custom-control-indicator-checked-color-bg: $primary !default;
$custom-control-indicator-checked-border-color: transparent !default;

// switch
$custom-control-switch-bg: #e2e2e2 !default;
$custom-switch-width: 2.8rem !default;
$custom-switch-indicator-size: 1.2rem !default;
$custom-switch-indicator-border-radius: 1rem !default;

// checkbox
$custom-checkbox-indicator-border-radius: 4px !default;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.214' height='7.946' viewBox='0 0 10.214 7.946'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23fff; stroke: %23fff; stroke-width: 0.5px; %7D %3C/style%3E%3C/defs%3E%3Cg id='check-symbol' transform='translate(0.354 -53.196)'%3E%3Cg id='check' transform='translate(0 53.55)'%3E%3Cpath id='Path_8' data-name='Path 8' class='cls-1' d='M3.025,59.276.756,57.007,0,57.763l3.025,3.025,6.482-6.482-.756-.756Z' transform='translate(0 -53.55)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A") !default;

// radio
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.214' height='7.946' viewBox='0 0 10.214 7.946'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23fff; stroke: %23fff; stroke-width: 0.5px; %7D %3C/style%3E%3C/defs%3E%3Cg id='check-symbol' transform='translate(0.354 -53.196)'%3E%3Cg id='check' transform='translate(0 53.55)'%3E%3Cpath id='Path_8' data-name='Path 8' class='cls-1' d='M3.025,59.276.756,57.007,0,57.763l3.025,3.025,6.482-6.482-.756-.756Z' transform='translate(0 -53.55)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A") !default;

// select
$custom-select-indicator: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.211' height='6.587' viewBox='0 0 11.211 6.587'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23313541; %7D %3C/style%3E%3C/defs%3E%3Cg id='right-arrow' transform='translate(11.211) rotate(90)'%3E%3Cg id='Group_6' data-name='Group 6'%3E%3Cpath id='Path_1' data-name='Path 1' class='cls-1' d='M6.408,5.168,1.419.179a.615.615,0,0,0-.867,0L.184.546a.614.614,0,0,0,0,.867L4.373,5.6.179,9.8a.615.615,0,0,0,0,.867l.367.367a.615.615,0,0,0,.867,0L6.408,6.039a.619.619,0,0,0,0-.87Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A") !default;
$custom-select-bg-size: 0.68rem 0.37rem !default;
$custom-select-outer-bg: rgba(112, 112, 112, 0.2);
$custom-select-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

// import bootstrap base minimum var and helpers for button
@import "bootstrap/scss/functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/forms.scss";
@import "bootstrap/scss/custom-forms.scss";

.form-control.is-valid,
.form-control.is-invalid {
  background-position-x: calc(100% - #{$input-height-inner-quarter});
  background-position-y: center;
}

// custom switch
.custom-switch {
  .custom-control-label {
    line-height: $custom-switch-indicator-size + $custom-control-gutter;

    &::before {
      left: -($custom-switch-width + $custom-control-gutter);
      height: $custom-switch-indicator-size + $custom-control-gutter / 2;
      width: $custom-switch-width + $custom-control-gutter / 2;
      background-color: $custom-control-switch-bg;
      border: none;
      cursor: pointer;
    }

    &::after {
      background-color: $white;
      cursor: pointer;
    }
  }
}

.custom-control-label {
  font-size: 0.8rem !important;
  line-height: $custom-control-indicator-size * 1.2;
}

// custom select
.sg-select {
  font-size: 1rem;

  .Select-option.is-selected,
  .Select-option.is-focused,
  .Select-option:hover {
    background-color: $darker-gray !important;
  }

  .Select-menu-outer {
    margin-top: 1px;
    box-shadow: $custom-select-box-shadow;
    border: solid 1px $custom-select-outer-bg;
  }

  .Select-option.is-selected {
    background-color: $primary !important;
    color: $white !important;
  }

  .Select-value span {
    color: $input-color !important;
    font-size: $input-font-size !important;
  }
}

// grouped select
.sg-dropdown-grouped-container {
  .Select-menu-outer {
    max-height: unset;

    .Select-menu {
      max-height: unset;
    }
  }

  .Select-control {
    margin-bottom: 20px;
  }

  .Select-option {
    padding-left: 28px;
    color: $black-rock-blue;
    font-weight: 400;
  }

  .Select-option.is-disabled {
    padding-left: 20px;
    color: $black-rock-blue;
    font-weight: 600;
  }

  .is-focused {
    background: $darker-gray;
    color: $black-rock-blue;
  }

  .is-selected {
    background: $carrot-orange;
    color: $white;
  }
}

// multi select
.sg-multi-select-container {
  .sg-multi-select {
    .Select-menu-outer {
      margin-top: 1px;
      border: solid 1px rgba(112, 112, 112, 0.2);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      padding-top: 0.8667rem;
      padding-bottom: 0.8667rem;
      max-height: unset;

      .Select-menu {
        max-height: 25rem;
      }

      .Select-option:last-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .dropdown-option-container {
      position: relative;
      height: 1.5rem;

      .dropdown-checkbox-container {
        position: relative;
        padding: 0 3px;
      }

      .dropdown-option-label {
        font-size: 1rem;
        position: absolute;
        line-height: 1.5rem;
        left: 36px;
        top: 0;
      }
    }

    .Select-multi-value-wrapper {
      .Select-value {
        margin-top: 0;
        border: 0;
        background-color: unset;
        max-width: 150px;
        line-height: 2.1333rem;
        color: unset;
        font-size: 0.95rem;

        .Select-value-icon {
          display: none;
        }

        .Select-value-label {
          padding: 0 0.3333rem;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .sg-multi-select-filter {
    @extend .sg-multi-select;
    position: relative;

    .Select-multi-value-wrapper {
      display: flex;
      position: relative;
      right: 1.6rem;
      float: right;
      align-items: center;
      min-width: calc(100% - 1.6rem);

      .number-selected {
        position: relative;
        top: 0.1333rem;
        padding-left: 1rem;
      }

      .Select-value {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-right: 0.1333rem;
        border-radius: 0.2rem;
        background: rgba(24, 45, 109, 0.22);
        height: 1.7333rem;
        font-size: 0.9333rem;

        .Select-value-icon {
          display: block;
          border: none;
          height: 2.1333rem;
          font-size: 1.6rem;

          &:hover {
            background: unset;
            color: unset;
          }
        }
      }

      & + .Select-clear-zone {
        display: block !important;
        position: absolute;
        top: 0.0667rem;
        right: 0.1333rem;
        float: right;

        &:hover {
          color: unset;
        }

        .Select-clear {
          line-height: 2.2667rem;
          font-size: 1.6rem;
        }
      }
    }

    .Select-menu-outer {
      .Select-menu {
        max-height: 286px;

        .dropdown-option-container {
          height: unset;
          min-height: 1.5rem;

          .dropdown-option-label {
            position: relative;
            padding: 0 4px;
          }
        }
      }
    }

    .number-selected-overlay {
      display: inline-block;
      position: absolute;
      top: 0;
      margin-top: 0.1333rem;
      margin-left: 0.2rem;
      background: $white;
      padding: 0.3333rem 0.4667rem 0.2rem 0.4667rem;

      .color-link {
        color: $sg-text-info;
        cursor: pointer;
      }
    }

    .is-disabled + .number-selected-overlay {
      background: $white-smoke1 !important;
    }

    .Select-option {
      .dropdown-option-label {
        left: 0.3333rem;
      }

      .form-group {
        margin-bottom: 0;
      }

      &.is-focused {
        background: $gainsboro-white;
      }
    }

    .is-selected {
      background: $white;
      color: $carrot-orange;
    }

    .show-all-container {
      margin-top: -0.8rem;

      > div {
        padding-right: 0.4rem;
        padding-left: 0.4rem;
      }

      .value {
        display: block;
        margin: 0.4rem 0;
        margin-right: 0.1333rem;
        border-radius: 0.2rem;
        background: rgba(127, 134, 155, 0.22);
        padding: 0 0.3333rem;
        height: 1.7333rem;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.8667rem;
        white-space: nowrap;
        font-size: 0.9333rem;
      }
    }

    .paginator-container {
      .pagination {
        // important tag is required to override unmoveable inline styling
        margin-top: 0.6rem !important;
      }
    }

    .Select-control {
      display: block;
    }

    .Select-arrow-zone {
      position: absolute;
      right: 0;
      height: 100%;
    }
  }

  .Select-control {
    margin-bottom: 20px;
  }

  .is-selected {
    background: $white;
  }

  .is-focused {
    background: $darker-gray;
  }
}

// shared among selects with dropdowns
.sg-select,
.sg-multi-select {
  .Select-arrow {
    border: none !important;
  }

  .Select-arrow-zone {
    background: $custom-select-indicator no-repeat $custom-select-bg-size !important;
    background-position: center !important;
    background-size: contain;
  }
}

// custom datepicker
$datepicker__text-color: #22272e !default;
$datepicker__background-color: $white !default;
$datepicker__border-color: $white !default;
$datepicker__font-size: 0.7rem !default;
$datepicker__item-size: 2rem !default;
$datepicker__selected-color: $primary !default;
$datepicker__font-family: "proxima-nova", sans-serif !default;
$datepicker__muted-color: #b8bfca !default;
$datepicker__outside-color: $lynch-blue !default;
@import "react-datepicker/src/stylesheets/datepicker";

.sg-datepicker {
  input.form-control:hover,
  input.form-control:active,
  input.form-control:focus {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
    border-color: $input-border-color;
  }

  .react-datepicker {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.02) !important;
    border: solid 1px $light-grey !important;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    margin: 1px !important;
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: $lynch-blue;
  }

  .react-datepicker__day--outside-month {
    color: $datepicker__muted-color;
  }

  input {
    color: $input-color !important;
  }

  .sg-datepicker-header-month {
    font-size: 0.85rem;
    font-weight: 600;
    color: $black-rock-blue;
  }

  .sg-datepicker-header-year {
    font-size: 0.85rem;
    color: $lynch-blue;
  }

  .sg-datepicker-header-prev,
  .sg-datepicker-header-next {
    font-size: 0.6rem;
  }

  .sg-datepicker-header-prev-disabled,
  .sg-datepicker-header-next-disabled {
    opacity: 0.5;
  }
}

// custom slider
@import "rc-slider/assets/index";

// multi range slider
.sg-multi-range-container {
  margin-top: 2.8rem;
  margin-bottom: 1.6667rem;
  padding-bottom: 1.6667rem;

  .input-range {
    margin-top: 2rem;
    margin-left: 0.1333rem;

    .input-range__slider-container:nth-of-type(2) {
      .input-range__slider {
        right: -0.2rem;
      }
    }

    .input-range__slider-container:nth-of-type(1) {
      .input-range__slider {
        left: 0.2rem;
      }
    }

    .input-range__slider {
      margin-top: -0.8667rem;
      border: 1px solid $carrot-orange;
      border-radius: 0;
      background: $carrot-orange;
      width: 0.4667rem;
      height: 1.3333rem;
    }

    .input-range__label {
      font-size: 0.9333rem;
    }

    .input-range__track {
      height: 0.4rem;
      background: $light-grey;
    }

    // if an input slider has no value, show no slider
    .input-range__track[style*="width: 0%;"] ~ .input-range__slider-container {
      display: none;
    }

    .input-range__track--active {
      background: $carrot-orange;
    }

    .input-range__label--value {
      top: -3.4rem;
    }

    .input-range__label-container {
      top: 0.7rem !important;
    }

    .input-range__label--min,
    .input-range__label--max {
      top: 1.1rem;
    }
  }
}

// custom timepicker
$sg-timepicker-scrollbar-bg: rgba(34, 39, 46, 0.05) !default;
$sg-timepicker-scrollbar-thumb-color: rgba(34, 39, 46, 0.15) !default;

.sg-timepicker {
  z-index: 2001 !important;

  .rc-time-picker {
    height: 2.6rem;
    line-height: 1.75;
  }

  .sg-timepicker-disabled {
    background-color: $white-smoke2;

    .rc-time-picker-input {
      background-color: $white-smoke2;
    }
  }

  .rc-time-picker-panel-select-option-disabled {
    background-color: transparent !important;
    color: $very-light-gray !important;
  }

  input {
    color: $input-color !important;
    font-size: $input-font-size !important;
    outline: 0;
  }

  .rc-time-picker-input {
    border: 0;
    padding: 0;
    margin: 0;
    height: auto;
  }

  .rc-time-picker-panel-input-wrap {
    display: none;
  }

  .rc-time-picker-panel-select {
    scrollbar-width: thin;
    scrollbar-color: $sg-timepicker-scrollbar-thumb-color
      $sg-timepicker-scrollbar-bg;
  }

  .rc-time-picker-panel-select::-webkit-scrollbar {
    width: 4px;
    background: $sg-timepicker-scrollbar-bg;
  }

  .rc-time-picker-panel-select::-webkit-scrollbar-thumb {
    background-color: $sg-timepicker-scrollbar-thumb-color;
    border-radius: 3px;
  }

  .rc-time-picker-panel-inner {
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.02);
    border: solid 1px $light-grey;
    padding: 12px 0;
    margin-top: 2.8rem;
  }

  .rc-time-picker-panel-select li:hover {
    background: $darker-gray !important;
  }

  .rc-time-picker-panel-select-option-selected {
    font-weight: normal;
  }

  .rc-time-picker-panel-select {
    width: 3.6rem !important;
  }

  &.rc-time-picker-panel-placement-topLeft .rc-time-picker-panel-inner {
    bottom: 2.3rem;
  }
}

.autocomplete-select {
  .Select-control {
    height: 45px;
    display: flex;
    align-items: center;
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    line-height: 45px !important;
  }

  .Select-value {
    line-height: 45px !important;
  }

  .Select-arrow-zone {
    display: none;
  }

  .Select-noresults {
    background-color: rgba(66, 139, 202, 0.1) !important;
  }

  .Select-option:last-child {
    background-color: rgba(66, 139, 202, 0.1) !important;
    display: flex;
    align-items: center !important;
    padding-left: 8px !important;
    width: 100% !important;
    height: 38px !important;
  }
}

.form-label {
  display: flex;
  align-items: center;

  small {
    font-size: 1.35rem;
    font-weight: 500;
    padding-top: 4px;
    padding-left: 4px;
  }
}

.multi-select-filter-read-more-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.4rem;

  div {
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 0.2rem;
    background: rgba(127, 134, 155, 0.22);
    padding-left: 0.4667rem;
    height: 1.7333rem;
    font-size: 0.9333rem;

    .Select-value-icon {
      display: block;
      border: none;
      height: 2.1333rem;
      font-size: 1.6rem;
      position: absolute;
      right: 0;
      padding: 0 0.2667rem;

      &:hover {
        background: unset;
        color: unset;
      }
    }
  }
}

.popover-body {
  .read-more-notes {
    .read-more-show-all-container {
      margin-top: -0.8rem;

      > div {
        padding-right: 0.4rem;
        padding-left: 0.4rem;
      }

      .value {
        display: flex;
        margin: 0.4rem 0;
        margin-right: 0.1333rem;
        border-radius: 0.2rem;
        background: rgba(127, 134, 155, 0.22);
        padding: 0 0.3333rem;
        height: 1.7333rem;
        line-height: 1.8667rem;
        white-space: nowrap;
        font-size: 0.9333rem;

        .Select-value-icon {
          display: block;
          border: none;
          height: 2.1333rem;
          font-size: 1.6rem;
          position: absolute;
          right: 1rem;

          &:hover {
            background: unset;
            color: unset;
          }
        }
      }
    }
  }
}

.dark-mode {
  .sg-select {
    .Select-option:hover {
      background-color: $shuttle-gray !important;
    }
    .Select-value {
      span {
        color: $white !important;
      }
    }
  }

  .Select-control {
    background-color: $lynch-blue !important;
    border: none;

    .Select-input:focus,
    .is-focused {
      background-color: $lynch-blue;
    }

    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
      color: $white;
      opacity: 1;
    }
  }
  .sg-multi-select-container {
    .sg-multi-select {
      .Select-menu-outer {
        background: $dark-moderate-blue;

        .Select-menu {
          background: $dark-moderate-blue;
        }

        .Select-option {
          background: $dark-moderate-blue;
          color: $white;
        }
        .Select-option.is-selected {
          color: $primary !important;
        }
      }
    }
  }
  .sg-multi-select-filter {
    .Select-multi-value-wrapper {
      .Select-value {
        background: $dark-moderate-blue;
      }
    }
  }
}
