@import "styles/variables.scss";

.action-button {
    width: 80px;
    height: 30px;
    margin: 0px 8px 0px 8px;
}

.row-container {
    width: 180px;
}