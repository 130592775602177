@import "styles/variables.scss";

.no-chart-data .block-ui-container {
  height: 40%;
  top: 60%;
}

.card-chart {
  .block-ui-container {
    cursor: default !important;
  }
}

.label {
  text-align: left;
  width: 6.25rem;
  font-size: 0.813rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: $lynch-blue;
}

.horizontal-bar-label-top {
  letter-spacing: 0.013rem;
  font-weight: 600;
  margin-top: 1rem;
  width: fit-content;
}
.horizontal-bar-chart {
  margin-bottom: 1.2rem;
  .label-left {
    width: 42px;
    margin-right: 0.75rem;
    font-size: 0.95rem;
  }
  .label-right {
    min-width: 24px;
    margin-left: 0.75rem;
    font-size: 0.95rem;
  }
  .progress {
    background: $sg-chart-progress-bg !important;
  }
  .progress-bar {
    background: $sg-chart-progress-bar-bg;
  }
}

.sg-card-subtitle {
  letter-spacing: 0.016rem;
  font-weight: 600;
}

.horizontal-bar-chart-popup {
  line-height: 1.29;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-size: 0.9333rem;

  > div {
    max-width: 274px;
    .horizontal-bar-chart-popup-label {
      font-weight: 400;
    }
    .horizontal-bar-chart-popup-value {
      font-weight: 600;
    }
    .horizontal-bar-chart-popup-message {
      padding-top: 0.3333rem;
    }
  }
}

.responsive-donut-chart {
  .highcharts-graph {
    r: 20%;
    stroke-width: 34px;
  }
}

.small-donut-chart-title {
  color: $black-rock-blue;
  font-size: 1rem;
}

// Library overrides
.highcharts-drillup-button {
  display: none;
}

circle.highcharts-graph {
  stroke: $autumn-fog;
}

// Linear Plot Chart Styles
.sg-linear-plot-chart {
  min-height: 150px;
  background: rgba(126, 134, 157, 0.05);
  padding: 1.4667rem 2.2667rem;

  .lpc-title {
    font-size: 1.3333rem;
    font-weight: 500;
    opacity: 0.6;
    color: $black-rock-blue;
  }
  .lpc-description {
    font-size: 1.2rem;
    font-weight: 400;
    color: $black-rock-blue;
    padding-bottom: 1rem;
    margin-bottom: 50px;
  }

  .lpc-range-container {
    margin: 0 1rem;

    .rc-slider {
      min-height: 60px;
      .rc-slider-tooltip-placement-top {
        .rc-slider-tooltip-arrow {
          bottom: 4px;
          left: calc(50% + 4px);
          transform: rotate(45deg);
          margin-left: -8px;
          border-width: 8px 8px 0;
          border-right-width: 1px;
          border-bottom-width: 1px;
          border-top-color: $white;
          border-right-color: $carrot-orange;
          border-bottom-color: $carrot-orange;
          background: $white;
        }
      }

      .rc-slider-tooltip-inner {
        border: 1px solid $carrot-orange;
        box-shadow: none;
        background: $white;
        padding: 10px;
        height: 2.6667rem;
        color: $black;
        font-size: 1.2rem;
        font-weight: 600;
      }

      .rc-slider-dot,
      .rc-slider-mark-text,
      .rc-slider-handle:hover,
      .rc-slider-handle:active,
      .rc-slider-handle-dragging {
        box-shadow: none !important;
        cursor: default;
      }
    }
  }
}
