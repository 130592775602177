@import "styles/variables.scss";

@mixin rotate {
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

@mixin results-header-sm-screens {
  @media (max-width: $screen-xs) {
    height: auto;
    h4 {
      text-align: center;
    }
  }
}

@mixin member-area-orange-button() {
  font-size: 0.9 * $base-font-size;
  font-weight: 7 * $base-font-weight;
  padding: 0.7 * $base-padding 0.9 * $base-padding;
  color: $white !important;
  text-align: center;
  background-color: $orange;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer !important;
  border: 0.05 * $base-border-width solid transparent;
  white-space: nowrap;
  line-height: 1.428571429;
  border-radius: 0.3 * $base-border-radius;
  -webkit-user-select: none;
  &:hover,
  &:focus {
    color: $night-rider !important;
    text-decoration: none;
  }
}
@mixin dashboard-star-ratings($font-size: 1.2rem, $padding-right: 0.1rem) {
  color: $gainsboro;
  font-size: $font-size;
  margin: 0 auto;
  position: relative;
  span {
    padding: 0 $padding-right;

    &.filled {
      color: #6ec5b8;
    }
  }
}

@mixin required-field($pad-left) {
  color: $dark-red;
  padding-left: $base-padding * $pad-left;
}

@mixin metrics-headers($color, $font-size, $text-transform, $font-weight) {
  color: $color;
  font-size: $font-size;
  text-transform: $text-transform;
  font-weight: $font-weight;
}

@mixin table-column($width, $text-align) {
  width: $width;
  text-align: $text-align;
}

// PADDINGS WITH LIST
@mixin padding($values...) {
  @each $var in $values {
    padding: #{$var};
  }
}

// MARGINS WITH LIST
@mixin margin($values...) {
  @each $var in $values {
    margin: #{$var};
  }
}

// BUTTON
@mixin button-custom($color, $bg-color, $very-light-gray, $hover-darkness) {
  color: $color;
  background-color: $bg-color;
  border-color: $very-light-gray;
  &:hover {
    background-color: darken(
      $bg-color,
      $base-bg-hover-dark-pct * $hover-darkness
    );
    border-color: darken(
      $very-light-gray,
      $base-border-hover-dark-pct * $hover-darkness
    );
    transition: all 0.3s ease;
  }
}

// Orange button Effect
@mixin orange-button-effect {
  color: $white;
  &:hover {
    color: $night-rider;
  }
}

// Green button
@mixin member-area-green-button() {
  background: $salad-green;
  font-size: 0.9 * $base-font-size;
  font-weight: 7 * $base-font-weight;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  margin: 0 * $base-margin 0 * $base-margin 1 * $base-margin 0 * $base-margin;
  padding: 0.5 * $base-padding 3 * $base-padding !important;
  vertical-align: middle;
  cursor: pointer !important;
  border: 0.05 * $base-border-width solid transparent;
  white-space: nowrap;
  line-height: 1.428571429 * $base-line-height;
  border-radius: 0.3 * $base-border-radius;
  -webkit-user-select: none;
  &:hover,
  &:focus {
    color: $night-rider !important;
    text-decoration: none;
  }
}

// Form control
@mixin reviews-form-control() {
  display: block;
  width: 100 * $base-width-pct;
  height: 2.2 * $base-height;
  padding: 0.5 * $base-padding 1 * $base-padding;
  font-size: 0.85 * $base-font-size;
  line-height: 1.428571429 * $base-line-height;
  color: $matterhorn-gray;
  vertical-align: middle;
  background-color: $white;
  background-image: none;
  border: 0.05 * $base-border-width solid $very-light-gray;
  border-radius: 0.25 * $base-border-radius;
}

// Header-4
@mixin reviews-header4() {
  font-size: 0.99 * $base-font-size;
  font-weight: 7 * $base-font-weight;
  text-transform: uppercase;
  color: $baltic-sea-black;
  text-transform: uppercase;
  margin-bottom: 2 * $base-margin;
  padding-bottom: 0.9 * $base-padding;
  border-bottom: 0.05 * $base-border-width solid $aluminium-gray;
  width: 100 * $base-width-pct;
}
@mixin copy-button-container($top) {
  position: absolute;
  top: $top * $base-top-pct;
  left: -3 * $base-left-pct;
  width: 100 * $base-width-pct;
  text-align: center;
  opacity: 0;
}
@mixin active($color) {
  background: url("../../../styles/assets/icons/request-confirm.png") no-repeat
    0.3rem $color;
}

@mixin sub-section-heading() {
  text-transform: uppercase;
  color: $orange;
  font-size: 1.2 * $base-font-size;
  font-weight: 7 * $base-font-weight;
  font-style: normal;
  padding: 0.4 * $base-padding 0.9 * $base-padding 0.9 * $base-padding;
  margin-bottom: 1.25 * $base-margin;
}

// Font Setting
@mixin font-setting(
  $font-size,
  $color,
  $line-height: 1.38,
  $text-align: left,
  $font-weight: normal,
  $letter-spacing: normal,
  $font-stretch: normal,
  $font-style: normal
) {
  font-size: $font-size;
  color: $color;
  text-align: $text-align;
  line-height: $line-height;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  font-stretch: $font-stretch;
  font-style: $font-style;
}

// tile setting
@mixin tile(
  $shadow: 0.375,
  $shadow-color: $light-black,
  $padding: 0.625,
  $margin-top: 1.25
) {
  box-shadow: 0 0 $shadow * $base-border-width 0 $shadow-color;
  margin-top: $margin-top * $base-margin;
  background-color: $white;
  padding: $padding * $base-padding;
}
// name initial ellipse
@mixin ellipse($height: 2.125, $width: 2.125) {
  height: $height * $base-height;
  width: $width * $base-width;
  @media (max-width: $screen-sm) {
    height: ($height - 0.625) * $base-height;
    width: ($width - 0.625) * $base-width;
  }
  border-radius: 50%;
  background-color: $rock-blue;
  vertical-align: middle;
}
//Pagination
@mixin pagination(
  $left-arrow-enabled: "assets/review-settings-icon/left-arrow-enabled.svg",
  $left-arrow-disabled: "assets/review-settings-icon/left-arrow-disabled.svg",
  $right-arrow-enabled: "assets/review-settings-icon/right-arrow-enabled.svg",
  $right-arrow-disabled: "assets/review-settings-icon/right-arrow-disabled.svg"
) {
  user-select: none;
  .previous,
  .next {
    a:hover {
      cursor: pointer;
      text-decoration: none;
    }
    span {
      font-size: 0.9375 * $base-font-size;
      font-weight: 6 * $base-font-weight !important;
      font-stretch: normal !important;
      font-style: normal !important;
      letter-spacing: normal;
      color: $approx-curious-blue;
    }
  }
  a,
  a:focus {
    outline: none;
  }
  .page-counts {
    margin: 0 0.5 * $base-margin 0 0.5 * $base-margin;
    cursor: pointer;
    color: $approx-curious-blue;
  }
  .disable-page {
    opacity: 0.5;
    font-size: $base-font-size;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $black-rock-blue;
    cursor: not-allowed;
    span {
      color: $black-rock-blue !important;
    }
  }
  .active-page {
    width: auto;
    height: 1.5 * $base-height;
    border-radius: 0.25 * $base-border-radius;
    border: solid 0.0625 * $base-border-width $approx-curious-blue;
    background-color: $approx-curious-blue-opacity;
    outline: none;
    padding: 0 $base-height / 2;
    display: inline-block;
  }
  .right-arrow::after {
    margin-left: 1 * $base-margin;
    @media (max-width: $screen-sm) {
      margin-left: 0.5 * $base-margin;
    }
    vertical-align: middle;
  }
  .disable-page .right-arrow::after {
    content: url($right-arrow-disabled) !important;
  }
  .right-arrow::after {
    content: url($right-arrow-enabled) !important;
    transform: rotate(30deg);
  }

  .left-arrow::before {
    margin-right: 1 * $base-margin;
    @media (max-width: $screen-sm) {
      margin-right: 0.5 * $base-margin;
    }
    vertical-align: middle;
  }
  .disable-page .left-arrow::before {
    content: url($left-arrow-disabled) !important;
  }
  .left-arrow::before {
    content: url($left-arrow-enabled) !important;
    transform: rotate(30deg);
  }
}

// Flex Setting
@mixin flex-setting(
  $flex-direction: row,
  $justify-content: start,
  $align-items: normal,
  $flex-wrap: nowrap,
) {
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $flex-wrap;
}
