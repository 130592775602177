@import "styles/variables.scss";

.flex-grow {
  flex-grow: 1;
}

.styleguide-sidebar {
  background-color: $lightest-blue;
  min-height: calc(100vh - 66px);
}

.styleguide-content,
.styleguide-sidebar {
  padding-top: 2rem;
  padding-bottom: 2rem;
}