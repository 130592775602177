.custom-deactivation-template {
  &.swal-modal {
    width: 640px;
    position: absolute;
    left: calc(100vw / 2 - 640px / 2);
    top: calc(100vh / 2 - 506px / 2);
    padding: 30px 60px 60px 60px !important;
  }
  .swal-button--save {
    background-color: #f42134;
    color: #ffffff;
  }
  .swal-text {
    width: 360px;
    text-align: center;
  }
  .swal-button--save:hover {
    background-color: darken(#f42134, 20);
    color: #ffffff;
  }
  .swal-button--cancel {
    background-color: transparent;
    border-radius: 4px;
    border: solid 1px #d6d3d3;
    color: #979593;
  }
  .swal-button--cancel:hover {
    background-color: transparent;
    border-radius: 4px;
    border: solid 1px #d6d3d3;
    color: darken(#979593, 20);
  }
  .swal-footer {
    display: flex;
    justify-content: center;
  }
}
.custom-deactivation-template {
  .swal-text {
    width: 420px;
    padding-bottom: 3rem;
  }
}
