.settings-card-icon {
  height: 2.1rem;
}

.settings-card-icon svg {
  height: 2rem;
  width: 2rem;
  fill: #7e7e7e;
  stroke: #7e7e7e;
}

.settings-card-name {
  height: 1.5rem;
}

.settings-card-description {
  margin-top: 2px;
}
