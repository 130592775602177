@import "styles/variables.scss";

.segment-header {
  margin-bottom: 0.1rem;
  border-bottom: 0.1rem solid $header-border;
  padding-top: $page-gutter * 1.5;
  padding-right: $page-gutter;
  padding-bottom: $page-gutter * 1.15;
  padding-left: $page-gutter;
}

.segment-container {
  background: $page-bg-color-light;
  padding: 2rem 2rem 0 2rem;
  color: $gondola-grey;
}

.cancel-segment {
  margin-right: 2.6667rem;
  font-size: 1.0667rem;
}

.segment-cancel-modal {
  padding-top: 1.7333rem;
  width: 584px;
  height: 369px;
  .swal-icon {
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    .swal-icon--error__x-mark {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    .swal-icon--error__line--right {
      right: unset;
    }
    .swal-icon--error__line--left {
      left: unset;
    }
    .swal-icon--error__line {
      top: unset;
    }
  }
  .swal-title {
    padding: 0.2rem 1.0667rem 0.4rem 1.0667rem;
    font-weight: normal;
  }
  .swal-text {
    text-align: center;
    font-weight: 300;
  }
  .swal-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4667rem 1.3333rem;
    min-width: 6.2667rem;
    height: 2.6667rem;
    font-size: 1.0667rem;
  }
  .swal-button:focus {
    box-shadow: none;
  }
  .swal-button--confirm {
    background: $sg-text-danger;
    &:hover {
      background: #a70817;
    }
  }
  .swal-button--cancel {
    background: $white;
    color: $mountain-mist-grey;
    border: 1px solid $light-grey;
    &:hover {
      background: $white;
      color: #646260;
    }
  }
}