@import "styles/variables.scss";

.read-more-notes {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  
  .read-more-notes-title {
    color: $cello-blue-2;
  }
}
/**
* These are default Bootstrap styles that are applied to 
* top, right, bottom, left placement positions, but have
* been omitted for some reason from more specific positions
* that include start and end suffixes.
*/
.bs-popover-top-start,
.bs-popover-top-end {
    margin-bottom: 0.5rem;
  .arrow {
    bottom: calc((0.5rem + 1px) * -1);
    &:before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25);  
    }
    &:after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: $white;
    }
  }
}
.bs-popover-right-start,
.bs-popover-right-end {
    .arrow {
      left: calc((0.5rem + 1px) * -1);
      width: 0.5rem;
      height: 1rem;
      margin: 0.3rem 0;
    &:before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25);
    }
    &:after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: $white;
    }
  }
}
.bs-popover-bottom-start,
.bs-popover-bottom-end {
    margin-top: 0.5rem;
  .arrow {
    top: calc((0.5rem + 1px) * -1);
    &:before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25);
    }
    &:after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: $white;
    }
  }
}
.bs-popover-left-start,
.bs-popover-left-end {
    margin-right: 0.5rem;
  .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
    &:before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25);
    }
    &:after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: $white;
    }
  }
}