@import "styles/variables.scss";
@import "styles/mixins.scss";

#review-details {
  .required-field::after {
    content: "\0002A";
    color: $dark-red;
    margin-left: 0.3 * $base-margin;
  }
  .disabled-btn {
    opacity: 0.7;
  }
  .private-feedback-toggle-button {
    @include font-setting($base-font-size, $approx-curious-blue, 1.38, left,5 * $base-font-weight);
    cursor: pointer;
  }

  .no-text-transform {
    text-transform: none;
  }

  .featured-review {
    @include font-setting($base-font-size, $gondola-grey);
  }

  .publish-review {
    @include font-setting($base-font-size, $gondola-grey);
  }

  .form-control {
    font-size: 0.938 * $base-font-size;
  }

  .star-ratings {
    margin-top: -0.125 * $base-margin;
  }
  .additional-star-ratings {
    margin-top: -0.938 * $base-margin;
  }

  .additional-star-ratings-label {
    font-size: 1rem;
    color: #313541;
    text-align: left;
    font-weight: 500;
    letter-spacing: normal;
    font-stretch: normal;
    font-style: normal;
    word-wrap: break-word;
  }

  .private-feedback-tile {
    border: solid 0.063 * $base-border-width $surf-crest-green;
    background-color: $twilight-blue-grey;
    .private-feedback-header {
      @include font-setting($base-font-size, $black-rock-blue,1.38,left,bold);
      word-wrap: break-word;
    }
    .private-feedback-body {
      opacity: 0.85;
      @include font-setting(0.938* $base-font-size, $black-rock-blue,1.38,left,5 * $base-font-weight);
      word-wrap: break-word;
    }
  }

  .comment-tile {
    border: solid 0 $pale-turquoise-blue;
    background-color: $lavender-violet;
    .comment-header {
      @include font-setting($base-font-size, $astral-blue,1.38,left,bold);
    }
    .comment-body {
      @include font-setting(0.938* $base-font-size,  $astral-blue,1.6,left,5 * $base-font-weight);
      word-wrap: break-word;
    }
  }
  .comment-tile:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 5%;
    margin-left: -0.938 * $base-margin;
    width: 0;
    height: 0;
    border-bottom: solid 0.5 * $base-border-width $lavender-violet;
    border-left: solid 0.5 * $base-border-width transparent;
    border-right: solid 0.5 * $base-border-width transparent;

    @media (max-width: $screen-sm) {
      left: 6%;
    }

    @media (max-width: $screen-xs) {
      left: 12%;
    }
  }

  .label-style {
    @include font-setting(0.875 * $base-font-size, $gondola-grey, 1.21, left, 500);
    text-transform: none;
  }

  .Select-control {
    border-radius: 0.188 * $base-border-radius;
    border: solid 0.063 * $base-border-radius $light-grey;
    background-color: $white;
    .Select-value-label {
      @include font-setting($base-font-size, $charcoal-grey);
    }
  }

  .Select-option {
    box-sizing: border-box;
    background-color: $white;
    cursor: pointer;
    display: block;
    margin-bottom: 0.063 * $base-margin;
    @include font-setting(0.9375 * $base-font-size, $black-rock-blue);
  }

  .Select-option.is-selected {
    background-color: $carrot-orange !important;
    color: $white !important;
  }
  .Select-option.is-focused {
    background-color: $gainsboro-white;
    color: $night-rider;
  }
  .Select-option.is-disabled {
    color: $spun-pearl !important;
    cursor: default;
  }
}

#review-details-comment {
  .email-review-author {
    @include font-setting($base-font-size, $gondola-grey);
  }
}

#review-details-challenge,
#review-details-withdraw-request {
  .challenge-information,
  .withdrawal-request-information {
    @include font-setting(0.813 * $base-font-size, $grey-dark, 1.38, left);
    font-style: oblique;

    .reviews-learn-more {
      color: $approx-curious-blue !important;
      cursor: pointer;
    }
  }
  .review-details-withdraw-request-warning {
    @include font-setting($base-font-size, $black-rock-blue, 1.38, left);
    opacity: 0.8;
  }
}

#review-details-challenge {
  .review-details-challenge-warning {
    @include font-setting($base-font-size, $black-rock-blue, 1.38, left);
    opacity: 0.8;
  }
  #modalView {
    .modal-dialog {
      float: unset !important;
      margin-top: 14 * $base-margin;
      cursor: pointer;
    }
    .modal-header {
      background-color: $cello-blue !important;
      padding: 1.5 * $base-padding 1 * $base-padding;
      h5 {
        border-bottom: 0;
      }
    }
    .modal-title {
      margin-bottom: 0;
    }
    .modal-content {
      height: 100%;
      border: none;
      border-top: solid 0.063 * $base-border-width $cello-blue;
    }
    .close {
      font-size: 1.875 * $base-font-size !important;
      padding-top: 0.5 * $base-padding;
      padding-bottom: 0.5 * $base-padding;
    }
    .modal-dialog {


      .modal-header {
        background-color: $cello-blue !important;
        border-bottom: 0;
        padding: 1.5 * $base-padding 1 * $base-padding;

        h5 {
          @include font-setting(1.625 * $base-font-size, $white, 0.69);
          font-size: 1.625 * $base-font-size !important;
          text-transform: none;
          border-bottom: none;
        }
      }

      .modal-body {
        @include font-setting(0.938* $base-font-size, $lynch-blue,1.47,left,5 * $base-font-weight);
        margin: 1%;
      }
    }
  }
}
