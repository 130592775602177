.company-users-index-container {
  width: 100%;

  .company-users-table {
    border-top: solid 1px #e3e7ed;
    border-left: solid 1px #e3e7ed;
    border-right: solid 1px #e3e7ed;
  }

  .column-container {
    width: 25%;
  }

  .first-cell {
    padding-left: 1.5rem;
  }

  .arrows-container {
    height: 12px;
  }

  .table-cell-container {
    width: 100%;
    height: 60px;
    border-bottom: solid 1px #e3e7ed;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #313541;
    position: relative;
    word-break: break-word;
    padding-right: 0.15rem;
  }

  .company-users-options-container {
    display: none;
    position: absolute;
    width: 200px;
    height: 92px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px rgba(112, 112, 112, 0.2);
    background-color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.53;
    letter-spacing: normal;
    text-align: left;
    color: #313541;
    top: 75%;
    left: -35%;
    z-index: 777;
  }

  .pointing-cursor {
    cursor: pointer;
  }

  .options-text {
    width: 100%;
    height: 50%;
    padding-left: 2rem;
    color: #313541;
    &:hover {
      background-color: #e0e0e0;
    }
  }

  .column-header-container {
    height: 44px;
    border-bottom: solid 1px #e3e7ed;
    background-color: #f7fafb;
  }

  .column-header-text {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.08;
    letter-spacing: normal;
    color: #313541;
  }

  .more-img {
    width: 3.3px;
    height: 15.1px;
    object-fit: contain;
    cursor: pointer;
  }

  .arrow-img {
    width: 4px;
    height: 5px;
    cursor: pointer;
  }

  .column-status-container-active {
    width: 70px;
    height: 23px;
    border-radius: 4px;
    border: solid 1px #5fcc29;
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #5fcc29;
  }

  .column-status-container-inactive {
    width: 82px;
    height: 23px;
    border-radius: 4px;
    border: solid 1px #667587;
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #667587;
  }

  .name-circle {
    width: 34px;
    height: 34px;
    background-color: #8aa2c0;
    border-radius: 50%;
    color: white;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 1rem;
  }
}
