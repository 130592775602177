@import "styles/variables.scss";

// override variables
$h1-font-size: 1.62rem;
$h2-font-size: 1.37rem;
$h3-font-size: 1.12rem;
$h4-font-size: 0.93rem;
$h5-font-size: 0.87rem;
$h6-font-size: 0.75rem;

.sg-text-primary {
  color: $sg-text-primary;
}
.sg-text-success {
  color: $sg-text-success;
}
.sg-text-info {
  color: $sg-text-info;
}
.sg-text-warning {
  color: $sg-text-warning;
}
.sg-text-danger {
  color: $sg-text-danger;
}
.sg-text-highlight {
  background-color: $sg-text-highlight-bg;
}
.sg-text-underline {
  text-decoration: underline;
}

// import bootstrap base minimum var and helpers
@import "bootstrap/scss/functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/type.scss";
