@import "styles/variables.scss";
@import "styles/mixins.scss";

.results-not-found-container {
  .icon-style {
    background-color: $solitude-blue2;
    border-radius: 50%;
    display: grid;
    place-items: center;
  }

  .primary-message {
    color: $black-rock-blue;
    font-size: 1.73rem;
    font-weight: 600;
    margin-top: 2.667rem;
  }

  .secondary-message {
    color: $grey-dark;
    font-size: 1.33rem;
    margin-top: 24px;
    margin-bottom: 2.667rem;
  }
}

.dark-mode {
  .results-not-found-container {
    .text-dark {
      color: $black-rock-blue !important;
    }
  }
}
