@import "styles/variables.scss";

.tab-billing-settings .tabs {
  border-bottom: 1px solid $approx-gainsboro;
  margin-bottom: 1.6rem;
}

.tab-billing-settings .nav-item {
  margin-left: -$page-gutter !important;
  margin-right: 1.2 * $page-gutter !important;
  position: relative;
  top: 1px;
}

.billing-overview-container {
  min-height: 72vh;
}

.billing-overview {
  display: flex;
  flex-wrap: wrap;
}

.billing-card-large-text {
  font-size: 2.4rem;
}

.billing-overview-card {
  position: relative;
  margin-right: 28px;
  margin-bottom: 28px;
  width: 355px;
  min-width: 338px;
  min-height: 300px;
}
.icon-cl {
  height: 3.75rem;
  margin-top: -1.25rem;
}

.icon-pulseM {
  width: 7.25rem;
  height: 1.5rem;
  margin: 0rem 0.813rem 2.5rem 0;
  object-fit: contain;
}

.billing-overview-card-title {
  margin-bottom: 2.8rem;
  text-transform: uppercase;
  line-height: 0.9;
  letter-spacing: 0.3px;
  color: $black-rock-blue;
  font-size: 1.3333rem;
  font-stretch: normal;
  font-weight: 600;
  font-style: normal;
}

.selected-subscription {
  border-bottom: 0.156rem solid $carrot-orange;
}

.billing-overview-card-sub-title {
  margin-bottom: 1rem;
  min-height: 1.33rem;
  text-align: left;
  line-height: 1.43;
  letter-spacing: normal;
  color: $grey-dark;
  font-size: 0.933rem;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
}

.billing-overview-card-status {
  font-size: 1.33rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: left;
  color: $lynch-blue;
  & + .h4 {
    opacity: 0.8;
    line-height: 1.25;
    letter-spacing: normal;
    color: $black-rock-blue;
    font-size: 1.0667rem;
  }
}

.add-on {
  position: absolute;
  bottom: 2rem;
  cursor: pointer;
  text-align: left;
  line-height: 1.25;
  letter-spacing: -0.24px;
  color: $sg-text-info;
  font-size: 1.0667rem;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
  &:hover {
    text-decoration: underline;
  }
}

.add-on-notes {
  .credit-info {
    font-size: 1.125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.18px;
    text-align: left;
    color: $black-rock-blue;

    .amount {
      font-size: 1.33rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.2px;
      text-align: left;
      color: $toast-default;
    }
    .post-text {
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.16px;
      text-align: left;
      color: $black-rock-blue;
    }
  }
  .credit-sub-info {
    font-size: 0.813rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: 0.13px;
    text-align: left;
    color: $black-rock-blue;
  }
}

.no-charges-due {
  opacity: 0.8;
  padding-top: 0.2rem;
  padding-bottom: 0.6667rem;
  text-align: left;
  line-height: 1.25;
  letter-spacing: normal;
  color: $black-rock-blue;
  font-size: 1.067rem;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
}

.truncate-overflow {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.billing-table-cell {
  color: $black-rock-blue;
}

.billing_cvv_input {
  background-image: none !important;
  padding-right: 0 !important;
}

ul.card_logos {
  list-style-type: none;
  height: 43px;
  margin-left: 0px;
  display: inline-block;
  padding-left: 1.5rem;
  li {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    background-image: url("~src/assets/images/settings/card_logos.png");
    float: left;
    height: 44px;
    width: 72px;
    text-indent: -9999px;
    margin-right: 4px;
  }
  li.visa {
    background-position: 0 0;
  }
  li.mastercard {
    background-position: -73px 0;
  }
  li.amex {
    background-position: -146px 0;
  }
  li.discover {
    background-position: -219px 0;
  }
  li.visa.off {
    background-position: 0 44px;
  }
  li.mastercard.off {
    background-position: -73px 44px;
  }
  li.amex.off {
    background-position: -146px 44px;
  }
  li.discover.off {
    background-position: -219px 44px;
  }
}

.menu-up {
  .Select-menu-outer {
    height: 200px !important;
    top: -200px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  }
}

#company-settings {
  .billing-transactions-description {
    font-weight: 600;
  }
}

.expiry-date {
  padding-top: 0.9rem;
}

.card-details-block {
  .custom-control {
    z-index: 0 !important;
  }
}
