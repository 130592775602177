@import "styles/variables.scss";
@import "styles/mixins.scss";
#private-feedback-review-component{
    .private-feedback-header {
        @include font-setting($base-font-size, $black-rock-blue,1.38,left,bold);
        word-wrap: break-word;
    }
      .private-feedback-body {
        opacity: 0.85;
        @include font-setting(0.938* $base-font-size, $black-rock-blue,1.38,left,5 * $base-font-weight);
        word-wrap: break-word;
      }
}