@import "styles/variables.scss";
@import "styles/mixins.scss";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/dropdown";

$dropdown-bg-transition: 250ms;

@mixin content-line-break {
  white-space: break-spaces;
  word-break: break-word;
}

@mixin margin-top-bottom {
  margin-top: 2.1rem;
  margin-bottom: 1.3rem;
}

@mixin dropdown-margin(
  $margin-left,
  $margin-top: 0.933rem,
  $width: fit-content
) {
  margin-top: $margin-top;
  margin-left: $margin-left;
  width: $width;
}

@mixin responsive-segment-list-card(
  $card-title-width,
  $body-element-width,
  $created-date-width,
  $title-width
) {
  .segment-list-card-head {
    height: auto;
    .card-title {
      width: $card-title-width;
    }
    .card-content {
      .segment-card-body-title {
        white-space: break-spaces;
        word-break: keep-all;
      }
      .card-details-last-column {
        .segment-card-body-element {
          width: $body-element-width;
        }
        .segment-card-created-date {
          width: $created-date-width;
        }
      }
    }
    .title {
      display: inline-block;
      width: $title-width;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      @include font-setting(1.2rem, $black-rock-blue, 1.38, left, 600);
      svg {
        margin-left: 0.5rem;
      }
    }
  }
}

.last-activity-popup-content {
  margin-top: -2.2rem !important;
  padding: 2rem !important;
  width: auto !important;
  .last-activity-popup-arrow {
    position: relative !important;
    top: -0.5rem;
    left: 2.3rem;
  }
  .recent-campaign-table {
    thead {
      @include font-setting(1rem, $bright-grey, 1.25, left, 500);
      border: none;
      border-bottom: solid 0.067rem rgba(151, 149, 147, 0.15);
      text-align: center;
    }
    tr,
    td {
      border: none;
    }
    .campaign-name {
      min-width: 11rem;
      .campaign-title {
        @include font-setting(1rem, $sg-text-info);
      }
    }
    .campaign-type {
      align-content: center;
      min-width: 7rem;
      text-align: center;
    }
    .campaign-date {
      min-width: 10rem;
      @include font-setting(1rem, $bright-grey);
      text-align: center;
    }
  }
}

.display-flex-end {
  display: flex;
  justify-content: end;
}

.segment-container {
  background-color: $page-bg-color-light;
  .pagination-container {
    min-height: 4.9rem;
  }
  @media (min-width: $screen-1600) {
    .segment-sidebar {
      flex: 0 0 $sidebar-gutter;
      max-width: $sidebar-gutter;
    }
    .segment-content {
      flex: 0 0 calc(100% - #{$sidebar-gutter});
      max-width: calc(100% - #{$sidebar-gutter});
    }
  }

  // Segments Sidebar Component
  .segment-sidebar {
    .search-title {
      @include margin-top-bottom();
      @include font-setting(1.33rem, $sapphire-blue, 1.38, left, bold);
    }
    .sidebar-menu-disabled {
      > div:nth-of-type(1) {
        > div:nth-of-type(2),
        div:nth-of-type(3) {
          padding-left: 2.667rem;
        }
      }
    }
    @media (max-width: $screen-md-pro), (max-width: $screen-md-pro) {
      .sidebar-menu-disabled {
        > div:nth-of-type(1) {
          > div:nth-of-type(2),
          div:nth-of-type(3) {
            .menu-text {
              .menu-label {
                margin-left: -0.667rem;
              }
            }
            padding-left: 1rem;
          }
        }
      }
    }
  }

  // segments Content Component
  .segment-content {
    .segments-content-view {
      .segmentlist-label {
        @include margin-top-bottom();
        @include font-setting(1.46rem, $black-rock-blue, 1.38, left, bold);
      }

      // For showing selected filters
      .filters-list {
        display: flex;
        width: fit-content;
        .reset-filter {
          padding: 0rem 0rem 0rem 1.12rem;
          @include font-setting(1.067rem, $sg-text-info, 1.25, left, 500);
        }
      }
    }

    .list-card-container.segment-list-card {
      position: relative;
      margin: 1rem 0;
      border-radius: 0.4rem;
      background-color: $white-smoke;
      overflow: visible;
      font-size: 1rem;
      font-weight: 400;
      .list-card-flag-container {
        .list-card-flag {
          top: -3.333rem;
          left: -2.667rem;
        }
        .list-card-flag-icon {
          margin: 0.3rem;
        }
      }
      .list-card.sg-card {
        overflow: visible;
        white-space: nowrap;
        color: $black-rock-blue;

        .sg-card-body {
          .accordion {
            padding: 0;
          }
          .archived-segment {
            background-color: $whisper;
          }
          .segment-list-card-head {
            display: flex;
            justify-content: space-between;
            padding: 1.66rem 2rem 0rem 2rem;
            height: 6.26rem;
            .segment-list-card-title {
              display: flex;
              .chevron {
                padding-top: 0.5rem;
              }
              .chevron:after {
                content: "\f107";
                font-family: "FontAwesome";
                font-size: 1.5rem;
              }
              .chevron.collapsed:after {
                content: "\f106";
              }
              .card-title {
                padding-left: 2rem;
                .segment-icon {
                  padding-left: 0.9rem;
                  margin-top: -0.133rem;
                }
              }
            }
            .card-content {
              @extend .display-flex-end;
              .card-details-middle-column {
                @extend .display-flex-end;
                padding-right: 0;
              }
              .card-details-last-column {
                @extend .display-flex-end;
                padding-left: 0;
                .segment-card-body-element,
                .segment-card-created-date {
                  width: 10rem;
                }
              }
              .segment-card-body-element {
                padding: 0rem 1rem;
                .segment-card-body-title {
                  @include font-setting(1rem, $black-rock-blue, 1.38, left);
                }
                .segment-card-value {
                  @include font-setting(
                    1.133rem,
                    $black-rock-blue,
                    1.38,
                    left,
                    600
                  );
                }
                .last-activity-link {
                  @include font-setting(1rem, $sg-text-info, 1.38, left, 600);
                  cursor: pointer;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .segment-card-created-date {
                .segment-card-body-title {
                  @include font-setting(1rem, $black-rock-blue, 1.38, left);
                }
                .segment-card-value {
                  @include font-setting(
                    1.133rem,
                    $black-rock-blue,
                    1.38,
                    left,
                    600
                  );
                  @include content-line-break();
                }
              }
            }
            .title {
              @include font-setting(1.2rem, $black-rock-blue, 1.38, left, 600);
              svg {
                margin-left: 0.5rem;
              }
            }
            @media (min-width: $screen-xl) {
              .title {
                display: inline-block;
                width: 30rem;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                @include font-setting(
                  1.2rem,
                  $black-rock-blue,
                  1.38,
                  left,
                  600
                );
                svg {
                  margin-left: 0.5rem;
                }
              }
            }
            @media (min-width: 2304px) {
              .title {
                display: inline-block;
                width: 50rem;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                @include font-setting(
                  1.2rem,
                  $black-rock-blue,
                  1.38,
                  left,
                  600
                );
                svg {
                  margin-left: 0.5rem;
                }
              }
            }
            .subtitle {
              color: $lynch-blue;
              .subtitle-date {
                color: $black-rock-blue;
              }
            }
          }
          @media (max-width: $screen-1600) {
            @include responsive-segment-list-card(
              33.33rem,
              12.467rem,
              10.867rem,
              30rem
            );
          }
          @media (max-width: $screen-ipad-pro) {
            @include responsive-segment-list-card(
              30rem,
              10.867rem,
              7.867rem,
              24rem
            );
          }
          @media (max-width: $screen-md-pro) {
            @include responsive-segment-list-card(20rem, 9rem, 5.2rem, 16rem);
          }
          .card-bottom-body {
            background-color: $white-smoke;
            padding: 2rem 3.333rem 1.333rem 3.333rem;
            .toggle-header {
              margin-bottom: 1rem;
              margin-left: 1rem;
              @include font-setting(1rem, $black-rock-blue, 1.38, left, 500);
            }
            .toggle-footer {
              width: min-content;
              @include font-setting(1rem, $sg-text-info, 1.38, left, 600);
            }
            .container-main {
              margin-bottom: 1.467rem;
              border: solid 0.133rem rgba(103, 103, 103, 0.16);
              border-radius: 0.2rem;
              background-color: $white;
              height: -webkit-fill-available;
              .container-header {
                background-color: $azureish-white;
                padding: 1rem;
                min-height: 2.66rem;
                font-weight: 600;
              }
              .container-body {
                padding: 1rem;
                @include font-setting(1rem, $black-rock-blue, 1.38, left);
                .sub-title {
                  @include font-setting(
                    0.933rem,
                    $matterhorn-gray,
                    1.38,
                    left,
                    600
                  );
                }
                .info {
                  @include content-line-break();
                  .show-criteria-container {
                    display: inline-block;
                    width: 100%;
                    min-height: fit-content;
                    .show-criteria-category {
                      padding-bottom: 2rem;
                      .sub-title {
                        @include font-setting(
                          0.933rem,
                          $matterhorn-gray,
                          1.38,
                          left,
                          600
                        );
                      }
                    }
                    .show-criteria-divider {
                      margin: 0 0.75rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .primary-message-style {
    font-size: 2.8rem;
  }

  .secondary-message-style {
    margin: auto;
    margin-bottom: 1.333rem;
    max-width: 46.667rem;
    font-size: 1.2rem;
  }
}

.segment-popup {
  border: solid 0.067rem rgba(112, 112, 112, 0.2);
  border-radius: 0.2rem;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.16) !important;
  background-color: $shadow-white;
  min-width: 13.33rem;
}
.segment-popup-item {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  border-radius: 0;
  padding: 0.4rem 1.333rem;
  @include hover-focus {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    @include gradient-bg($darker-gray);
  }
  transition: background-color $dropdown-bg-transition linear;
  -ms-transition: background-color $dropdown-bg-transition linear;
  -webkit-transition: background-color $dropdown-bg-transition linear;
}

%menu-submenu {
  .popup-menu {
    @extend .segment-popup;
    .popup-menu-item {
      @extend .segment-popup-item;
    }
    .active-menu-item {
      background-color: $darker-gray;
    }
  }
  .sub-popup-menu {
    @extend .segment-popup;
    min-height: 8rem;
    margin-right: -0.467rem;
    .sub-popup-menu-item {
      @extend .segment-popup-item;
      img {
        height: 1.333rem;
        width: 1.333rem;
        margin-top: -0.2rem !important;
      }
    }
  }
}

.segment-menu-action-popup-content {
  padding: 0 !important;
  @extend %menu-submenu;
  .segment-menu-action-popup-arrow {
    display: none;
  }
}

.segment-submenu-action-popup-content {
  @extend %menu-submenu;
  padding: 0 !important;
  :not([class]) {
    z-index: 0 !important;
    margin-top: -0.8rem;
  }

  .segment-submenu-action-popup-arrow {
    position: absolute;
    top: 0.246rem;
    right: 0.267rem;
    transform: rotate(180deg) translateY(50%) translateX(-25%);
    z-index: -1;
    margin-left: 0.533rem;
    background: transparent;
    width: 1rem;
    height: 0.533rem;
    color: $darker-gray;
  }
}
.segment-details-container {
  background-color: $page-bg-color-light;
  .segment-details-block {
    padding-right: $page-gutter;
    padding-left: $page-gutter;
  }
  .segment-summary-sidebar {
    .segment-summary {
      .summary-title {
        @include margin-top-bottom();
        @include font-setting(1.33rem, $sapphire-blue, 1.38, left, bold);
      }
      .summary-card {
        border-radius: 0.267rem;
        box-shadow: 0 0.133rem 0.4rem 0 rgba(0, 0, 0, 0.24);
        background: $white;
        padding-right: 1.33rem;
        padding-bottom: 1.33rem;
        padding-left: 1.33rem;
        height: 100%;
        min-height: 9.333rem;
        font-size: 1.067rem;
        Row {
          align-items: center;
        }
        .option-dropdown {
          display: flex;
          justify-content: end;
          .dropdown-item {
            display: flex;
            align-items: center;
            margin-top: 0.6rem;
            margin-bottom: 0.6rem;
            min-height: 2.6rem;
          }
          .dropdown-menu {
            min-height: 3.5rem;
          }
        }
        .summary-info {
          align-items: center;
          padding-top: 1.1rem;
          padding-bottom: 1.1rem;
          @include font-setting(0.933rem, $black-rock-blue, 1.21, left);
          > .summary-info:nth-of-type(1) {
            padding-top: 0.5rem;
          }
          .summary-values {
            display: flex;
            align-items: center;
            @include font-setting(1.06rem, $black-rock-blue, 1.25, left, 600);
          }
        }
        .summary-info2 {
          padding-top: 1.33rem;
          @include font-setting(0.933rem, $black-rock-blue, 1.21, left);
        }

        .include-details {
          border: none;
          padding: 0;
          .label {
            padding-top: 1rem;
            padding-bottom: 0.25rem;
            @include font-setting(1rem, $lynch-blue, 1.21);
          }
          .values {
            @include font-setting(1.13rem, $black-rock-blue, 1.25, left, 600);
          }
        }

        .texting-accordion-container {
          border-radius: none;
          box-shadow: none;
          padding: 0;
          width: 100%;

          .accordion {
            small {
              padding-left: 1rem;
              font-size: 0.5rem;
              opacity: 0.6;
              padding-top: 0.067rem;
            }
            span {
              flex-grow: 0;
            }
            .texting-accordion-line {
              display: none;
            }
            .collapse,
            .collapse.show,
            .collapsing {
              > div {
                border: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                padding: 0;
              }
              .show-criteria-category {
                .label {
                  @include font-setting(1rem, $lynch-blue, 1.21);
                }
                .values {
                  @include font-setting(
                    1.13rem,
                    $black-rock-blue,
                    1.25,
                    left,
                    600
                  );
                }
              }
            }
          }
        }
      }
    }
  }
  .segment-summary-content {
    .segment-overview {
      .overview-title {
        @include margin-top-bottom();
        @include font-setting(1.33rem, $sapphire-blue, 1.38, left, bold);
      }
      .overview-card {
        border-radius: 0.267rem;
        box-shadow: 0 0.133rem 0.4rem 0 rgba(0, 0, 0, 0.24);
        padding: 1rem !important;
        min-height: 8.733rem;
        max-height: 8.733rem;
        @media (max-width: $screen-md-pro) {
          max-height: 9.667rem;
        }
        .title {
          padding-right: 0.666rem;
        }

        .sg-card-body {
          @media (max-width: $screen-ipad-pro) {
            .card-container {
              padding: 0;
            }
          }
        }
        .card-content {
          height: -webkit-fill-available;
          .main-content {
            display: flex;
            justify-content: center !important;
            padding-top: 1rem;
            @include font-setting(1.467rem, $sapphire-blue, 0.59, left, bold);
          }
          .bottom-details {
            display: flex;
            justify-content: center !important;
            padding-top: 0.666rem;
            @include font-setting(0.933rem, $sapphire-blue, 1.21);
            text-align: center;
          }
        }
      }
    }
  }
}
.archive-segment-modal {
  width: 38.9333rem;
  height: 29.5333rem;
  .swal-icon--custom {
    padding-top: 2rem;
  }
  .swal-title,
  .swal-text {
    @include font-setting(1.6rem, $cello-blue-2, 1.21);
    padding-right: 7.333rem;
    padding-left: 7.333rem;
    text-align: center;
  }
  .swal-content {
    @include font-setting(1.2rem, $cello-blue-2, 1.22);
    padding-right: 7.333rem;
    padding-left: 7.333rem;
    text-align: center;
  }
  .swal-footer {
    .swal-button-container {
      .swal-button--confirm {
        background-color: $carrot-orange;
        width: 5.875rem;
      }
      .swal-button--cancel {
        background-color: $white;
        border: solid 0.067rem $lynch-blue;
        width: 5.875rem;
      }
    }
  }
}

.segment-card-menu-container {
  padding-top: 0.8rem;
  .sg-dropdown {
    .dropdown-menu {
      overflow: visible;
      min-width: 16.5rem;
      .dropdown-item {
        display: flex;
        align-items: center;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        min-height: 2.6rem;
        @include font-setting(1rem, $black-rock-blue, 1.25, left, 500);
      }
      .parent-item-container {
        padding-right: 0;
        display: block;
        &:hover {
          .show {
            .dropdown-toggle {
              &:before {
                content: "\A";
                position: absolute;
                top: 0;
                left: -33px;
                z-index: 9999;
                border-top: 10px solid transparent;
                border-right: 10px solid $gainsboro-white;
                border-bottom: 10px solid transparent;
                border-left: 0;
                border-style: solid;
              }
            }
          }
        }
        .dropdown-menu {
          min-height: 3.5rem;
          left: -18rem !important;
          top: -3rem !important;
        }
        .dropdown-item {
          display: flex;
          align-items: center;
          margin-top: 0.3rem;
          margin-bottom: 0.3rem;
          padding-right: 1rem;
          min-height: 2.6rem;
          @include font-setting(1rem, $black-rock-blue, 1.25, left, 500);
          img {
            margin-right: 1rem;
          }
        }
      }
      .sg-dropdown {
        button {
          min-width: 12rem;
        }
      }
    }
    .dropdown-toggle {
      justify-content: flex-start;
      width: 100%;
      text-align: left;
      @include font-setting(1rem, $black-rock-blue, 1.25, left, 500);
      .dropdown-toggle-indicator {
        display: none;
      }
      padding-left: 0;
      text-decoration: none;
      color: $gray-900;
      font-weight: 400;
    }
  }
}
