.pvtile-container {
  margin-right: 1%;

  .pflist-right-container {
    flex-grow: 1;
    align-items: flex-start;
    padding-right: 30px;
  }

  .contact-page-link {
    &:hover {
      color: #313541 !important;
      text-decoration: none !important;
    }
  }

  .new-tile {
    box-shadow: 0 0 0.375rem 0 #00000014;
    margin-top: 1.25rem;
    background-color: #fff;
    padding: 13px 0px 22px 16px;
  }

  .pflist-left-container {
    min-width: 400px !important;
    width: 400px !important;
    max-width: 400px !important;
    margin-right: 5px;
  }

  @media (max-width: 1400px) {
    .pflist-left-container {
      min-width: 35%;
    }
  }

  .pflist-data-styles {
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
    width: 92%;
    word-break: break-all;
  }
}
