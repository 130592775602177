// import project variables
@import "styles/variables.scss";

// override colors for button
$primary: #f47821 !default;
$secondary: #ececf0 !default;
$danger: #f42134 !default;
$light: #667587 !default;
$btn-disabled-opacity: 0.75 !default;

// import bootstrap base minimum var and helpers for button
@import "bootstrap/scss/functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/buttons.scss";

// custom styles/sizes
.btn-xs {
  @include button-size(
    $btn-padding-y-sm * 0.85,
    $btn-padding-x-sm * 0.85,
    $btn-font-size-sm * 0.85,
    $btn-line-height-sm * 0.85,
    $btn-border-radius-sm * 0.85
  );
}

.btn:disabled {
  cursor: not-allowed;
}

.btn-group-toggle {
  input[type="radio"] {
    display: none;
  }
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: $carrot-orange;
    border-color: $carrot-orange;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: $grey-dark;
    background-color: $gray-200;
    border-color: $light-grey;
  }

  .btn-outline-secondary {
    border-color: $light-grey;
    color: $grey-dark;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      border-color: $light-grey;
      color: $grey-dark;
      font-size: 1rem;
      background: $gray-200;
    }
  }
}
