#track-sidebar-component {
  position: relative;
  .credits-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 675px;
  }

  .credits-label {
    font-size: 20px;
    font-weight: 600;
    color: #082544;
  }

  .credits-divider {
    height: 10px;
    border-top: 1px solid lightgray;
  }

  .credits-lower-container {
    display: flex;
  }

  .credits-upper-txt-container {
    display: flex;
    margin-bottom: 10px;
    font-size: 16px;
    color: #444444;
  }

  .credit-txt-two {
    font-weight: bold;
    margin-left: 6px;
    margin-right: 6px;
  }

  .credit-txt-three {
    font-size: 14px;
    font-weight: 600;
    padding-top: 1px;
    color: #428bca;
    cursor: pointer;
  }

  .credits-info-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-top: 4px;
  }

  .credits-info-txt {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
  }
}

@import "styles/variables.scss";
@import "styles/mixins.scss";

#track-sidebar-component {
  .filter-title {
    font-size: 1.25 * $base-font-size;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: $sapphire-blue;
  }
  .track-menu-text {
    font-size: 1.125 * $base-font-size;
    font-weight: 6 * $base-font-weight;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.78;
    letter-spacing: 0.011 * $base-padding;
    text-align: left;
    color: $light-slate-grey;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;

    .track-menu-icon {
      width: 1.125 * $base-width;
      height: 1.125 * $base-height;

      @media (max-width: $screen-sm) and (min-width: $screen-sm) {
        width: 0.7 * $base-width;
        height: 0.7 * $base-height;
      }

      @media (max-width: $screen-xs) {
        width: $base-width;
        height: $base-height;
        margin-left: $base-margin;
        margin-right: $base-margin;
      }
    }

    @media (max-width: $screen-sm) and (min-width: $screen-sm) {
      font-size: 0.7 * $base-font-size;
    }

    @media (max-width: $screen-xs) {
      font-size: $base-font-size;
    }
  }

  .track-menu-style {
    border-left: 0.125 * $base-border-width solid $light-slate-grey;
  }

  //.track-menu-style:hover,
  //.track-menu-style:focus {
  //  filter: invert(48%) sepia(100%) saturate(429%) hue-rotate(341deg)
  //    brightness(96%) contrast(99%);
  //  border-left: 0.125 * $base-border-width solid;
  //  color: $carrot-orange;
  //  font-weight: bold;
  //}

  .track-active-menu {
    filter: invert(48%) sepia(100%) saturate(429%) hue-rotate(341deg) brightness(96%) contrast(99%);;
    border-left: 0.125*$base-border-width solid;
    color: $carrot-orange;
    font-weight: bold;
  }

  .popup-arrow,
  .popup-content {
    background: $black !important;
    color: $white;
  }
}
