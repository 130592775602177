@import "styles/variables.scss";
.sg-icons {
  .sg-note {
    font-size: 0.8667rem;
    color: $gray-600;
  }

  .sg-basic-code-example {
    .code-preview-label,
    .code-preview-elements {
      display: none;
    }
  }
  
  .style-guide-icon-container {
    .icon-example {
      max-width: 2.6667rem;
      max-height: 2.6667rem;
      margin: 0.6667rem 0.3333rem 1rem 1.3333rem;
      object-fit: contain;
    }
  
    .sg-header-icon-container {
      display: inline-block;
      background-color: $gray-100;
      color: $white;
      .icon-example {
        width: 2.6667rem;
        height: 2.6667rem;
      }
    }
  }
}
