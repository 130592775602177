@import "styles/variables.scss";
@import "styles/mixins.scss";
#Paginator {
  .pagination {
    @include pagination();
  }
}

.dark-mode {
  #Paginator {
    .pagination {
      .previous,
      .next {
        span {
          color: $white !important;
        }
      }
      .left-arrow::before,
      .right-arrow::after {
        filter: brightness(0) invert(1);
      }
      a {
        color: $white;
      }
      .active-page {
        border: solid 0.0625 * $base-border-width $white;
      }
    }
  }
}
