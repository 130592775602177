@import "styles/variables.scss";
@import "styles/mixins.scss";

.platform-image {
  height: 3.0875 * $base-height;
  width: 4.875 * $base-width;
}
.ellipse {
  height: 2.125 * $base-height;
  width: 2.125 * $base-width;
  @media (max-width: $screen-sm) {
    height: 1.5 * $base-height;
    width: 1.5 * $base-width;
  }
  border-radius: 50%;
  background-color: $rock-blue;
  vertical-align: middle;
}
.name-initials {
  font-size: 0.875 * $base-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: $white;
}
.reviewer-name {
  font-size: $base-font-size;
  font-weight: 6 * $base-font-weight;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $black-rock-blue;
}
.after-rating{
  margin-top: 0.1rem;
}
.dot {
  margin-top: -0.14 * $base-margin;
  margin-left: 0.375 * $base-margin;
  margin-right: 0.375 * $base-margin;
  font-size: 0.875 * $base-font-size;
  font-weight: 9 * $base-font-weight;
  font-stretch: normal;
  font-style: normal;
  color: $dim-gray1;
  vertical-align: top;
}
.review-type {
  font-size: 0.875 * $base-font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $dim-gray1;
  line-height: 1.38;
  @media (max-width: $screen-xs) {
    font-size: 0.775 * $base-font-size;
    line-height: 1.98;
  }
}
.star-ratings {
  margin-top: -0.1 * $base-margin;
}
