.review-widget-form-container {
    width: 50%;

    .label {
        font-size: 16px !important;
        margin-bottom: 0px !important;
        min-width: 98px;
        margin-right: 1rem;
    }

    .widget-input-container {
        width: 80%;
        margin-right: 2%;
    }

    .color-input-container {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 220px;
        width: 220px;
    }

    .input-container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .hidden {
        width: 0px;
        height: 0px;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        left: 25%;
        top: 2rem;
    }

    .color-input {
        position: absolute;
        cursor: pointer;
        margin-left: 10px;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        z-index: 777;
    }

    .rc-color-picker-trigger {
      border: none;
      height: 24px;
      width: 24px;
      border-radius: 5px;
      box-shadow: none;
    }

    .info-text {
      font-size: 14px;
      font-style: italic;
      color: #7e7e7e;
    }

    .widget-input {
        max-width: 220px;
    }

    .copy-btn {
        width: 112px;
        border-radius: 5px !important;
        height: 40px;
    }

    .save-btn {
        width: auto;
        border-radius: 5px !important;
        height: 40px;
        margin-right: 20px;
    }
}
