@import "styles/variables.scss";
@import "styles/toastNotifications.scss";
@import "styles/mixins.scss";
@import "AppDarkMode.scss";

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  background: $white;
  font-family: "proxima-nova", sans-serif !important;
  color: #6d7273 !important;
  font-size: 0.95 * $base-font-size;
  font-weight: 5 * $base-font-weight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

body.sticky-footer .content-wrapper {
  min-height: calc(100vh - 4rem);
}

body.fixed-nav {
  padding-top: 4 * $base-padding;
}

.content-wrapper {
  min-height: calc(100vh - 4rem);
  padding: 6.5 * $base-padding 0 * $base-padding 1 * $base-padding 0 *
    $base-padding;
}

.page-header .mr-3 {
  margin-right: 36px !important;
}

.scroll-to-top {
  position: fixed;
  right: 0.5 * $base-right;
  bottom: 0.02 * $base-bottom;
  display: none;
  width: 1 * $base-width;
  height: 1 * $base-height;
  text-align: center;
  color: $white;
  background: $gray-800;
  line-height: 0.9 * $base-line-height;
}

.scroll-to-top:focus,
.scroll-to-top:hover {
  color: $white;
}

.scroll-to-top:hover {
  background: $gray-800;
}

.scroll-to-top i {
  font-weight: 8 * $base-font-weight;
}

.smaller {
  font-size: 0.7 * $base-font-size;
}

.o-hidden {
  overflow: hidden !important;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.text-orange {
  color: $orange;
}

.text-green {
  color: $green;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fixed-top {
  background: $white;
}

.jumbotron {
  position: absolute;
  top: 50 * $base-top-pct;
  left: 50 * $base-left-pct;
  transform: translate(-50%, -50%);
  background-color: transparent !important;
}

span {
  &.jumbotron {
    margin: 0;
    padding: 0;

    @media (max-width: $screen-xs-min) {
      position: relative;
      float: left;
      margin-top: 0.05 * $base-margin;
    }
  }
}

.same-height {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .box-card {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.row-bottom-gutter {
  margin-bottom: 2 * $base-margin;
}

.border-bottom-gray {
  border-bottom: 0.05 * $base-border-width solid $solitude;
}

.visible-xs {
  display: none !important;

  @media (max-width: $screen-md-min) {
    display: block !important;
  }
}

.hidden-xs {
  display: block !important;

  @media (max-width: $screen-md-min) {
    display: none !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.click-through {
  pointer-events: none;
}

.btn-warning {
  color: $white;
  border-color: $orange;
  background-color: $orange;

  &:hover {
    color: $black;
    border-color: $orange;
    background-color: $orange;
  }
}

.bg-light-gray {
  background: $light-gray !important;
}

.error {
  color: $dark-red;
  font-size: 0.76 * $base-font-size;
}

.cursor-pointer {
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.h-40 {
  height: 2.6675rem;
  font-size: 1.067rem;
}
.cursor-pointer {
  cursor: pointer;
}

#toggle-switch {
  .btn-primary,
  .btn-white {
    color: $white;
    background-color: $light-slate-gray !important;
    border-color: $dark-slate-gray !important;
  }

  .toggle-on.btn,
  .toggle-off.btn {
    color: $white;
    background: $light-slate-gray;
    border-color: $dark-slate-gray;
    text-align: right !important;
    display: block !important;

    h2 {
      font-size: 0.9 * $base-font-size;
    }
  }

  .toggle-on.btn {
    color: $white;
    background: $light-slate-gray;
    border-color: $dark-slate-gray;
    text-align: left !important;
    display: block !important;

    h2 {
      font-size: 0.9 * $base-font-size;
    }
  }

  .toggle-off.btn:hover,
  .toggle-on.btn:hover {
    color: $white !important;
  }

  .toggle-handle {
    border: 0.05 * $base-border-width solid $dark-slate-gray !important;
    background: $white !important;
    width: 6 * $base-width !important;
    height: 2 * $base-height !important;
  }

  .toggle-handle:hover {
    color: $white !important;
  }

  .btn:hover {
    color: $white !important;
  }
}

#modalView {
  .modal-dialog {
    position: relative;
    width: auto;
    margin-top: 5 * $base-margin;
    pointer-events: none;
    transform: translate(0, 0);
    max-width: 50 * $base-width-pct;

    @media (max-width: $screen-md-min) {
      max-width: 100%;
    }
  }

  .modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.05 * $base-border-width solid $black;
    border-radius: 0.3 * $base-border-radius;
    outline: 0;
  }

  .modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1 * $base-padding 1 * $base-padding 0 1 * $base-padding;
    background: $atomic-gray;
    border-radius: 0.1 * $base-border-radius 0.1 * $base-border-radius 0 *
      $base-border-radius 0 * $base-border-radius;
    color: $white;

    h5 {
      border-bottom: 0.05 * $base-border-width solid $gray-200;
      font-size: 0.97 * $base-font-size !important;
    }
  }

  .modal-title {
    margin: 0;
    line-height: 1.428571429 * $base-line-height;
    width: 100 * $base-width-pct;
    color: $white !important;
    font-weight: bold;
    display: block;
    font-size: 0.9 * $base-font-size !important;
    text-transform: uppercase;
    margin-bottom: 0.8 * $base-margin;
  }

  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1 * $base-padding;
    align-content: center;
  }

  .dropshadow {
    -webkit-box-shadow: 0 * $base-shadow 0.1 * $base-shadow 0.5 * $base-shadow 0 *
      $base-shadow $trolley-gray;
    -moz-box-shadow: 0 * $base-shadow 0.1 * $base-shadow 0.5 * $base-shadow 0 *
      $base-shadow $trolley-gray;
    box-shadow: 0 * $base-shadow 0.1 * $base-shadow 0.5 * $base-shadow 0 *
      $base-shadow $trolley-gray;
    padding: 3 * $base-padding 0 * $base-padding;
    width: 80 * $base-width-pct;
    margin: auto;
    font-family: verdana, sans-serif;

    .logo {
      width: 12 * $base-width;
    }

    p {
      padding: 1 * $base-padding 7 * $base-padding 0 * $base-padding;
    }

    #modal-body-greetings {
      font-size: 1 * $base-font-size !important;
      font-weight: 5 * $base-font-weight !important;
    }

    #email-template-main-body,
    #email-template-signature {
      font-size: 0.9 * $base-font-size !important;
    }

    .btn-section {
      padding: 1 * $base-padding 5 * $base-padding 0 * $base-padding;
    }

    .color-link {
      color: $sg-text-info;
      cursor: pointer;
    }

    .review-link {
      background-color: $cornflower-blue;
      border: 0;
      color: $white;
      text-decoration: underline;
      margin-left: 0.5 * $base-margin;
      margin-bottom: 0.5 * $base-margin;
    }

    @media (max-width: $screen-md-min) {
      p {
        padding: 1 * $base-padding 0 * $base-padding 0 * $base-padding;
      }

      .btn-section {
        padding: 1 * $base-padding 0 * $base-padding 0 * $base-padding;
      }
    }

    @media (max-width: $screen-sm-min) {
      p {
        padding: 1 * $base-padding 0 * $base-padding 0 * $base-padding;
      }

      .btn-section {
        padding: 1 * $base-padding 0 * $base-padding 0 * $base-padding;
      }
    }
  }

  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 0.6 * $base-padding 1 * $base-padding;
    font-size: 0.8 * $base-font-size;
    line-height: 1.428571429 * $base-line-height;
    border-radius: 0.1 * $base-border-radius;
  }

  .close {
    color: $white;
  }

  .fa-times-thin:before {
    content: "\00d7";
    padding: 0.05rem;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    color: white !important;

    &:hover {
      color: white;
    }
  }

  .form-group {
    display: flex !important;
  }
}

.sidebar-view {
  display: flex;
  flex-direction: column;
  height: 100%;
}

input::placeholder,
textarea::placeholder {
  color: $approx-dark-gray !important;
  opacity: 1;
  /* Firefox */
  font-size: 0.9 * $base-font-size;
}

.brand-slider {
  img {
    max-width: 100%;
    padding: 0.5rem;
  }
}

.slick-prev,
.slick-next {
  z-index: 3 !important;
  font-size: 180% !important;

  &:before {
    font-family: FontAwesome !important;
    color: $orange !important;
    font-size: 100% !important;
    line-height: 0 !important;
  }
}

.slick-prev {
  &:before {
    content: "\F104" !important;
  }
}

.slick-next {
  &:before {
    content: "\F105" !important;
  }
}

#table-pagination {
  margin-left: 0.25 * $base-margin;
  margin-top: 0.25 * $base-margin;
}

.color-inherit {
  color: inherit !important;
}

.btn-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pre-wrap {
  white-space: pre-wrap;
}

.popover {
  z-index: 2000;
}

.unread_messages_count {
  background-color: #f47821;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-grid;
  place-items: center;
  border-radius: 50%;
  font-size: 0.9rem;
}

.btn-no-focus-border:focus {
  box-shadow: none !important;
}

label[required]::after {
  content: "*";
  color: $dark-red;
  font-size: 1rem;
  font-weight: bold;
  margin-left: 0.2rem;
}

.svg-white-color {
  filter: grayscale(1) brightness(4);
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.6;
}

.opacity-6 {
  opacity: 0.6;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.page-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .loader {
    border: 5px solid $orange;
    border-radius: 50%;
    border-top: 5px solid $box-shadow-light;
    width: 4rem;
    height: 4rem;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

// Mobile Styles
@media (max-width: $bootstrap-grid-small) {
  #root {
    max-width: 100dvw;
    overflow-x: hidden;
  }
}
