.btn-twitter-connect {
  display: inline-block;
  width: 206px;
  height: 40px;
  border-radius: 4px;
  background-color: #1da1f2;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.16px;
  text-align: center;
  color: #ffffff;
}

.img-twitter-profile {
  border: 1px solid rgba(112, 112, 112, 0.18);
}

.block-twitter-confirmed {
  border: solid 1px #c6dbbc;
  background-color: #fbfdfa;
  padding: 1.5rem;
}

.btn-facebook-connect {
  display: inline-block;
  width: 206px;
  height: 40px;
  border-radius: 4px;
  background-color: #3a559f;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.16px;
  text-align: center;
  color: #ffffff;
}
