@import "styles/variables.scss";

.bottom-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Only bottom shadow */
}

.enterprise-content-area {
  background: $ghost-white;

  .sg-table {
    thead {
      background-color: $light-apricot;
      border: 1px solid rgba(244, 120, 33, 0.2);
      th {
        font-size: 0.93rem;
      }
    }
    tr {
      border: 1px solid $light-peach;
    }
    tbody {
      td {
        font-size: 1.2rem;
      }
    }

    tbody tr:nth-of-type(even) {
      background-color: rgba(254, 241, 232, 0.3);
    }
  }
  .customer-table tbody tr:nth-of-type(even) {
    background-color: rgba(254, 241, 232, 0.3);
  }

  .get-reviews-table th:nth-child(2) {
    width: 30%;
  }

  .dropdown {
    .dropdown-toggle {
      font-size: 1rem;
    }
    .dropdown-menu {
      background: $ghost-white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .dropdown-item {
        min-height: 1rem;
        text-align: right;
        padding: 0;
        padding-right: 1.7rem;
        color: $dark-gray;
      }
      .dropdown-item:hover {
        background-color: rgba(27, 20, 20, 0.1);
      }
    }
  }

  .billing-statement-title {
    color: $cello;
    font-size: 1.73rem;
    font-weight: 500;
    margin-top: 1rem;
  }

  .billing-statement-sub-title {
    font-size: 1rem;
    color: $matterhorn-gray;
    line-height: 1.2;
  }

  .billing-card {
    position: relative;

    .add-billing-btn {
      position: absolute;
      right: 1rem;
      top: -4rem;
    }
  }

  .form-heading {
    font-size: 1.73rem;
    color: $cello;
  }
  .settings-card-name {
    font-size: 1.467rem;

    + div {
      font-size: 16px;
    }
  }

  .enterprise-form-text {
    padding-left: 3rem;
    position: relative;
    margin-top: 1.8rem;

    .information-icon {
      position: absolute;
      left: 1.5rem;
      top: 2px;
    }
  }
}
.page-title {
  font-size: 2rem;
  color: $deep-cyan-blue;
  font-weight: bold;
}

.table-heading {
  font-size: 1.467rem;
  position: relative;

  .reset-filter {
    position: absolute;
    font-size: 1rem;
    top: 0.48rem;
    right: 1.7rem;
    cursor: pointer;
    font-weight: 500;
  }
}

.key-metrics-popover {
  .read-more-notes > div:first-of-type {
    border-bottom: 1px solid rgba(226, 226, 226, 0.4);
    padding: 0.5rem 0rem;
  }
}
.recent-popover-header {
  border-bottom: 1px solid rgba(226, 226, 226, 0.4);
}

.filter-button {
  font-size: 0.9rem;
  font-weight: 500;
  color: $dark-gray;
  position: relative;

  .filter-badge {
    width: 8px;
    height: 8px;
    background-color: $tangerine-orange;
    position: absolute;
    right: 2.9rem;
    top: -2px;
    border-radius: 50%;
  }
}

.location-content {
  position: relative;
}
