@import "styles/variables.scss";

.key-metrics {
  color: $black-rock-blue;

  .sg-card {
    .sg-card-title {
      color: $black-rock-blue;
    }
  }

  .card-icon {
    height: 2.5rem;
    width: 2.5rem;
  }

  .card-item-title {
    font-size: 1.46rem;
    font-weight: bold;
  }

  .card-sub-title {
    color: $lynch-blue;
    letter-spacing: 0.14px;
  }

  .card-title {
    font-size: 1.06rem;
    line-height: 1.1;
    letter-spacing: 0.16px;
    text-transform: none !important;
    color: $black-rock-blue !important;
  }
}
.key-metrics-filter {
  gap: 1rem;

  .btn {
    line-height: 1.5;
  }
  .btn-group-toggle {
    height: 2.333rem;
    width: 19.333rem;
    border: 1px solid $light-blue-gray;

    .btn-secondary {
      background-color: $ghost-white;
    }
  }
}

