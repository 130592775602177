@import "styles/variables.scss";
@import "styles/mixins.scss";

#sidebar-component, #track-sidebar-component {
  .filter-title {
    @include font-setting(
      1.25 * $base-font-size,
      $sapphire-blue,
      1.38,
      left,
      bold
    );
  }
  .popup-arrow,
  .popup-content {
    background: $black !important;
    color: $white;
  }

  .popup-content {
    width: auto !important;
  }

  .Select-control {
    border-radius: 0.188 * $base-border-radius;
    border: solid 0.063 * $base-border-radius $light-grey;
    background-color: $white;
    .Select-value-label {
      @include font-setting($base-font-size, $charcoal-grey);
    }
  }

  .Select-option {
    box-sizing: border-box;
    background-color: $white;
    cursor: pointer;
    display: block;
    margin-bottom: 0.063 * $base-margin;
    @include font-setting(0.9375 * $base-font-size, $black-rock-blue);
  }

  .Select-option.is-selected {
    background-color: $carrot-orange !important;
    color: $white !important;
  }

  .Select-option.is-focused {
    background-color: $gainsboro-white;
    color: $night-rider;
  }
  .Select-option.is-disabled {
    color: $spun-pearl;
    cursor: default;
  }
  .filter-sub-title {
    img {
      margin-top: -0.188 * $base-margin;
    }
  }

  .review-status-container {
    margin-top: 70px;
  }

  .gray-line-separator {
    border-bottom: 1px solid $very-light-gray;
    height: 1px;
    width: 100%;
  }
}
