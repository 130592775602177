@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.error-page {
  width: 36 * $base-width;
  max-width:100 * $base-width-pct;
  @media (max-width: $screen-sm-max) {
    width: 100 * $base-width-pct;
  }
  > .headline {
    float: left;
    font-size: 6.2 * $base-font-size;
    font-weight: 3 * $base-font-weight;
    @media (max-width: $screen-sm-max) {
      float: none;
      text-align: center;
    }
  }
  //For the message
  > .error-content {
    margin-left: 11.5 * $base-margin;
    @media (max-width: $screen-sm-max) {
      margin-left: 0;
    }
    > h3 {
      font-weight: 3 * $base-font-weight;
      font-size: 1.56 * $base-font-size;
      @media (max-width: $screen-sm-max) {
        text-align: center;
      }
    }
    p{
      text-align:center;
    }
    display: block;
  }
}
