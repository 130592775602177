@import "styles/variables.scss";

.customer-segments-container {
  margin-top: 0.6667rem;
  
  .accordion {
    .segment-content-container {
      display: inline-flex;
      align-items: center;
      margin-right: 0.6667rem;
      margin-bottom: 0.6667rem;
      border: solid 1px $summer-sky-blue;
      border-radius: 0.2667rem;
      background-color: $white;
      padding: 0.4667rem 0.9333rem;
      min-height: 2.1333rem;

      .segment-content {
        flex-wrap: wrap;
        margin-right: 0.5333rem;
        text-transform: capitalize;
        line-height: 1.21;
        letter-spacing: normal;
        color: $summer-sky-blue;
        font-size: 0.9333rem;
        font-stretch: normal;
        font-weight: 600;
        font-style: normal;
      }
    }

    .segments-blue-txt {
      display: inline-flex;
      cursor: pointer;
      text-align: left;
      line-height: 2;
      letter-spacing: normal;
      color: $approx-curious-blue;
      font-size: 1rem;
      font-stretch: normal;
      font-weight: 500;
      font-style: normal;
    }

    select {
      text-align: left;
    }

    .texting-accordion-line + div {
      border: none;
    }
  }
  .add-segment-btn {
    background: $white;
  }
}

.segments-form-tooltip.tooltip {
  left: -396px !important;
  opacity: 1;

  .arrow::before {
    top: -0.4rem;
    left: -0.0667rem;
    border-width: 0.75rem 0.75rem 0.75rem;
    border-left-color: $white;
  }

  .segment-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
  }

  .tooltip-inner {
    border: solid 1px rgba(126, 126, 126, 0.2);
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.16);
    background-color: $white;
    width: 392px;
    min-width: 392px;
    max-width: 392px;
    height: 206px;
    text-align: left;
    line-height: 1.47;
    letter-spacing: normal;
    color: $grey-dark;
    font-size: 1rem;
    font-stretch: normal;
    font-weight: normal;
    font-style: normal;
  }

  .segment-form-label {
    margin-bottom: 0.3333rem;
    text-align: left;
    line-height: 1.21;
    letter-spacing: normal;
    color: $sg-text-primary;
    font-size: 0.9333rem;
    font-stretch: normal;
    font-weight: 500;
    font-style: normal;
  }

  .information-container {
    margin-top: 0.5333rem;
  }

  .segment-form-icon {
    width: 1.0667rem;
    min-width: 1.0667rem;
    max-width: 1.0667rem;
    height: 1.0667rem;
    min-height: 1.0667rem;
    max-height: 1.0667rem;
  }

  .segment-form-info-content {
    margin-left: 0.2667rem;
    text-align: left;
    line-height: 1.38;
    letter-spacing: normal;
    color: $grey-dark;
    font-size: 0.8667rem;
    font-stretch: normal;
    font-weight: normal;
    font-style: oblique;
  }

  .save-segment-button-container {
    margin-top: 1.3333rem;

    .segment-form-save {
      width: 4.8rem;
      height: 2.6667rem;
    }
  
    .segment-form-cancel {
      z-index: 0;
      margin-left: 1.0667rem;
      background: $white;
      height: 2.6667rem;
      color: $grey-dark;
    }
  }

  .filter-label-name {
    text-transform: capitalize;
  }
  .filter-label-count {
    opacity: 0.6
  }
}

@media (max-width: $screen-md-pro) {
  .customer-segments-container {
    .segment-content-container {
      padding: 0.2667rem;
      max-width: 6.3333rem;
    }
  }
}
