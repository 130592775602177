@import "styles/variables.scss";

.tab-review-settings .tabs {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.6rem;
}

.tab-review-settings .nav-item {
  margin-left: -$page-gutter !important;
  margin-right: 1.2 * $page-gutter !important;
  position: relative;
  top: 1px;
}

.review-field-label {
  min-width: 220px;
}

.review-field-select {
  min-width: 120px;
}

.swal-footer {
  text-align: center;
}

.review-topics,
.custom-email-template {
  &.swal-modal {
    width: 640px;
    padding: 30px 60px 60px 60px !important;
  }
  .swal-button--save {
    background-color: #f42134;
    color: #ffffff;
  }
  .swal-text {
    width: 360px;
    text-align: center;
  }
  .swal-button--save:hover {
    background-color: darken(#f42134, 20);
    color: #ffffff;
  }
  .swal-button--cancel {
    background-color: transparent;
    border-radius: 4px;
    border: solid 1px #d6d3d3;
    color: #979593;
  }
  .swal-button--cancel:hover {
    background-color: transparent;
    border-radius: 4px;
    border: solid 1px #d6d3d3;
    color: darken(#979593, 20);
  }
}

.custom-email-template {
  .swal-text {
    width: 420px;
    padding-bottom: 3rem;
  }
}

.suggestion-topics {
  .sg-select,
  .sg-select .Select-menu-outer {
    width: 480px;
  }
}

.custom-email-template-form {
  border-radius: 6px;
  border: solid 1px rgba(126, 126, 126, 0.2);
  background-color: #fbfdfa;
  margin-right: 44px;
}

.truncate-overflow {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
