$card-padding-y: 0.87rem !default;
$card-padding-x: 0.87rem !default;
$dropdown-font-size: 1.067rem;

@import "styles/variables.scss";

.sg-card {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
  background-color: $white;
  .sg-card-title {
    color: $black-rock-blue;
    letter-spacing: 0.33px;
    font-size: 1rem;
    font-weight: bold;
  }
  .sg-card-sub-title {
    color: $black-rock-blue;
    letter-spacing: 0.33px;
    font-size: 0.9rem;
    font-weight: bold;
  }
  .sg-card-body {
    padding-top: $card-padding-y;
    padding-bottom: $card-padding-y;
  }
  .sg-card {
    box-shadow: none;
  }
}

.sg-action-card {
  text-align: center;
  line-height: 1.27;
  color: $lynch-blue;
  font-size: 1.5rem;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;

  .sg-card-body {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
    padding: 60px 113px;

    > img {
      margin: 0 0 26px 0;
      max-width: 186px;
      max-height: 186px;
    }

    .sg-card-body-title {
      margin-bottom: 25px;
      text-transform: uppercase;
      line-height: 1.73rem;
      letter-spacing: 0.21px;
      color: $black-rock-blue;
      font-size: 1.73rem;
      font-stretch: normal;
      font-weight: 600;
      font-style: normal;
    }

    .sg-card-body-emphasize {
      line-height: 1.54;
      letter-spacing: 0.39px;
      color: $black-rock-blue;
      font-size: 1.87rem;
      font-stretch: normal;
      font-weight: 600;
      font-style: normal;
    }

    .btn-outline-primary,
    .btn-primary,
    .sg-dropdown .btn-primary {
      margin: 1.4rem 10px 0 10px;
      padding: 14px 27px;
      min-width: 158px;
      height: $dropdown-font-size * 3.125;
      line-height: 1.38;
      font-family: Avenir, "proxima-nova", sans-serif;
      font-size: $dropdown-font-size;
      font-weight: 500;
      .dropdown-toggle-indicator {
        line-height: 15px;
      }
    }

    .sg-dropdown {
      display: inline-block;

      .dropdown-item {
        width: 180px;

        img {
          margin: 3px 0.8rem 3px 0;
        }
      }
    }
  }
  &.mini {
    margin: 0;
    font-size: 1.0667rem;
    .sg-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      padding: 3.75rem;
    }
    img {
      margin: 0 0 24px 0;
      width: 123px;
      height: 123px;
    }
    .sg-card-body-emphasize {
      line-height: 1;
      font-size: 1.2rem;
    }
    .sg-card-body-title {
      margin-bottom: 10px;
      text-transform: uppercase;
      line-height: 0.9;
      letter-spacing: 0.08px;
      color: $black-rock-blue;
      font-size: 1.3333rem;
      font-weight: 600;
    }
    .sg-card-body {
      padding: 3.75rem !important;
    }
  }
}

.dark-mode {
  .sg-card {
    background: $dark-moderate-blue;
    color: $white;
  }
  .sg-card-title {
    color: $white;
  }
}
