#partner-terms {
  .terms-heading {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2b3033;
    color: #393d40;
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 10px;
  }

  li {
    padding-top: 2em !important;
    text-align: justify;
  }

  .top-list {
    margin: 0;
    padding: 0;
    border: none;
    padding-top: 0px !important;
    text-align: justify;
  }

  h5 {
    font-size: 15px;
    font-weight: 700;
    color: #85898c;
    padding: 1.5em 0 0.8em 0;
    text-transform: uppercase;
  }

  b {
    display: block;
  }

  p {
    font-size: 15px;
    line-height: 1.6em;
    text-align: justify;
  }
}
