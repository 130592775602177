#mainNav {
  .navbar-collapse {
    overflow: auto;

    max-height: 75vh;
    .navbar-nav {
      margin-top: 0.3 * $base-margin;
      .nav-item {
        .nav-link {
          cursor: pointer;
          &.active,
          &:hover{
            color: $white !important;
            i{
              opacity: 1;
            }
          }
          i {
              height: 1.3 * $base-height;
              width: 1.5 * $base-width;
              margin: 0.15 * $base-margin 0.2 * $base-margin 0 * $base-margin 0 * $base-margin;
              float: left;
              display: block;
              font-size: 1.3 * $base-font-size;
            }
            
          .results-icon{
            background: url(/img/side-menu-sprite.png);
            background-position:center 0;  
            opacity: 0.5;        
          }
          .setting-direct-connect {
            background: url(/img/side-menu-sprite.png) center -720px no-repeat; // Add pixcel for transform the icon from sprite img.
            opacity: 0.5;
          }
          .appointments-icon {
            background: url(/img/side-menu-sprite.png) center -438px no-repeat; // Add pixcel for transform the icon from sprite img
            opacity: 0.5;
          }
          .campaigns-icon{
            background: url(/img/side-menu-sprite.png) center -372px no-repeat; // Add pixcel for transform the icon from sprite img
            opacity: 0.5;
          }
          .customers-icon {
            background: url(/img/side-menu-sprite.png) center -52px no-repeat; // Add pixcel for transform the icon from sprite img
            opacity: 0.5;
          }
          .reviews-icon{
            background: url(/img/side-menu-sprite.png) center -106px no-repeat; // Add pixcel for transform the icon from sprite img
            opacity: 0.5;
          }
          .setting-widgets {
            background: url(/img/side-menu-sprite.png) center -764px no-repeat !important; // Add pixcel for transform the icon from sprite img
            opacity: 0.5;
          }
        }
      }
      .dropdown{
        a{
          cursor:pointer;
        }
        .dropdown-user{
          width: 16.1 * $base-width;
          padding: 0 * $base-padding;
          li{
            padding: 0.9 * $base-padding;

            &:hover{
              background : $carrot-orange; 
              a{
                color: $white;
                text-decoration: none;
              }
            }

            a{
              color: $dark-trout;
              i{
                float: left;
              }

              h5{
                font-weight: 7 * $base-font-weight;
                font-size: 0.89 * $base-font-size;
                padding-left: 1.6 * $base-padding;
              }

              p{
                font-size: 0.75 * $base-font-size;
                padding-top: 0.3 * $base-padding;
                padding-left: 1.6 * $base-padding;
                margin: 0;
              }
            }  
          }
        }
      }
    }

    .navbar-sidenav {
      .nav-link-collapse:after {
        float: right;

        content: '\f107';

        font-family: 'FontAwesome';
      }
      .nav-link-collapse.collapsed:after {
        content: '\f105';
      }
      .sidenav-second-level,
      .sidenav-third-level {
        padding-left: 0 * $base-padding;
        > li > a {
          display: block;

          padding: 0.4 * $base-padding 0* $base-padding;
          &:focus,
          &:hover {
            text-decoration: none;
          }
        }
      }
      .sidenav-second-level > li > a {
        padding-left: 0.9 * $base-padding;
      }
      .sidenav-third-level > li > a {
        padding-left: 1 * $base-padding;
      }
    }
    .sidenav-toggler {
      display: none;
    }
    .navbar-nav > .nav-item.dropdown {
      > .nav-link {
        position: relative;
        min-width: 2 * $base-width;
        &:after {
          float: right;
          width: auto;
          content: '\f105';
          border: none;
          font-family: 'FontAwesome';
        }
        .indicator {
          position: absolute;
          top: 0.1 * $base-top;
          left: 1 * $base-left;
          font-size: 0.9 * $base-font-size;
        }
      }
      &.show > .nav-link:after {
        content: '\f107';
      }
      .dropdown-menu > .dropdown-item > .dropdown-message {
        overflow: hidden;

        max-width: none;

        text-overflow: ellipsis;
      }
    }
    @media (max-width: $screen-md-min) {
      padding: 0.9 * $base-padding;
    }
  }


  @media (min-width: $screen-md-min) {
    .navbar-brand {
      width: 13.22 * $base-width;
    }
    .navbar-collapse {
      overflow: visible;

      max-height: none;
      .navbar-sidenav {
        position: absolute;
        top: 0;
        left: 0;

        overflow-x: hidden;
        overflow-y: auto;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;

        margin-top:  3.5 * $base-height;

        > .nav-item {
          width: 13.22 * $base-width;
          padding: 0;
          > .nav-link {
            padding: 0.8 * $base-padding 0.9 * $base-padding;
          }
          .sidenav-second-level,
          .sidenav-third-level {
            padding-left: 0;

            list-style: none;

            > li {
              width: 13.22 * $base-width;
              > a {
                padding: 1 * $base-padding;
              }
            }
          }
          .sidenav-second-level > li > a {
            padding-left: 2.75 * $base-padding;
          }
          .sidenav-third-level > li > a {
            padding-left: 3.75 * $base-padding;
          }
        }
      }
    }
  }
  @media (max-width: $screen-md-min) {
    padding: 0 * $base-padding;

    .navbar-toggler{
      margin: 0.5 * $base-margin;
    }
    .navbar-brand{
      margin: 0.5 * $base-margin;
    }
  }
}
