@import "src/components/campaigns/manage/PurchaseCreditsModal.scss";

.add-customers-modal {
  @extend .base-modal-w-cards;

  .modal-dialog {
    .credits-card {
      position: relative;
      height: 373px;
      margin-top: 2rem;
      .info-section {
        h2 {
          margin-top: 2rem;
          margin-bottom: 0.4667rem;
        }
        .content-separator {
          padding: 0 3rem;
        }
      }
      .cta-section {
        position: absolute;
        bottom: 0;
        margin: 0;
        width: 100%;
        button {
          margin-top: 2rem;
          margin-bottom: 2.2667rem;
        }
      }
    }
  }

  .modal-dialog.modal-lg {
    max-width: 882px;
  }

  .modal-dialog.modal-md {
    max-width: 500px;
  }
}

// Smooths transition from add to upload modal
.add-customers-modal.fade,
.add-customers-modal.fade + .fade {
  transition: unset;
}
