.download-text {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #323130;
  margin-top: 33px;
  margin-bottom: 15px;
}

.download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 306px;
  height: 40px;
  border-radius: 4px;
  background-color: #f47821;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  transition: 0.6s opacity;
  &:hover {
    color: white;
    text-decoration: none;
    opacity: 0.8;
  }
}

.rss-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 45px;
  width: calc(100% - 88px);
  margin-bottom: 3px;
}

.rss-text {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #323130;
}

.copy-code {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #428bca;
  cursor: pointer;
}

.copy-code-container {
  border-radius: 3px;
  border: solid 1px #d6d3d3;
  background-color: #f3f2f2;
  padding: 16px;
  display: flex;
  align-items: center;
  min-width: 200px;
  width: calc(100% - 88px);
}
