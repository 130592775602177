@import 'styles/variables.scss';

.accept-terms-container {
  margin: 3.333rem 4.667rem 5rem 4.667rem;
  .title {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $cello-blue;
    padding-bottom: 1rem;
  }
  .last-updated-info {
    font-size: 1rem;
    font-weight: 500;
  }
  .terms-heading {
    font-size: 1.6rem;
  }
  .highlight-text {
    text-decoration: underline;
    font-weight: bolder;
    color: $night-rider;
  }
  ul {
    list-style-type: none;
  }
}

.accept-terms-cta-container {
  background-color: $cello-blue;
  height: 4rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .accept-terms-cta {
    background-color: $green;
    border-color: $green;
    font-weight: 700;
    padding: 0.533rem 1.333rem;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    font-size: 1rem;
    border-radius: 0.267rem;
    cursor: pointer;
    &:hover {
      color: $night-rider;
    }
  }
}
