$color-danger: #f42134;
$color-default: #667587;
$color-default-light: #a0a9b4;
$color-info: #22b5e6;
$color-success: #5fcc29;
$color-warning: #f47821;

.sg-badge {
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid transparent;
  background-color: #ffffff;
  font-weight: 600;
  line-height: 1.25;
  font-size: 0.8rem;
  text-align: center;
  padding: 2px 8px;
  &.sg-badge-success {
    border-color: $color-success;
    color: $color-success;
  }
  &.sg-badge-warning {
    border-color: $color-warning;
    color: $color-warning;
  }
  &.sg-badge-info {
    border-color: $color-info;
    color: $color-info;
  }
  &.sg-badge-danger {
    border-color: $color-danger;
    color: $color-danger;
  }
  &.sg-badge-default-light {
    border-color: $color-default-light;
    color: $color-default-light;
  }
  &.sg-badge-default {
    border-color: $color-default;
    color: $color-default;
  }
}