@import "styles/variables.scss";
@import "styles/mixins.scss";
.test-header-color {
  color: $red;
}

.pad-left-none {
  padding-left: 0 * $base-padding !important;
}

.pad-right-none {
  padding-right: 0 * $base-padding !important;
}

.left-menu-bg {
  background-color: $gray-200;
}
.main-container {
  padding-top: 4.2 * $base-padding !important;
}

.loading-bullet {
  color: $carrot-orange !important;
}

.btn {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
}

.btn-primary {
  color: $white;
  background-color: $carrot-orange;
  border-color: $carrot-orange;
}

.btn-primary:hover {
  color: $white;
  background-color: #ff8c69;
  border-color: #ff8c69;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: $carrot-orange;
  border-color: $carrot-orange;
}

.btn-outline-primary {
  color: $carrot-orange;
  border-color: $carrot-orange;
}

.btn-outline-primary:hover {
  color: $white;
  background-color: $carrot-orange;
  border-color: $carrot-orange;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-primary:disabled:hover {
  color: $carrot-orange;
  border-color: $carrot-orange;
  background-color: transparent;
}

.btn-outline-secondary {
  color: $lynch-blue;
  border-color: $lynch-blue;
}

.btn-outline-secondary:hover {
  color: $white;
  background-color: $lynch-blue;
  border-color: $lynch-blue;
}

.radio-list input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.radio-list input:checked ~ label,
.radio-list label {
  color: $night-rider;
  font-weight: 500;
}

.radio-list input:checked ~ label:before {
  content: "\f192";
  display: block;
  color: $orange;
}

.radio-list input:disabled ~ label,
.radio-list input:disabled ~ label:before {
  opacity: 0.5;
}

.radio-list label::before {
  display: inline-block;
  content: "\f10c";
  float: left;
  color: $nobel-gray;
  margin-right: 0.3 * $base-margin;
  font: normal normal normal 0.9 * $base-font-size/1 FontAwesome;
  font-size: 18px;
}

textarea {
  resize: none;
}

.Select-clear-zone {
  display: none !important;
}

// Confirm dialog settings
.confirm-dialog-settings {
  &.swal-modal {
    width: 36rem;
    padding: 2rem !important;
  }
  .swal-title {
    color: $cello-blue-2;
    font-weight: normal;
    font-size: 1.7rem;
  }
  .swal-text {
    width: 26.933rem;
    height: 3.6rem;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: $lynch-blue;
    margin-bottom: 1.5rem;
  }
  .swal-icon--error {
    border-color: $sg-text-danger;
  }
  .swal-icon--error__line {
    background-color: $sg-text-danger;
  }
  .swal-button--save {
    background-color: $sg-text-danger;
    color: $shadow-white;
  }
  .swal-button--save:hover {
    background-color: darken($sg-text-danger, 20);
    color: $shadow-white;
  }
  .swal-button--cancel {
    background-color: transparent;
    border-radius: 0.267rem;
    border: solid 0.067rem $light-grey;
    color: $mountain-mist-grey;
    outline: none;
    box-shadow: none;
  }
  .swal-button--cancel:hover {
    background-color: transparent;
    color: darken($mountain-mist-grey, 20);
  }
}

// To remove box shadow
.no-box-shadow {
  box-shadow: none !important;
}

.text-transform-none {
  text-transform: none !important;
}
