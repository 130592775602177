@import "styles/variables.scss";
@import "styles/mixins.scss";

.customer-container {
  color: $gondola-grey;
  
  .responsive-customer-element {
    width: 910px;

    @media (max-width: $screen-ipad-pro) {
      max-width: 90%;
      flex: 0 0 90%;
      width: unset;
    }
    @media (min-width: $screen-ipad-pro) {
      max-width: 910px;
      flex: 0 0 910px;
    }
    @media (max-width: 576px) {
      left: -4.5333rem;
      max-width: calc(100% + 4.5333rem);
      flex: 0 0 calc(100% + 4.5333rem);
    }
  }

  h2 {
    display: inline-block;
    padding-top: 0.1333rem;
    line-height: 0.82;
    letter-spacing: 0.022rem;
    font-size: 1.4667rem;
    font-stretch: normal;
    font-weight: bold;
    font-style: normal;
  }
  .customer-card-subtitle {
    opacity: 0.8;
    max-width: 100%;
    line-height: 1.13;
    letter-spacing: normal;
    font-size: 1.0667rem;
    font-stretch: normal;
    font-weight: normal;
    font-style: normal;
  }
  box-shadow: unset;
  .customer-block {
    .sg-card {
      margin: 1rem 0;
      .sg-card-body {
        display: flex;
        .customer-info-icon,
        .customer-lock-icon {
          position: relative;
          margin-left: 1.3333rem;
        }
        .customer-lock-icon {
          bottom: 0.4rem;
        }
        .customer-info-icon {
          bottom: 0.3333rem;
        }
        .customer-card-icon-container {
          position: relative;
          top: -0.2667rem;
          padding: 0 1.4667rem 0 0.6667rem;

          img {
            max-height: 2.4rem;
            max-width: 2.4rem;
          }
        }
        .customer-card-content-container {
          width: 100%;
        }
      }
    }

    // About Card
    .customer-about-card {
      margin-top: 1.4667rem;

      h2 {
        padding-top: 1rem;
      }
      .customer-card-body > div {
        padding-right: 0.4667rem;
        padding-left: 0.4667rem;
        @media (min-width: 1480px) {
          .col-md-4 {
            max-width: 16rem;
          }
        }
      }
      .sg-card-body {
        .customer-card-icon-container {
          top: 0;
        }
      }
      .customer-about-card-item {
        padding: 0.75rem;
        min-width: 16rem;
        min-height: 3rem;
        text-align: left;
        letter-spacing: 0.0153rem;
        font-size: 1rem;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;

        img {
          position: relative;
          top: -0.0667rem;
          margin-right: 0.8667rem;
        }
      }
    }

    // Communication Preferences Card
    .customer-communication-preferences-card {
      .col-sm-11 {
        @extend .responsive-customer-element;
      }

      .customer-communication-preferences-table {
        margin-top: 0.4rem;
        line-height: 1.21;
        letter-spacing: normal;
        font-size: 0.9333rem;
        font-stretch: normal;
        font-weight: 500;
        font-style: normal;

        th,
        td {
          border-top: 0;
          border-bottom: 0;
          @media (max-width: $screen-sm) {
            padding: 0.75rem 0.25rem;
            &.preferences-table-indent {
              padding: 0.75rem 0 0.75rem 1.25rem;
            }
          }
          &:last-of-type {
            max-width: 1.8rem;
          }
        }
        th {
          opacity: 0.7;
          font-weight: 500;
        }
        td {
          span {
            cursor: pointer;
            color: $sg-text-info;
          }
        }
        tr.comm-pref-setting {
          border: solid 1px rgba(112, 112, 112, 0.13);
        }
        .preferences-table-indent {
          margin-left: 1rem;
          padding-left: 3rem;
          .preferences-table-info-icon {
            position: relative;
            top: -0.1333rem;
            margin-left: 0.6667rem;
          }
        }
      }
      .btn-lg {
        min-width: 6.6667rem;
        line-height: 1.3;
        font-size: 1.0667rem;
      }
    }

    // Customer Information Card
    .customer-information-card {
      margin-bottom: 9rem;
      .customer-card-subtitle {
        margin-bottom: 0.6667rem;
      }
      .col-sm-8 {
        @extend .responsive-customer-element;
      }

      .col-xl-6:nth-of-type(even) {
        @media (min-width: $screen-lg) {
          padding-left: 2.6667rem;
        }
      }
      .col-xl-6:nth-of-type(odd) {
        @media (min-width: $screen-lg) {
          padding-right: 2.6667rem;
        }
      }

      label {
        margin-top: 1.1333rem;
        margin-bottom: 0.2667rem;
      }
    }
  }
}
