.add-or-edit-user-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .image-upload-container {
    height: 144px;
    border-radius: 4px;
    border: solid 1px rgba(126, 126, 126, 0.4);
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  .upload-image {
    width: 108px;
    height: 108px;
    border-radius: 4px;
    border: solid 1px rgba(126, 126, 126, 0.2);
    background-color: #edf2f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 500;
  }

  .hide-upload-image {
    display: none;
  }

  .user-profile-img {
    opacity: 0;
    display: flex;
    width: 0px;
    height: 0px;
  }

  .show-user-profile-img {
    width: 108px;
    height: 108px;
    opacity: 1;
    display: flex;
    object-fit: contain;
    cursor: pointer;
    z-index: 500;
  }

  .upload-text {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.33;
    color: #313541;
  }

  .upload-image-right-container {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
  }

  .image-btns-container {
    display: flex;
    margin-bottom: 14px;
  }

  .upload-image-btn {
    width: 114px;
    height: 34px;
    border-radius: 4px;
    background-color: #f47821;
    margin-right: 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 500;
  }

  .remove-image-btn {
    width: 86px;
    height: 34px;
    border-radius: 4px;
    border: solid 1px #667587;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 500;
  }
  .image-validation-text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    opacity: 0.6;
    letter-spacing: normal;
    text-align: left;
    color: #313541;
  }

  .users-add-edit-form-container {
    display: flex;
    flex-direction: column;
  }

  .users-form-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
  }

  .form-input-container {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .form-label {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #323130;
    &:focus {
      border: 0.5px solid #007eff;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
      background: #fff;
      outline: 0;
    }
  }

  .form-label-required {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #323130;
    &:after {
      content: '*';
      color: red;
      margin-left: 3px;
      top: -2;
    }
    &:focus {
      border: 0.5px solid #007eff;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
      background: #fff;
      outline: 0;
    }
  }

  .form-input {
    height: 40px;
    border-radius: 3px;
    border: solid 1px #d6d3d3;
    background-color: rgba(255, 255, 255, 0);
    padding-left: 14px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #444444;
    width: 90% !important;
    &::placeholder {
      opacity: 0.5;
    }
    &:focus {
      border: 0.5px solid #007eff;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
      background: #fff;
      outline: 0;
    }
  }

  .users-information-img {
    width: 16px;
    height: 16px;
    margin-left: 6px;
  }

  .active-input {
    width: 94px;
    height: 40px;
    border: solid 1px #f47821;
    background-color: #f47821;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .inactive-input {
    width: 94px;
    height: 40px;
    border: solid 1px #d6d3d3;
    background-color: #ffffff;
    color: #444444;;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
  }

  .password-info-container {
    display: flex;
    align-items: center;
    margin-top: 9px;
  }

  .password-info-txt {
    margin-left: 7px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: left;
    color: #7e7e7e;
  }

  .button-container {
    display: flex;
    margin-top: 47px;
  }

  .add-user-btn {
    border: none;
    width: 100px;
    height: 40px;
    border-radius: 4px;
    background-color: #f47821;
    color: #ffffff;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    font-size: 15;
    align-items: center;
    &:focus {
      outline: 0;
      text-decoration: none;
    }
  }

  .cancel-add-user-btn {
    width: 100px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #667587;
    color: #667587;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  .users-add-edit-error-message {
    color: red;
    min-height: 16px;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    white-space: nowrap;
  }
}
