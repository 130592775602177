.invite-form-container {
  padding-left: 21px;
  padding-right: 21px;
  display: flex;
  flex-direction: column;
  border-radius: 4;
  border: 1px solid #e3e7ed;
  background-color: #edf2f8;
  .get-reviews-error {
    color: red;
    font-size: 12px;
    display:flex;
    flex-wrap: wrap;
  }

  .input-long {
    // 100% minus 375 pixels for the daytime phone and action buttons, divided among 4 input boxes - 16px (4 * 4px) for padding
    width: calc((100% - 375px)/4 - 16px);
    margin-right: 15px;

    @media (max-width: 1366px) {
      // 100% minus 645 pixels for the daytime phone and action buttons, divided among 3 input boxes
     width: calc(100% - 645px);
    }
  }

  .clear-btn {
    background-color: white;
    &:hover {
      color: #667587;
      background: white;
      opacity: 0.6;
    }
  }

  .input-medium {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 198px;
  }

  input {
    height: 40px;
    &:focus {
      border: 1px solid #ced4da;
      box-shadow: none;
    }
  }

  .button-border {
    border: 1px solid #ced4da;
    &:hover {
      border: 1px solid #ced4da;
    }
  }

  .input-small {
    width: 54px;
    border-left: none !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  button:focus {
    box-shadow: none !important;
  }

  @media only screen and (max-width: 1200px) {
    .input-long {
      width: 100% !important;
    }

    .btn-container {
      margin-top: 2px;
    }

    .inner-form-container {
      flex-direction: column;
    }

    .invite-form-container {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .invite-form-container {
    flex-direction: column;
  }
}
