@import "styles/variables.scss";

.filter-modal {
  position: absolute;
  z-index: 1;
  width: 25rem;
  background: $white;
  right: 0;
  top: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .filter-pane-header {
    background: $gulf-blue;
    padding: 0.5rem 1.5rem;
    color: $white;
  }

  .sg-datepicker,
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .sg-datepicker .form-control {
    background: none;
  }

  .filter-pane-footer {
    display: flex;
    justify-content: end;
    border-top: 1px solid rgba(226, 226, 226, 0.4);

    button {
      margin-top: 1rem;
    }
  }
}
