.request-appt-container {
  display: flex;
  align-items: center;
  width: 180px;
  height: 49px;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f47821;
  padding: 8px 22px 8px 17px;

  .request-appt-small-txt {
    font-size: 13px;
    color: white;
    margin-bottom: -7px;
  }

  .request-appt-large-txt {
    font-size: 17px;
    color: white;
  }

  .request-appt-content-container {
    margin-left: 14px;
  }
}
