.light-blue-card-container {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.7;
  border-radius: 6px;
  background-color: #edf2f8;
  margin-top: 30px;

  .text-container {
    display: flex;
    align-items: center;
  }

  .square {
    background-color: #667587;
    width: 5px;
    height: 5px;
    margin-right: 1.25rem;
  }

  .text {
    color: #667587;
    font-size: 15px;
  }
}
