@import "styles/variables.scss";
@import "node_modules/react-toastify/scss/main.scss";

.Toastify__toast-container {
  width: 30rem;

  a {
    color: $sg-text-info !important;
  }
  .Toastify__toast {
    background: $white !important;
    width: 29.7333rem;
    min-height: 7.0667rem;
    color: $dim-gray;
    font-family: $base-font;

    h3 {
      opacity: 0.9;
      margin: 0 0 0.2rem 0;
      line-height: 1.13;
      color: $black-rock-blue;
      font-size: 1.0667rem;
      font-weight: 500;
      -webkit-font-smoothing: auto;
    }

    .Toastify__toast-body {
      .decorator {
        display: flex;
        position: absolute;
        top: 0.5333rem;
        margin-right: 1rem;
        margin-left: -0.5rem;
        border-radius: 2.6777rem;
        width: 0.3333rem;
        height: calc(100% - 1.0666rem);
        &:before {
          padding: 1rem 3rem 1.5rem 0.9333rem;
          content: " ";
        }
        + div {
          padding-left: 3rem;
        }
      }
      .toast-action {
        font-weight: 500;
        font-weight: 0.9333rem;
        -webkit-font-smoothing: auto;
        margin-top: 0.2667rem;
        
        .color-link {
          color: $sg-text-info;
          cursor: pointer;
        }
      }
    }
    .Toastify__close-button {
      width: 1.3333rem;
      height: 1.3333rem;
      color: $dim-gray !important;
      opacity: 1;
      > svg  {
        width: 1.3333rem;
        height: 1.3333rem;
        display: none;
      }
      &:before {
        display: block;
        background: url("../assets/images/alerts/close_sm_icon.svg") center no-repeat;
        width: 1.3333rem;
        height: 1.3333rem;
        content: " ";
      }
    }
  }

  .Toastify__toast--error {
    .Toastify__toast-body {
      .decorator {
        background-color: $toast-error;
        &:before {
          background: url("../assets/images/alerts/error_sm_icon.svg") center no-repeat;
        }
      }
    }
  }
  .Toastify__toast--info {
    .Toastify__toast-body {
      .decorator {
        background-color: $toast-info;
        &:before {
          background: url("../assets/images/alerts/info_sm_icon.svg") center no-repeat;
        }
      }
    }
  }
  .Toastify__toast--success {
    .Toastify__toast-body {
      .decorator {
        background-color: $toast-success;
        &:before {
          background: url("../assets/images/alerts/success_sm_icon.svg")
            center no-repeat;
        }
      }
    }
  }
  .Toastify__toast--warning {
    .Toastify__toast-body {
      .decorator {
        background-color: $toast-warning;
        &:before {
          background: url("../assets/images/alerts/warning_sm_icon.svg")
            center no-repeat;
        }
      }
    }
  }
  .Toastify__toast--default {
    .Toastify__toast-body {
      .decorator {
        background-color: $toast-warning;
        &:before {
          background: url("../assets/images/alerts/warning_sm_icon.svg")
            center no-repeat;
        }
      }
    }
  }

  &.Toastify__toast-container--top-left,
  &.Toastify__toast-container--top-center,
  &.Toastify__toast-container--top-right {
    top: 8rem;
  }

  // // TODO tt --- experimental custom stacking ---
  // // Waiting for final expected behavior specs from product
  // padding-top: 145px;
  // .Toastify__toast {
  //   margin-top: -7.5rem;
  //   transition: all 0.5s;
  //   width: 400px;
  // }
  // .Toastify__toast:nth-of-type(4) {
  //   margin-left: 15px;
  // }
  // .Toastify__toast:nth-of-type(3) {
  //   margin-left: 10px;
  // }
  // .Toastify__toast:nth-of-type(2) {
  //   margin-left: 5px;
  // }
  // .Toastify__toast:nth-of-type(1) {
  //   margin-left: 0;
  // }

  // &:hover {
  //   .Toastify__toast:not(:first-of-type) {
  //     margin-top: 0rem;
  //   }
  //   .Toastify__toast:nth-of-type(1),
  //   .Toastify__toast:nth-of-type(2),
  //   .Toastify__toast:nth-of-type(3),
  //   .Toastify__toast:nth-of-type(4) {
  //     margin-left: 0;
  //   }
  // }
  // // --- experimental custom stacking ---
}
