#mainNav.static-top {
  .sidenav-toggler {
    display: none;
  }
  @media (min-width: $screen-md-min) {
    .sidenav-toggler {
      display: flex;
    }
  }
}

body.sidenav-toggled {
  #mainNav.static-top {
    #sidenavToggler {
      i {
        -webkit-transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter:  'FlipH';
      }
    }
  }
}
