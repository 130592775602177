@import "styles/variables.scss";

.customer-reviews-container {
  margin-top: 0.6667rem;
  
  .accordion {
    .no-reviews-text {
      opacity: 0.7;
      border: none;
      text-align: left;
      color: $black-rock-blue;
      font-size: 1rem;
      font-weight: 500;
    }

    .review-inner-container {
      :nth-of-type(1) {
        width: 49%;
      }
      :nth-of-type(2) {
        width: 35%;
        margin-left: 15%;
      }
    }

    .reviews-divider {
      border: 1px solid $clear-day-gray;
      width: 1px;
      height: 2.4rem;
    }

    .review-count {
      color: $black-rock-blue;
      font-size: 1.4667rem;
      font-weight: bold;
    }

    .review-info {
      color: $lynch-blue;
      font-size: 1rem;
      font-weight: normal;
    }
  }
}
