@import "styles/variables.scss";

.auth-body {
  background-image: url("~src/assets/images/login/background.png");
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  position: relative;
}

.auth-container {
  position: relative;

  .auth-wrapper {
    padding: 10rem 0rem;

    .auth-card {
      background: $light-snow-gray;
      border-radius: 4px;
      padding: 2rem 1rem;

      .service-monster-logo {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 2rem;
      }

      .input-group-text {
        background-color: $white !important;
        img {
          height: 1rem;
          width: 1.3rem;
        }
      }
      input {
        border-left: none;
      }
    }
  }
}
