@import "styles/variables.scss";

.segment-modal-container {
  .modal-dialog {
    max-width: 584px;
  }
  .modal-body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      margin-top: 3rem;
      width: 5.3333rem;
      height: 5.3333rem;
    }
    p {
      margin-top: 1.6667rem;
      max-width: 484px;
      text-align: center;
      line-height: 1.21;
      color: $cello-blue-2;
      font-size: 1.6rem;
    } 
    .segment-in-use-paragraph {
      margin-bottom: 2.1333rem;
    }
    .unsaved-changes-paragraph {
      margin-bottom: 4.4rem;
    }
    .button-container {
      margin-bottom: 2.2rem;
      .btn {
        margin: 0 0.4rem;
        min-width: 6.2667rem;
        min-height: 2.6667rem;
      }
    }
    .segment-in-use-modal-button-container {
      margin-bottom: 1.7333rem;
    }
    .segment-name-container {
      position: relative;
      width: 482px;

      .input-container {        
        .is-invalid {
          border: solid 0.063 * $base-border-width $light-grey;
          box-shadow: none;
          background: none;
        }
        &:after{
          top: 2.5rem;
          right: 0.75rem;
        }
      }
      .segment-name-messages {
        color: $red;
        .segment-name-count {
          float: right;
          text-align: right;
          color: $dark-gray;
        }
      }
    }
  }
}