@import "styles/variables.scss";

.pill-box {
  flex: 1 0 auto;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  margin: 0;
  outline: 0;
  border: solid 0.063rem $light-grey;
  border-radius: 0.188rem;
  background: $white;
  padding: 0.4em 0.5em;
  max-width: 100%;
  min-height: 100px;
  text-align: left;
  line-height: 1.21428571em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.8667rem;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  >* {
    display: inline-block;
  }

  .preview-item-pill {
    display: inline-block;
    margin: 0.188rem;
    border: solid 0.031rem $alto-approx;
    border-radius: 0.625rem;
    background-color: $white-smoke1;
    padding: 0.188rem 0.5rem 0.188rem 0.5rem;
    text-align: left;
    letter-spacing: 0.009rem;
    color: $grey-dark;
    font-size: 0.875rem;
    font-stretch: normal;
    font-weight: normal;
    font-style: normal;

    span {
      margin-top: -0.125rem;
      margin-left: 0.188rem;
      cursor: pointer;
      vertical-align: text-bottom;
      font-size: 0.8rem;
    }
  }

  .multi-item-text-area-input {
    border: none;

    &:focus-visible {
      outline: none;
    }
  }

  .multi-item-text-area-error {
    color: $sg-text-danger;
  }
}