@import "styles/variables.scss";

.search-container {
  max-width: 15rem;

  .input-group {
    border: 1px solid $orange;
    border-radius: 2px;
    align-items: center;
  }

  .search-toggle {
    display: "flex";
    align-items: "center";
    border-radius: "8px";
    cursor: pointer;

    span {
      font-size: 0.9rem;
      font-weight: 500;
      color: $dark-gray;
    }
  }

  .form-control {
    height: calc(1em + 0.75rem);
    border: none;
    box-shadow: none;
  }

  .search-close-icon {
    border: none;
    &:hover {
      background: none;
    }
  }
}
