// import project variables
@import "styles/variables.scss";
@import "shared/styleguide/typography/Typography.scss";

// custom styling for the styleguide
.font-preview {
  max-width: 200px !important;
  min-width: 200px !important;
}

.font-preview .card {
  display: grid;
  height: 230px;
  place-items: center;
  background-color: transparentize($whisper-gray, 0.8);
}

.font-preview h1 {
  font-size: 4rem;
}
