@import "styles/variables.scss";

.chart {
  height: 70px !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.highcharts-background {
  display: none;
}

.positive-review-stroke {
  stroke: $carrot-orange;
  stroke-width: 1px;
}

.negative-review-stroke {
  stroke: $toast-error;
  stroke-width: 1px;
}